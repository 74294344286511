import React from 'react';
import * as R from 'ramda';
import { LinkButtonWithLoader } from 'poly-book-admin';
import {
  ExcelExportCellType,
  createExcelExportCell,
  performExcelExport,
} from 'poly-client-utils';
import { EXPORT_XLS_CAPTION } from 'poly-constants';
import { bool } from 'prop-types';

import { userManagementPropTypes } from './UserManagementTable.js';

// getWIPReportRowConfig :: [Users] -> [ExcelExportDataCell]
const getUsersManagementRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 3),
  ]),
  R.juxt([
    R.prop('status'),
    R.prop('fullName'),
    R.prop('email'),
    R.path(['userGroups', 0, 'userGroup', 'name']),
  ]),
);

// eslint-disable-next-line import/no-unused-modules
export const getUserManagementExportExcelConfig = (users) => ({
  exportFileName: 'user_management.xlsx',
  columnWidths: [15, 15, 35, 35],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 3)],
      ['Status', 'Name', 'Email', 'Role'],
    ),
    ...R.map(getUsersManagementRowConfig, users),
  ],
});

export function UserManagementExportXLSBtn({ users, loading }) {
  const handleClick = () => {
    const excelConfig = getUserManagementExportExcelConfig(users);
    performExcelExport(excelConfig);
  };

  return (
    <LinkButtonWithLoader onClick={handleClick} disabled={loading}>
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoader>
  );
}

UserManagementExportXLSBtn.propTypes = {
  users: userManagementPropTypes.isRequired,
  loading: bool.isRequired,
};
