import { gql } from '@apollo/client';

export const CONTACT_USER_CHANGED = gql`
  subscription USER_CHANGED($input: SingleDocSubInput!) {
    userChanged(input: $input) {
      id
      type
      document {
        _id
      }
    }
  }
`;
