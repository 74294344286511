import styled from 'styled-components';
import { Input } from 'poly-book-admin';

export const SearchInputComp = styled(Input)`
  input {
    border-radius: 15px;
  }
  width: 200px;
  flex-shrink: 0;
`;
