import React from 'react';
import * as R from 'ramda';
import { bool, string } from 'prop-types';
import styled from 'styled-components';
import {
  TableWithPaginationContainer,
  TableCard,
  Table,
  Loader,
} from 'poly-book-admin';
import {
  keywordSortQuery,
  pathOrNothingUI,
  useSortableTable,
  useHighlightMatchesBySearch,
} from 'poly-client-utils';
import {
  TablePagination,
  useMapConfigToTableProps,
  useSetInitialPaginationOnMount,
  useSetItemsCount,
} from 'poly-admin-ui';
import { PropertyLink } from '../../../containers/links/Links.js';
import { usePropertiesBySearchQuery } from '../apollo/usePropertiesBySearch.js';

const tableConfig = [
  ['Property Name', PropertyLink, keywordSortQuery(['name'])],
  [
    'Address',
    R.path(['address', 'formatted_address']),
    keywordSortQuery(['address', 'formatted_address']),
  ],
  ['Division', R.always('-')],
  ['Budget Code', pathOrNothingUI(['clientOuterId'])],
];

const TableS = styled(Table)`
  th:last-child,
  td:last-child {
    width: 12%;
  }
`;

function PropertiesTableComp(props) {
  return (
    <TableCard>
      <TableWithPaginationContainer paginationVisible>
        <TableS {...props} />
      </TableWithPaginationContainer>
      <TablePagination />
    </TableCard>
  );
}

PropertiesTableComp.propTypes = {
  paginationVisible: bool,
};

// getPropertiesTotalCount :: SearchPropertiesQueryResult -> Number
const getPropertiesTotalCount = R.pathOr(0, ['searchProperties', 'total']);

// getProperties :: SearchPropertiesQueryResult :: [Property]
const getProperties = R.pathOr([], ['searchProperties', 'hits']);

export function PropertiesTable({ status }) {
  useSetInitialPaginationOnMount();

  const { sort, ...tableSortingProps } = useSortableTable({
    tableConfig,
    column: 1,
  });

  const { result, loading } = usePropertiesBySearchQuery(status, sort);

  useSetItemsCount(getPropertiesTotalCount, result);

  const { rows, ...restTableProps } = useMapConfigToTableProps(
    getProperties,
    tableConfig,
    result,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [['name'], ['address', 'formatted_address'], ['clientOuterId']],
    rows,
  );

  if (loading) return <Loader />;

  return (
    <PropertiesTableComp
      {...tableSortingProps}
      {...restTableProps}
      rows={highlightedRows}
    />
  );
}

PropertiesTable.propTypes = {
  status: string,
};
