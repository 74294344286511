import React from 'react';
import { useRouterParams, useRouterQuery } from 'poly-client-routing';
import { filesCollections, UpdateCollections } from 'poly-constants';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { DefaultBodyWrapper, Loader } from 'poly-book-admin';
import {
  READ_FILE_PERMISSION,
  READ_TASK_PERMISSION,
  READ_UPDATE_PERMISSION,
} from 'poly-security';
import {
  entities,
  NotFoundPage,
  useEntityFiles,
  useEntityUpdates,
} from 'poly-admin-ui';
import { InvoiceDetails } from './InvoiceDetails/index.js';
import { InvoiceTasksTab } from './InvoiceTasksTab.js';
import { PageTabs } from '../../../components/tabs/PageTabs.js';
import { FilesTab } from '../../../containers/tabs/FilesTab.js';
import { InvoiceEditForm } from './InvoiceEditForm/InvoiceEditForm.js';
import { INVOICE_UPDATES, InvoiceUpdatesTab } from './InvoiceUpdatesTab.js';
import { InvoicePageHeader } from './invoicePageHeader/InvoicePageHeader.js';
import { useInvoiceAccountSubscription } from '../apollo/useInvoiceAccount.js';
import { invoiceTabs } from './common/constants.js';

function InvoiceCardBody() {
  const { isEdit } = useRouterQuery(['isEdit']);

  return isEdit ? <InvoiceEditForm /> : <InvoiceDetails />;
}

function InvoiceAccountTab() {
  return (
    <DefaultBodyWrapper>
      <InvoiceCardBody />
    </DefaultBodyWrapper>
  );
}

function InvoiceFilesTab() {
  const { invoiceId } = useRouterParams(['invoiceId']);

  return (
    <FilesTab
      collection={filesCollections.OCR_INVOICES}
      entity={{
        _id: invoiceId,
        name: entities.OCR_INVOICE,
      }}
    />
  );
}

const usePrefetchInvoiceCard = () => {
  const { result } = useInvoiceAccountSubscription();

  const entity = {
    _id: result?.ocrInvoice?._id,
    name: entities.OCR_INVOICE,
  };

  useEntityFiles({
    collection: UpdateCollections.ocrInvoices,
    entity,
  });

  useEntityUpdates({
    query: INVOICE_UPDATES,
    entity,
  });

  return {
    result,
  };
};

function TabsByPermissions() {
  const ifHasPermission = useHasUserAccessWithPermission(READ_TASK_PERMISSION);

  const tabs = [
    [
      'Info',
      invoiceTabs.invoiceAccount,
      <InvoiceAccountTab key={invoiceTabs.invoiceAccount} />,
    ],
    [
      'Tasks',
      invoiceTabs.invoiceTasks,
      <InvoiceTasksTab key={invoiceTabs.invoiceTasks} />,
      READ_TASK_PERMISSION,
    ],
    [
      'Updates',
      invoiceTabs.invoiceUpdates,
      <InvoiceUpdatesTab key={invoiceTabs.invoiceUpdates} />,
      READ_UPDATE_PERMISSION,
    ],
    [
      'Files',
      invoiceTabs.invoiceFiles,
      <InvoiceFilesTab key={invoiceTabs.invoiceFiles} />,
      READ_FILE_PERMISSION,
    ],
  ];

  return (
    <PageTabs
      viewerHasPermission={ifHasPermission}
      defaultValue={invoiceTabs.invoiceAccount}
      tabs={tabs}
    />
  );
}

function InvoicePageC() {
  return (
    <>
      <InvoicePageHeader />
      <TabsByPermissions />
    </>
  );
}

export function InvoicePage() {
  const { result } = usePrefetchInvoiceCard();

  if (result.loading) return <Loader />;
  if (!result?.ocrInvoice) return <NotFoundPage />;

  return <InvoicePageC />;
}
