import * as R from 'ramda';
import React from 'react';
import { number, bool, arrayOf, shape, string } from 'prop-types';
import { LinkButton } from 'poly-book-admin';
import { openPrintWindowWithData } from 'poly-client-utils';
import { isNilOrEmpty } from 'poly-utils';
import {
  useMapConfigToTableProps,
  useNotificationState,
  CommonPrintLayout,
} from 'poly-admin-ui';
import { PRINT_PDF_CAPTION } from 'poly-constants';
import styled from 'styled-components';

import { report1099TableConfig } from './Report1099Table/Report1099Table.js';
import { Report1099TableC } from './Report1099Table/Report1099TableC.js';

const ReportSubtitle = styled.div`
  text-align: center;
`;

export function Report1099PrintPDFBtn({ year, disabled, filteredRows }) {
  const { showErrorNotification } = useNotificationState();

  const { headers, columns } = useMapConfigToTableProps(
    R.identity,
    report1099TableConfig,
    [],
  );

  const onClick = async () => {
    if (isNilOrEmpty(filteredRows)) {
      showErrorNotification('No report records to print');
      return;
    }

    await openPrintWindowWithData({
      Layout: CommonPrintLayout,
      Table: Report1099TableC,
      metaData: {
        title: '1099 Report',
        additional: <ReportSubtitle>{year}</ReportSubtitle>,
      },
      fileName: '1099_report',
      rows: filteredRows,
      headers,
      columns,
    });
  };

  return (
    <LinkButton onClick={onClick} disabled={disabled}>
      {PRINT_PDF_CAPTION}
    </LinkButton>
  );
}

export const filteredRowsPropTypes = arrayOf(
  shape({
    name: string,
    taxName: string,
    paidByCC: number,
    paidByOthers: number,
    taxId: string,
    addressTwo: string,
    state: string,
  }),
);

Report1099PrintPDFBtn.propTypes = {
  year: number,
  disabled: bool,
  filteredRows: filteredRowsPropTypes,
};
