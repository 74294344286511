import React from 'react';
import { RoutesGenerator } from 'poly-client-routing';
import { NotificatorProvider, OldVersionDetector } from 'poly-admin-ui';

import {
  ThemeProvider,
  StoreProvider,
  ApolloClientProvider,
} from './providers.js';
import { routesConfig } from './routes/routesConfig.js';

export function App() {
  return (
    <StoreProvider>
      <ApolloClientProvider>
        <ThemeProvider>
          <NotificatorProvider>
            <RoutesGenerator config={routesConfig} />
            <OldVersionDetector />
          </NotificatorProvider>
        </ThemeProvider>
      </ApolloClientProvider>
    </StoreProvider>
  );
}

App.displayName = 'App';
