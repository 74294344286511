import React from 'react';
import * as R from 'ramda';
import { formatDate } from 'poly-utils';

import { SupplierLink, PropertyLink } from '../../containers/links/Links.js';
import { getHighlightedSearchItemValue } from './helpers.js';

// getReferenceIdByMissingInvoice :: MissingInvoiceEntry -> String
const getReferenceIdByMissingInvoice = R.path([
  'recurringInvoice',
  'referenceId',
]);

// getAccountNumberByMissingInvoice :: MissingInvoiceEntry -> String
const getAccountNumberByMissingInvoice = R.path([
  'recurringInvoice',
  'supplierOuterContractId',
]);

// getSupplierNameByMissingInvoice :: MissingInvoiceEntry -> String
const getSupplierNameByMissingInvoice = R.path([
  'recurringInvoice',
  'supplier',
  'company',
  'name',
]);

// getPropertyNameByMissingInvoice :: MissingInvoiceEntry -> String
const getPropertyNameByMissingInvoice = R.path([
  'recurringInvoice',
  'property',
  'name',
]);

export const missingInvoicesTableConfig = [
  [
    'RINV #',
    getHighlightedSearchItemValue(getReferenceIdByMissingInvoice),
    getReferenceIdByMissingInvoice,
  ],
  [
    'Supplier',
    (missingInvoiceEntry) => (
      <SupplierLink
        name={getHighlightedSearchItemValue(getSupplierNameByMissingInvoice)(
          missingInvoiceEntry,
        )}
        _id={R.path(
          ['recurringInvoice', 'supplier', '_id'],
          missingInvoiceEntry,
        )}
      />
    ),
    getSupplierNameByMissingInvoice,
  ],
  [
    'Account Number',
    getHighlightedSearchItemValue(getAccountNumberByMissingInvoice),
    getAccountNumberByMissingInvoice,
  ],
  [
    'Property',
    (missingInvoiceEntry) => (
      <PropertyLink
        name={getHighlightedSearchItemValue(getPropertyNameByMissingInvoice)(
          missingInvoiceEntry,
        )}
        _id={R.path(
          ['recurringInvoice', 'property', '_id'],
          missingInvoiceEntry,
        )}
      />
    ),
    getPropertyNameByMissingInvoice,
  ],
  ['Receipt Method', R.path(['recurringInvoice', 'receiptMethod'])],
  [
    'Missing Dates',
    R.compose(
      R.map((date) => {
        const dateStr = formatDate(date);
        return <div key={dateStr}>{dateStr}</div>;
      }),
      R.prop('expectedDates'),
    ),
  ],
];
