import React from 'react';
import * as R from 'ramda';
import { string, func, shape } from 'prop-types';
import { FormPage } from 'poly-book-admin';
import {
  usePristineSubscribe,
  useUpdateQueryParams,
} from 'poly-client-routing';
import { useDispatch } from 'react-redux';
import {
  propertyFormObjectToMutation,
  propertyToEditForm,
} from './utils/utils.js';
import { propertyFormSections } from './form/sections/index.js';
import { formDataToMutationBase } from '../../../utils/form.js';
import { useUpdateProperty } from '../../../hooks/properties/useUpdateProperty.js';
import { useSaveContacts } from '../fields/ContactSelect/useSaveContacts.js';
import { setProcesses } from '../../../redux/processes.js';
import { useOnSubmitSetStopSubmitting } from '../../../hooks/useOnSubmitSetStopSubmitting.js';

// editPropertyToMutation :: Property -> FormValues -> UpdatePropertyInput
const editPropertyToMutation = formDataToMutationBase(
  propertyFormObjectToMutation,
  ['buildingInfo', 'alarmInfo', 'financialCoding'],
);

export function EditPropertyForm(props) {
  const {
    formId,
    property,
    propertyId,
    transformConfig,
    showSuccessNotification,
  } = props;

  const dispatch = useDispatch();

  const { updateProperty } = useUpdateProperty();

  const updateQueryParams = useUpdateQueryParams();

  const saveContacts = useSaveContacts();

  const propertyCustomFields = R.pathOr(
    {},
    ['client', 'configs', 'propertyFields'],
    property,
  );

  const initialValues = propertyToEditForm(property);

  const onSubmitForm = async (formData) => {
    dispatch(setProcesses({ [formId]: true }));
    const clientId = R.path(['client', '_id'], formData);

    const [branchManagerResult] = await saveContacts(
      formData,
      propertyId,
      clientId,
      ['branchManager'],
    ).catch(() => dispatch(setProcesses({ [formId]: false })));

    let managers = {
      branchManagerId: R.path(['contactId'], branchManagerResult),
    };

    if (propertyCustomFields.districtFacilityManager) {
      const [districtFacilityManagerResult] = await saveContacts(
        formData,
        propertyId,
        clientId,
        ['districtFacilityManager'],
      ).catch(() => dispatch(setProcesses({ [formId]: false })));

      managers = {
        ...managers,
        districtFacilityManagerId: R.path(
          ['contactId'],
          districtFacilityManagerResult,
        ),
      };
    }

    const toMutationObject = editPropertyToMutation(initialValues)({
      ...formData,
      ...managers,
    });

    await updateProperty({
      id: propertyId,
      input: R.dissocPath(
        ['buildingInfo', 'customBuildingType'],
        toMutationObject,
      ),
    });

    showSuccessNotification('Property was successfully updated');
    updateQueryParams({ isEdit: false });
    dispatch(setProcesses({ [formId]: false }));
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, onSubmitForm);

  const subscriptionProps = usePristineSubscribe();

  return (
    <FormPage
      {...props}
      {...subscriptionProps}
      id={formId}
      sections={transformConfig(propertyFormSections)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    />
  );
}

EditPropertyForm.displayName = 'EditPropertyForm';

EditPropertyForm.propTypes = {
  formId: string.isRequired,
  propertyId: string.isRequired,
  transformConfig: func,
  showSuccessNotification: func,
  property: shape({
    clients: shape({
      configs: shape({
        propertyFields: shape({
          districtFacilityManager: shape({
            clientId: string,
          }),
        }),
      }),
    }),
  }),
};
