import React, { useMemo } from 'react';
import { gql, useMutation } from '@apollo/client';
import { useOnSubmitSetStopSubmitting } from 'poly-admin-ui';
import { useNotificationContext } from 'poly-book-admin';
import { collectionNames } from 'poly-constants';

import { CreateBatchInvoicesFooterC } from './createBatchInvoicesFooterC/CreateBatchInvoicesFooterC.js';
import { useApprovalInvoices } from './useApprovalInvoices.js';

const CREATE_BATCH_INVOICES = gql`
  mutation CREATE_BATCH_INVOICES(
    $collectionName: ValidBatchCollection!
    $documentIds: [ID!]!
  ) {
    createBatch(collectionName: $collectionName, documentIds: $documentIds)
  }
`;

export function CreateBatchInvoicesFooter() {
  const {
    id,
    total,
    isLoading,
    selectedRows,
    debouncedRefetch,
    setSelectedRowsDispatched,
  } = useApprovalInvoices();
  const { showSuccessNotification } = useNotificationContext();
  const [createBatchInvoices] = useMutation(CREATE_BATCH_INVOICES);

  const onSubmitHandler = async () => {
    await createBatchInvoices({
      variables: {
        documentIds: selectedRows,
        collectionName: collectionNames.ocrInvoices,
      },
    });
    debouncedRefetch();
    setSelectedRowsDispatched([]);
    showSuccessNotification(
      'Batch has been created, \n Invoices statuses updated',
    );
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(id, onSubmitHandler);

  const btnProps = useMemo(
    () => ({
      size: 'small',
      onClick: onSubmit,
      loader: isLoading,
      disabled: !selectedRows.length || isLoading,
    }),
    [isLoading, selectedRows],
  );

  return <CreateBatchInvoicesFooterC btnProps={btnProps} total={total} />;
}

CreateBatchInvoicesFooter.displayName = 'CreateBatchInvoicesFooter';
