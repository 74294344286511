import { gql } from '@apollo/client';

export const batchFields = gql`
  fragment batchFields on Batch {
    _id
    batchId
    createdAt
    documentIds
    workflow {
      ... on TiaaBatchWorkflow {
        total
        approvedAt
        xeroFundedAt
        status
      }
    }
  }
`;
