import * as R from 'ramda';
import { OCRInvoiceStatuses } from 'poly-constants';
import { routes } from '../../routes/constants.js';
import { useInvoiceAccount } from '../../modules/invoice/apollo/useInvoiceAccount.js';

export const useCustomActiveByVerifiedInvoice = (isInvoiceRoute) => {
  const { data } = useInvoiceAccount(isInvoiceRoute);

  return {
    customActive:
      data && data.ocrInvoice?.workflow.status === OCRInvoiceStatuses.VERIFIED,
  };
};

export const prepareMenuItems = R.curry((customActive, items) =>
  R.ifElse(R.isNil, R.always(items), () =>
    R.map(
      R.cond([
        [
          R.propEq('link', routes.QUEUE),
          R.mergeRight({ customActive: !customActive }),
        ],
        [R.propEq('link', routes.APPROVAL), R.mergeRight({ customActive })],
        [R.T, R.identity],
      ]),
      items,
    ),
  )(customActive),
);
