import {
  keywordSortQuery,
  useEntitiesByReactiveSearch,
} from 'poly-client-utils';
import { gql } from '@apollo/client';
import { ASC_SORT_ORDER } from 'poly-constants';
import { usePagination } from 'poly-admin-ui';
import { useSelector } from 'react-redux';

import { commonSupplierFields } from './fragments.js';

export const SUPPLIERS_BY_SEARCH_FILTER = gql`
  query SUPPLIERS_BY_SEARCH_FILTER($searchInput: CollectionSearchParams!) {
    searchSuppliers(input: $searchInput) {
      hits {
        ...commonSupplierFields
      }
      total
    }
  }

  ${commonSupplierFields}
`;

export const SUPPLIER_SEARCH_CHANGED = gql`
  subscription SUPPLIER_SEARCH_CHANGED($searchInput: CollectionSearchParams!) {
    searchSupplierChanged(input: $searchInput) {
      id
      type
    }
  }
`;

const suppliersSearchDefaultSort = keywordSortQuery(['company', 'name'])(
  ASC_SORT_ORDER,
);

export const useSuppliersBySearch = ({
  sort = suppliersSearchDefaultSort,
  status,
}) => {
  const { pagination } = usePagination();

  const searchText = useSelector((state) => state.searchText);

  const { loading, refetch, result } = useEntitiesByReactiveSearch({
    gqlSearchQuery: SUPPLIERS_BY_SEARCH_FILTER,
    gqlSearchChangedQuery: SUPPLIER_SEARCH_CHANGED,
    query: { term: { status } },
    sort,
    pagination,
    alias: 'useSuppliersBySearch',
    searchText,
  });

  return {
    loading,
    refetch,
    result,
  };
};
