import * as R from 'ramda';
import React from 'react';
import { number, bool } from 'prop-types';
import { LinkButton } from 'poly-book-admin';
import { useNotificationState } from 'poly-admin-ui';
import { isNilOrEmpty } from 'poly-utils';
import {
  performExcelExport,
  ExcelExportCellType,
  createExcelExportCell,
} from 'poly-client-utils';
import { EXPORT_XLS_CAPTION } from 'poly-constants';
import { filteredRowsPropTypes } from './Report1099PrintPDFBtn.js';

// get1099ReportRow :: Object -> [ExcelExportDataCell]
const get1099ReportRow = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 8),
    ...R.repeat(ExcelExportCellType.defaultCurrency, 1),
  ]),
  R.juxt([
    R.propOr('', 'name'),
    R.propOr('', 'taxId'),
    R.propOr('', 'is1099'),
    R.propOr('', 'address'),
    R.propOr('', 'addressTwo'),
    R.propOr('', 'city'),
    R.propOr('', 'state'),
    R.propOr('', 'zip'),
    R.propOr(0, 'compensation'),
  ]),
);

// eslint-disable-next-line import/no-unused-modules
export const get1099ExportExcelConfig = (report1099Rows, year) => ({
  exportFileName: 'suppliers_1099.xlsx',
  columnWidths: [20, 15, 10, 45, 45, 15, 10, 10, 15],
  rows: [
    [{ value: '1099 Report', cellType: ExcelExportCellType.title }],
    [{ value: year, cellType: ExcelExportCellType.subTitle }],

    R.zipWith(
      createExcelExportCell,
      [
        ...R.repeat(ExcelExportCellType.tableHeader, 8),
        ...R.repeat(ExcelExportCellType.tableCurrencyHeader, 1),
      ],
      [
        'Name',
        'Tax Id',
        '1099',
        'Address',
        'Address Two',
        'City',
        'State',
        'Zip',
        'Amount',
      ],
    ),

    ...report1099Rows.map(get1099ReportRow),
  ],
});

export function Report1099ExportXLSBtn({ year, disabled, filteredRows }) {
  const { showErrorNotification } = useNotificationState();

  const onClick = async () => {
    if (isNilOrEmpty(filteredRows)) {
      showErrorNotification('No report records to export');
      return;
    }

    const config = get1099ExportExcelConfig(filteredRows, year);
    await performExcelExport(config);
  };

  return (
    <LinkButton onClick={onClick} disabled={disabled}>
      {EXPORT_XLS_CAPTION}
    </LinkButton>
  );
}

Report1099ExportXLSBtn.propTypes = {
  year: number,
  disabled: bool,
  filteredRows: filteredRowsPropTypes,
};
