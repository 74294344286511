import * as R from 'ramda';
import React from 'react';
import { formatTotal, unwindByProp } from 'poly-utils';
import {
  commonSortQuery,
  keywordSortQuery,
  formatDateProp,
  useSortableTable,
} from 'poly-client-utils';
import { useMapConfigToTableProps, useSetItemsCount } from 'poly-admin-ui';
import { Loader } from 'poly-book-admin';
import {
  InvoiceLink,
  InvoicePDFLink,
} from '../../../../containers/links/Links.js';
import { SpendDetailsTable as SpendDetailsTableC } from './SpendDetailsTableC.js';
import { useSpendDetailReport } from '../withSpendDetailReport.js';
import { getGlCodeByCategoryId } from '../../../../utils/constants/serviceTypes.js';
import { calculateSubtotalLine } from '../spendDetailsUtils.js';

const tableConfig = [
  ['Invoice ID', InvoiceLink, commonSortQuery(['invoiceId'])],
  ['Invoice #', InvoicePDFLink, keywordSortQuery(['invoiceNumber'])],
  [
    'Invoice Date',
    formatDateProp('invoiceDate'),
    commonSortQuery(['invoiceDate']),
  ],
  [
    'Property',
    R.path(['property', 'name']),
    commonSortQuery(['property', 'name', 'keyword']),
  ],
  [
    'Supplier',
    R.path(['supplier', 'company', 'name']),
    commonSortQuery(['supplier', 'company', 'name', 'keyword']),
  ],
  ['Service Type', R.path(['lines', 'serviceType', 'psCategoryId'])],
  [
    'GL Code',
    R.pipe(
      R.path(['lines', 'serviceType', 'psCategoryId']),
      getGlCodeByCategoryId,
    ),
  ],
  ['Total', R.compose(formatTotal, calculateSubtotalLine, R.path(['lines']))],
];

// getOCRInvoicesTotalCount :: SearchOCRInvoicesQueryResult -> Number
const getOCRInvoicesTotalCount = R.pathOr(0, ['searchOCRInvoices', 'total']);

// getOCRInvoices :: SearchOCRInvoicesQueryResult -> [Invoice]
const getOCRInvoices = R.pipe(
  R.pathOr([], ['searchOCRInvoices', 'hits']),
  unwindByProp('lines'),
);

export function SpendDetailsTable() {
  const { sort, ...tableSortingProps } = useSortableTable({
    tableConfig,
    column: 1,
  });

  const { loading, result, filters } = useSpendDetailReport(sort);

  useSetItemsCount(getOCRInvoicesTotalCount, result);

  const tableProps = useMapConfigToTableProps(
    getOCRInvoices,
    tableConfig,
    result,
  );

  if (loading) return <Loader />;

  return (
    <SpendDetailsTableC
      {...result}
      {...tableSortingProps}
      {...tableProps}
      {...filters}
    />
  );
}
