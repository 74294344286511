import React, { useState } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useRouterParams } from 'poly-client-routing';
import { DESC_SORT_ORDER, EXPORT_XLS_CAPTION } from 'poly-constants';
import { commonSortQuery, performExcelExport } from 'poly-client-utils';
import { usePaginationParams } from 'poly-admin-ui';
import { useApolloClient } from '@apollo/client';
import { LinkButtonWithLoader } from 'poly-book-admin';

import { OCR_INVOICES_BY_SEARCH } from '../../invoice/apollo/useOcrInvoicesBySearch.js';
import {
  getInvoicesExportExcelConfigBase,
  getInvoicesRowConfigBase,
} from '../../property/PropertyPage/PropertyInvoicesExportXLSBtn.js';

const useGetOcrInvoices = () => {
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();

  const searchTerm = useSelector((state) => state.searchText);

  const paginationParams = usePaginationParams();

  const { supplierId } = useRouterParams(['supplierId']);

  const getOcrInvoicesP = async () => {
    setLoading(true);
    const result = await apolloClient.query({
      query: OCR_INVOICES_BY_SEARCH,
      variables: {
        searchInput: {
          searchTerm,
          query: {
            match: { supplierId },
          },
          sort: commonSortQuery(['invoiceId'])(DESC_SORT_ORDER),
          ...paginationParams,
        },
      },
      fetchPolicy: 'network-only',
    });
    setLoading(false);
    return result;
  };

  return { getOcrInvoicesP, loading };
};

// getSupplierInvoicesRowConfig :: [Invoice] -> [ExcelExportDataCell]
const getSupplierInvoicesRowConfig = getInvoicesRowConfigBase(
  R.pathOr('', ['property', 'name']),
);

// eslint-disable-next-line import/no-unused-modules
export const getSupplierInvoicesExportExcelConfig =
  getInvoicesExportExcelConfigBase({
    fileName: 'supplier_invoices.xlsx',
    titleName: 'Property',
    getRows: getSupplierInvoicesRowConfig,
  });

export function SecureProjectsExportXLSBtn() {
  const { getOcrInvoicesP, loading } = useGetOcrInvoices();

  const handleClick = async () => {
    const { data } = await getOcrInvoicesP();

    const invoices = R.pathOr([], ['searchOCRInvoices', 'hits'], data);

    const excelConfig = getSupplierInvoicesExportExcelConfig(invoices);

    performExcelExport(excelConfig);
  };

  return (
    <LinkButtonWithLoader loader={loading} onClick={handleClick}>
      {EXPORT_XLS_CAPTION}
    </LinkButtonWithLoader>
  );
}
