import * as R from 'ramda';
import { notAllLoaded } from '../../../utils/general.js';
import { useFetchMore } from '../../../utils/hooks/apollo.js';

// fetchIf :: {SearchOcrInvoicesQueryResult, isFetchingMore} -> Bool
const fetchIf = R.allPass([
  R.pathSatisfies(notAllLoaded, ['searchOCRInvoices', 'hits', 'length']),
  R.propEq('isFetchingMore', false),
]);

// getHits :: (SearchOcrInvoicesQueryPreviousResult, fetchMoreInvoicesQueryResult) -> [Invoice]
const getHits = (searchOCRInvoices, fetchMoreResult) =>
  R.concat(
    R.prop('hits', searchOCRInvoices),
    R.path(['searchOCRInvoices', 'hits'], fetchMoreResult),
  );

export const useFetchMoreOCRInvoices = ({ data, fetchMore }) => {
  const { loadMore, isFetchingMore } = useFetchMore({
    fetchIf,
    getVariables: (input) => ({
      searchInput: {
        ...input.searchInput,
        from: R.path(['searchOCRInvoices', 'hits', 'length'], input),
      },
    }),
    updateQuery: ({ searchOCRInvoices }, { fetchMoreResult }) => ({
      searchOCRInvoices: {
        ...R.prop('searchOCRInvoices', fetchMoreResult),
        hits: getHits(searchOCRInvoices, fetchMoreResult),
      },
    }),
    fetchMore,
    data,
  });

  return {
    loadMore,
    isFetchingMore,
  };
};
