import { getMaxLengthValidator } from 'poly-client-utils';
import {
  MAX_OCR_INVOICE_DESCRIPTION_LENGTH,
  MAX_OCR_INVOICE_NUMBER_LENGTH,
} from 'poly-constants';
import * as R from 'ramda';

// positiveNumber :: Number -> Bool
const gteZero = R.gte(R.__, 0);

// validateRegularField :: Object -> (Object, Object) -> Object
const validateRegularField =
  (formData) =>
  (errors, { path, validators }) =>
    R.pipe(
      R.find(([validator]) => !validator(R.path(path, formData), formData)),
      R.prop('1'),
      R.ifElse(R.identity, R.assocPath(path, R.__, errors), R.always(errors)),
    )(validators);

// validateArrayField :: FormValue -> [Validator] -> Error
const validateArrayField = (value, validators) =>
  R.reduceWhile(
    R.isNil,
    (errors, validator) => R.reduce(validateRegularField(value), {}, validator),
    null,
    validators,
  );

// validateLineField :: [Validator] -> FormData -> [Object] | undefined
// Validator = [Any -> Boolean, String]
// FormData = Object
export const validateLineField = (validators) =>
  R.pipe(
    R.map((arrayItemToValidate) =>
      validateArrayField(arrayItemToValidate, validators),
    ),
    R.when(R.isEmpty, () => [{ lines: 'Should provide line items' }]),
    R.ifElse(R.all(R.isEmpty), R.always(undefined), R.identity),
  );

export const lineFieldValidationRules = [
  [
    {
      path: ['description'],
      validators: [
        [R.identity, 'Description is required'],
        getMaxLengthValidator(MAX_OCR_INVOICE_DESCRIPTION_LENGTH),
      ],
    },
    {
      path: ['serviceTypeId'],
      validators: [[R.identity, 'Service type is required']],
    },
    {
      path: ['price'],
      validators: [
        [R.identity, 'Price is required'],
        [gteZero, 'Price should be greater or equal than 0'],
      ],
    },
    {
      path: ['quantity'],
      validators: [
        [R.identity, 'Quantity is required'],
        [gteZero, 'Quantity should be greater or equal than 0'],
      ],
    },
  ],
];

// validateInvoiceNum :: String -> Bool
const validateInvoiceNum = R.test(/(?=.*[a-zA-Z0-9]).*/);

export const invoiceNumberRules = [
  [validateInvoiceNum, 'Invoice # should contain at least one digit or letter'],
  getMaxLengthValidator(MAX_OCR_INVOICE_NUMBER_LENGTH),
];
