import * as R from 'ramda';
import React from 'react';
import { filesCollections, UpdateCollections } from 'poly-constants';
import { useRouterParams, useRouterQuery } from 'poly-client-routing';
import styled from 'styled-components';
import {
  SUPPLIER_UPDATES_QUERY,
  SUPPLIER_PEOPLE_QUERY,
  entities,
  useShowByAllowedTabs,
  usePagination,
  NotFoundPage,
} from 'poly-admin-ui';
import { READ_FILE_PERMISSION, READ_UPDATE_PERMISSION } from 'poly-security';
import { Loader, ToolBarBtnDivider } from 'poly-book-admin';
import { PageTabs } from '../../../components/tabs/PageTabs.js';
import { SupplierAccountInfo } from '../SupplierAccountTab/SupplierAccountTab.js';
import { SupplierOCRInvoicesTab } from '../../invoice/EntityInvoicesTab/index.js';
import { useSupplierAccount } from '../apollo/useSupplierAccount.js';
import { usePrefetchSupplierCard } from '../apollo/prefetches.js';
import { SupplierPageHeader } from './SupplierDetailsPageHeader.js';
import { FilesTab } from '../../../containers/tabs/FilesTab.js';
import { UpdatesTab } from '../../../containers/tabs/UpdatesTab.js';
import { PeopleTab } from '../../../containers/tabs/PeopleTab.js';
import { SearchInput } from '../../../containers/SearchInput.js';
import { FlexContainer } from '../../invoice/InvoicePage/common/styles.js';
import { SupplierInvoicesPrintBtn } from './PrintSupplierInvoicesBtn.js';
import { SecureProjectsExportXLSBtn } from './ExportXLSSupplierInvoicesBtn.js';
import { supplierTabs } from './constants.js';
import { EditSupplierForm } from '../SupplierForm/EditSupplierForm.js';

function SupplierFilesTab() {
  const { supplier, loading } = useSupplierAccount();

  if (loading) return <Loader />;

  const entity = {
    _id: supplier._id,
    name: entities.SUPPLIER,
    humanReadableId: R.path(['company', 'name'], supplier),
  };

  return <FilesTab collection={filesCollections.SUPPLIERS} entity={entity} />;
}

function SupplierUpdatesTab() {
  const { supplier, loading } = useSupplierAccount();

  if (loading) return <Loader />;

  const entity = {
    _id: supplier._id,
    name: entities.SUPPLIER,
    humanReadableId: R.path(['company', 'name'], supplier),
  };

  return (
    <UpdatesTab
      collection={UpdateCollections.suppliers}
      entity={entity}
      updatesQuery={SUPPLIER_UPDATES_QUERY}
    />
  );
}

function SupplierInvoicesTab() {
  const { supplierId } = useRouterParams(['supplierId']);

  return (
    <SupplierOCRInvoicesTab
      query={{
        match: { supplierId },
      }}
    />
  );
}

function SupplierPeopleTab() {
  const { pagination } = usePagination();

  const { supplierId } = useRouterParams(['supplierId']);

  const entity = {
    name: entities.SUPPLIER,
    _id: supplierId,
  };

  return (
    <PeopleTab
      entity={entity}
      pagination={pagination}
      gqlSearchQuery={SUPPLIER_PEOPLE_QUERY}
    />
  );
}

const ToolBarWrapper = styled(FlexContainer)`
  align-items: center;
`;

function TabsToolBarC() {
  return (
    <ToolBarWrapper>
      <SearchInput />
      <SupplierInvoicesPrintBtn />
      <ToolBarBtnDivider />
      <SecureProjectsExportXLSBtn />
    </ToolBarWrapper>
  );
}

function TabsToolBar() {
  const isAllowedToShow = useShowByAllowedTabs([supplierTabs.supplierInvoices]);

  return isAllowedToShow ? <TabsToolBarC /> : null;
}

function SupplierPageC() {
  const { isEdit } = useRouterQuery(['isEdit']);
  return (
    <>
      <SupplierPageHeader tab={supplierTabs.supplierAccount} />
      {isEdit ? (
        <EditSupplierForm />
      ) : (
        <PageTabs
          tabs={[
            [
              'Info',
              supplierTabs.supplierAccount,
              <SupplierAccountInfo key={supplierTabs.supplierAccount} />,
            ],
            [
              'Invoices',
              supplierTabs.supplierInvoices,
              <SupplierInvoicesTab key={supplierTabs.supplierInvoices} />,
            ],
            [
              'Updates',
              supplierTabs.supplierUpdates,
              <SupplierUpdatesTab key={supplierTabs.supplierUpdates} />,
              READ_UPDATE_PERMISSION,
            ],
            [
              'Contacts',
              supplierTabs.supplierPeople,
              <SupplierPeopleTab key={supplierTabs.supplierPeople} />,
            ],
            [
              'Files',
              supplierTabs.supplierFiles,
              <SupplierFilesTab key={supplierTabs.supplierFiles} />,
              READ_FILE_PERMISSION,
            ],
          ]}
          toolBar={<TabsToolBar />}
          defaultValue={supplierTabs.supplierAccount}
        />
      )}
    </>
  );
}

export function SupplierPage() {
  const { loading, supplierAccount } = usePrefetchSupplierCard();

  if (loading) return <Loader />;
  if (!supplierAccount?.supplier) return <NotFoundPage />;

  return <SupplierPageC />;
}
