import { gql } from '@apollo/client';
import { commonUserFields } from 'poly-admin-ui';

export const DEFAULT_USER_QUERY = gql`
  query DEFAULT_USER_QUERY($id: ID!) {
    user(id: $id) {
      ...commonUserFields
    }
  }

  ${commonUserFields}
`;

export const USERS_BY_SEARCH = gql`
  query USERS_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchUsers(input: $searchInput) {
      hits {
        ...commonUserFields
      }
    }
  }

  ${commonUserFields}
`;
