import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { shape, string, bool } from 'prop-types';
import { SupplierStatuses, supplierTypes } from 'poly-constants';
import { UPDATE_SUPPLIER_PERMISSION } from 'poly-security';
import {
  supplierRatingShape,
  pathOrNothingUI,
  useHasUserAccessWithPermission,
} from 'poly-client-utils';
import {
  supplierStatusesColors,
  supplierSourceColors,
  SectionWithBackBtn,
  BackBtn,
  PageHeaderComponentByTabQuery,
} from 'poly-admin-ui';
import {
  EntityStatus,
  getThemeFont,
  MainHeader,
  Rating,
  Text,
} from 'poly-book-admin';
import { forceTitleCase } from 'poly-utils';
import { routes } from '../../../routes/constants.js';
import { editSupplierFormId } from '../SupplierForm/constants.js';
import { ActionButtons } from '../../../containers/buttons/ActionButtons.js';
import { useSupplierAccount } from '../apollo/useSupplierAccount.js';

const { SUBCONTRACTOR } = supplierTypes;

const SupplierSourceTagWrapper = styled(Text)`
  font-size: ${R.propOr('23px', 'size')};
  color: ${R.prop('color')};
  font-weight: ${getThemeFont(['bold'])};
  margin: 0 15px;
`;

const SupplierNameS = styled(MainHeader)`
  max-width: 58%;
  line-height: 34px;
`;

const SectionWithBackBtnS = styled(SectionWithBackBtn)`
  max-width: 84%;
`;

function SupplierSourceTag({ source, type, ...rest }) {
  return (
    <SupplierSourceTagWrapper
      {...{ color: supplierSourceColors[source], ...rest }}
    >
      {source}
      {type && ` / ${forceTitleCase(type)}`}
    </SupplierSourceTagWrapper>
  );
}

SupplierSourceTag.propTypes = {
  source: string,
  type: string,
};

function ActionButtonsWithRender(props) {
  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_SUPPLIER_PERMISSION,
  );

  return ifHasPermission ? (
    <ActionButtons {...props} permission={UPDATE_SUPPLIER_PERMISSION} />
  ) : null;
}

function SupplierPageHeaderComp({
  type,
  status,
  cardNumber,
  fallbackLink,
  source,
  name,
  rating,
  tab,
  supplierId,
  isBranch,
}) {
  const title = `${isBranch ? 'Branch ' : ''}Supplier / ${R.toLower(type)}`;
  return (
    <PageHeaderComponentByTabQuery key={supplierId}>
      <SectionWithBackBtnS>
        <BackBtn fallbackLink={fallbackLink} />
        <SupplierNameS>{name}</SupplierNameS>
        <EntityStatus title={title} status={status} cardNumber={cardNumber} />
        <SupplierSourceTag source={source} />
        {type === SUBCONTRACTOR && <Rating value={rating} readOnly />}
      </SectionWithBackBtnS>
      <ActionButtonsWithRender
        formId={editSupplierFormId}
        tab={tab}
        processName={editSupplierFormId}
      />
    </PageHeaderComponentByTabQuery>
  );
}

SupplierPageHeaderComp.propTypes = {
  status: shape({
    color: string.isRequired,
    text: string.isRequired,
  }),
  type: string.isRequired,
  rating: supplierRatingShape,
  source: string,
  name: string.isRequired,
  cardNumber: string.isRequired,
  fallbackLink: string.isRequired,
  tab: string.isRequired,
  supplierId: string,
  isBranch: bool.isRequired,
};

// getCommonProps :: SearchSupplierQueryResult => Object
const getCommonProps = R.applySpec({
  status: {
    color: R.pipe(
      R.propOr(SupplierStatuses.ACTIVE, R.path(['supplier', 'status'])),
      R.toLower,
      R.prop(R.__, supplierStatusesColors),
    ),
    text: R.compose((s) => forceTitleCase(s), R.path(['supplier', 'status'])),
  },
  source: R.path(['supplier', 'misc', 'source']),
  name: pathOrNothingUI(['supplier', 'company', 'name']),
  cardNumber: pathOrNothingUI(['supplier', 'cardNumber']),
  fallbackLink: R.always(routes.SUPPLIERS),
  type: R.propOr(supplierTypes.ADMINISTRATIVE, R.path(['supplier', 'type'])),
  rating: R.path(['supplier', 'rating']),
  supplierId: R.path(['supplier', '_id']),
  isBranch: R.pathSatisfies(R.complement(R.isNil), [
    'supplier',
    'masterSupplier',
  ]),
});

export function SupplierPageHeader(props) {
  const { data } = useSupplierAccount();

  const commonProps = getCommonProps(data);

  return <SupplierPageHeaderComp {...props} {...commonProps} />;
}

SupplierPageHeader.displayName = 'SupplierPageHeader';
