import React from 'react';
import {
  MainHeader,
  PageHeaderContainer,
  ToolBarBtnDivider,
  DefaultBodyWrapper,
} from 'poly-book-admin';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { highlightMatchesInObject, useSortableTable } from 'poly-client-utils';
import styled, { css } from 'styled-components';

import { SearchInput } from '../../containers/SearchInput.js';
import {
  UserManagementTableWithPagination,
  usersTableConfig,
} from './UserManagementTable.js';
import { UserManagementExportXLSBtn } from './UserManagementExportXLSBtn.js';
import { UserManagementExportPdfBtn } from './UserManagementExportPdfBtn.js';
import { useUserManagementQuery } from './useUserManagementQuery.js';

const headerStyles = css`
  height: 80px;
  z-index: 3;
`;

const FlexWrapperS = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

const FlexS = styled.div`
  display: flex;
  align-items: center;
`;

const TableSearchInputS = styled(SearchInput)`
  margin-right: 20px;
`;

const DefaultBodyWrapperS = styled(DefaultBodyWrapper)`
  overflow-y: auto;
`;

// highlightUsersMatches :: String -> User -> User
const highlightUsersMatches = (search) => (object) =>
  highlightMatchesInObject({
    object,
    paths: [['fullName']],
    search,
  });

export function UserManagementPage() {
  const { sort, ...tableSortProps } = useSortableTable({
    column: 2,
    tableConfig: usersTableConfig,
  });
  const { users, loading } = useUserManagementQuery(sort);
  const searchTerm = useSelector(R.prop('searchText'));

  const highlightedUsers = users.map(highlightUsersMatches(searchTerm));

  return (
    <>
      <PageHeaderContainer {...{ headerStyles }}>
        <FlexWrapperS>
          <MainHeader>User Management </MainHeader>
          <FlexS>
            <TableSearchInputS />
            <UserManagementExportPdfBtn users={users} loading={loading} />
            <ToolBarBtnDivider />
            <UserManagementExportXLSBtn users={users} loading={loading} />
          </FlexS>
        </FlexWrapperS>
      </PageHeaderContainer>
      <DefaultBodyWrapperS>
        <UserManagementTableWithPagination
          users={highlightedUsers}
          loading={loading}
          tableSortProps={tableSortProps}
        />
      </DefaultBodyWrapperS>
    </>
  );
}
