import * as R from 'ramda';

/**
 * prepareFormDataForMutation :: FormData -> MutationData
 */
export const prepareFormDataForMutation = R.pick(['name', 'status']);

/**
 * prepareInitialValuesForForm :: ModalData -> FormInitialValues
 */
export const prepareInitialValuesForForm = R.compose(
  R.mergeDeepRight({ isEdit: true }),
  R.pick(['_id', 'name', 'status']),
  R.defaultTo({}),
);
