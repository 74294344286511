import * as R from 'ramda';
import { SupplierSources } from 'poly-constants';

import { routes } from '../../../routes/constants.js';

/**
 * prepareFormDataForMutation :: FormData -> MutationData
 */
export const prepareFormDataForMutation = R.compose(
  R.mergeDeepRight({ supplierSource: SupplierSources.TIAA }),
  R.pick(['name', 'status', 'xeroAccountCode']),
);

/**
 * serviceTypeNamesFromProps :: QueryResult -> ModifiedNames
 */
export const serviceTypeNamesFromProps = ({ data, ownProps }) => ({
  items: R.pipe(
    R.pathOr([], ['searchServiceTypes', 'hits']),
    R.reject(R.propEq('_id', ownProps.skipServiceTypeId)),
    R.map(
      R.applySpec({
        url: R.always(routes.SERVICE_TYPES),
        name: R.prop('name'),
      }),
    ),
  )(data),
});

// serviceTypeNameQueryFromProps :: Props -> Query
export const serviceTypeNameQueryFromProps = ({ nameSearch }) => ({
  searchInput: {
    size: 3,
    query: {
      bool: {
        should: [
          { term: { 'name.keyword': { value: nameSearch, boost: 3 } } },
          { term: { name: nameSearch } },
        ],
      },
    },
  },
});
