import React from 'react';
import * as R from 'ramda';
import { string, bool, arrayOf, element } from 'prop-types';
import { NOTHING_UI_STRING } from 'poly-constants';
import { BooleanTextWithDot, SectionWithCard } from 'poly-book-admin';
import {
  getUserCellPhoneNumber,
  getUserWorkPhoneNumber,
  getUserWorkPhoneObject,
  getUserCellPhoneObject,
  getUserFaxPhoneObject,
  formatPhoneNumber,
} from 'poly-utils';

import { EmailLink } from './Links.js';
import { InternationalPhoneDisplay } from './InternationalAddressAndPhone/InternationalPhone/InternationalPhoneDisplay.js';
import { isClientContact } from '../modules/forms/peopleForm/edit/EditPeopleForm.js';
import { formatAddress } from '../utils/address.js';

// getFormattedPhoneByGetter :: Function -> User -> String
const getFormattedPhoneByGetter = (getter) =>
  R.compose(formatPhoneNumber('Ext'), getter);

export function ContactInfoSection({
  fullName,
  isPrimary,
  references,
  isInternational,
  isClientManager,
  showClientManagers,
  ...props
}) {
  const list = [
    {
      title: 'Name',
      elem: `${fullName} ${isPrimary ? '(Primary Acct Contact)' : ''}`,
    },
    { title: 'Title', elem: R.propOr(NOTHING_UI_STRING, 'title')(props) },
    { title: 'Email', elem: <EmailLink email={R.prop('email', props)} /> },
    {
      title: 'Phone',
      elem: isInternational ? (
        <InternationalPhoneDisplay phone={getUserWorkPhoneNumber(props)} />
      ) : (
        getFormattedPhoneByGetter(getUserWorkPhoneObject)(props)
      ),
    },
    {
      title: 'Mobile',
      elem: isInternational ? (
        <InternationalPhoneDisplay phone={getUserCellPhoneNumber(props)} />
      ) : (
        getFormattedPhoneByGetter(getUserCellPhoneObject)(props)
      ),
    },
    ...(isInternational
      ? []
      : [
          {
            title: 'Fax',
            elem: getFormattedPhoneByGetter(getUserFaxPhoneObject)(props),
          },
        ]),
    { title: 'Reference Cards', elem: references },
    { title: 'Address', elem: formatAddress(props) },
    ...(isClientContact(props) && showClientManagers
      ? [
          {
            title: 'Client Manager',
            elem: <BooleanTextWithDot isTrue={isClientManager} />,
          },
        ]
      : []),
  ];

  return <SectionWithCard title="Info" list={list} />;
}

ContactInfoSection.propTypes = {
  isPrimary: bool,
  fullName: string,
  isInternational: bool,
  isClientManager: bool,
  showClientManagers: bool,
  references: arrayOf(element),
};
