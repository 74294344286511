import React from 'react';
import arrayMutators from 'final-form-arrays';
import { useSelector } from 'react-redux';
import { FormCreator } from 'poly-book-admin';
import { shape, string } from 'prop-types';
import { verifyInvoiceFormSection } from './sections.js';
import { getInitialFormData, prepareInvoiceDataToVerify } from './utils.js';
import { useHandleInvoiceSave } from '../../InvoicePage/common/hooks.js';
import { useVerifyInvoice } from '../../apollo/useVerifyInvoice.js';
import { InvoiceWorkflow } from '../../../../utils/constants/invoice.js';

export function InvoiceVerifyForm({ invoice }) {
  const entity = useSelector((state) => state.entity);

  const { verifyInvoice } = useVerifyInvoice();

  const { handleInvoiceSave } = useHandleInvoiceSave();

  const onSubmit = (invoiceUpdate) =>
    handleInvoiceSave({
      saveWorkflow: InvoiceWorkflow.VERIFY,
      invoice,
      invoiceUpdate: {
        id: invoice._id,
        update: prepareInvoiceDataToVerify(invoiceUpdate),
      },
      whenNoDuplicates: () =>
        verifyInvoice({
          id: invoice._id,
          update: prepareInvoiceDataToVerify(invoiceUpdate),
        }),
    });

  const invoiceVerifyFormProps = {
    onSubmit,
    mutators: { ...arrayMutators },
    validateOnBlur: true,
    initialValues: getInitialFormData(entity)(invoice),
    sections: verifyInvoiceFormSection,
    layout: { card: false, group: { height: '100%' } },
    keepDirtyOnReinitialize: false,
  };

  return <FormCreator {...invoiceVerifyFormProps} />;
}

InvoiceVerifyForm.propTypes = {
  invoice: shape({
    invoiceId: string.isRequired,
    invoiceDate: string.isRequired,
    invoiceNumber: string.isRequired,
    invoiceFileLink: string.isRequired,
  }),
};
