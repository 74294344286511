import React from 'react';
import { bool } from 'prop-types';
import styled, { css } from 'styled-components';
import { Table, moneyColumnStyles } from 'poly-book-admin';

import { LoadingMsg } from '../../../../components/loaders/LoadingMsg.js';

const withSelectableStyles = css`
  th:first-child,
  td:first-child {
    width: 60px;
  }
`;

const TableS = styled(Table)`
  min-width: 1024px;
  padding-bottom: 3px;

  th:nth-child(5),
  td:nth-child(5),
  th:nth-child(6),
  td:nth-child(6),
  th:last-child,
  td:last-child {
    width: 9%;
  }

  ${moneyColumnStyles(7)};

  @media (max-width: 1450px) {
    th:nth-child(5),
    td:nth-child(5),
    th:nth-child(6),
    td:nth-child(6),
    th:last-child,
    td:last-child {
      width: 12%;
    }
  }

  th:last-child,
  td:last-child {
    text-align: center;
  }

  ${({ selectedRows }) => selectedRows && withSelectableStyles};
`;

export function QueueTableC({ isFetchingMore, ...props }) {
  return (
    <>
      <TableS {...props} />
      {isFetchingMore ? <LoadingMsg>Loading...</LoadingMsg> : null}
    </>
  );
}

QueueTableC.propTypes = {
  isFetchingMore: bool.isRequired,
};
