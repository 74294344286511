import * as R from 'ramda';
import React from 'react';
import { SupplierSources } from 'poly-constants';
import { FormCreator } from 'poly-book-admin';
import { usePristineSubscribe } from 'poly-client-routing';
import { useOnSubmitSetStopSubmitting, useProcessState } from 'poly-admin-ui';
import { createSupplierModalSections } from './sections.js';
import { addSupplierFormModal, initialValues } from './constants.js';
import { ModalFormWrapper } from '../../../containers/modal/ModalWrapper.js';
import { prepareFormDataForMutation } from './utils.js';
import { useCreateSupplier } from './useCreateSupplier.js';

function AddSupplierFormModal(props) {
  const { createSupplier } = useCreateSupplier();

  const subscriptionProps = usePristineSubscribe();

  const onSubmitHandler = R.compose(
    createSupplier,
    R.mergeDeepRight({ misc: { source: SupplierSources.TIAA } }),
    prepareFormDataForMutation,
  );

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    addSupplierFormModal,
    onSubmitHandler,
  );

  return (
    <FormCreator
      {...props}
      {...subscriptionProps}
      onSubmit={onSubmit}
      id={addSupplierFormModal}
      sections={createSupplierModalSections}
      layout={{
        width: '500px',
        overflow: 'visible',
        card: false,
      }}
      initialValues={initialValues}
    />
  );
}

AddSupplierFormModal.displayName = 'AddSupplierModalForm';

export function CreateSupplierModal(props) {
  const { process } = useProcessState(addSupplierFormModal);

  return (
    <ModalFormWrapper
      {...props}
      actionInProgress={!!process}
      title="Create Supplier"
      buttonLabel="Create"
      formId={addSupplierFormModal}
      Form={AddSupplierFormModal}
      width="500px"
    />
  );
}
