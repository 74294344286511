import React from 'react';
import { Button, useNotificationContext } from 'poly-book-admin';
import { useOnSubmitSetStopSubmitting, useProcessState } from 'poly-admin-ui';
import { gql, useMutation } from '@apollo/client';
import { useRouterParams } from 'poly-client-routing';

const sendXeroProcessId = 'sendXeroProcessId';

const SEND_BATCH_TO_XERO_MUTATION = gql`
  mutation SEND_BATCH_TO_XERO_MUTATION($batchId: ID!) {
    sendBatchToXero(batchId: $batchId) {
      _id
      workflow {
        ... on TiaaBatchWorkflow {
          status
        }
      }
    }
  }
`;

const useSendBatchToXero = () => {
  const [mutate] = useMutation(SEND_BATCH_TO_XERO_MUTATION);

  return {
    sendBatchToXero: (batchId) =>
      mutate({
        variables: {
          batchId,
        },
      }),
  };
};

export function SendToXeroBtn() {
  const { batchId } = useRouterParams(['batchId']);
  const { process } = useProcessState(sendXeroProcessId);

  const { showSuccessNotification } = useNotificationContext();

  const { sendBatchToXero } = useSendBatchToXero();

  const onSubmitHandler = async () => {
    await sendBatchToXero(batchId);
    showSuccessNotification('Successfully uploaded batch to Xero');
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    sendXeroProcessId,
    onSubmitHandler,
  );

  return (
    <Button
      onClick={onSubmit}
      id={sendXeroProcessId}
      size="small"
      enableButtonAfterSubmitDone={false}
      loader={process}
    >
      Send To Xero
    </Button>
  );
}

SendToXeroBtn.displayNAme = 'SendToXeroBtn';
