export const propertyTabs = {
  propertyInfo: 'property-info',
  propertyInvoices: 'property-invoices',
  propertyFiles: 'property-files',
  propertyUpdates: 'property-updates',
  propertyTasks: 'property-tasks',
  propertyPeople: 'property-people',
  propertySuppliers: 'property-suppliers',
};

export const addPropertyFormId = 'addPropertyFormId';

export const editPropertyFormId = 'editPropertyFormId';
