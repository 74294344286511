import React from 'react';
import { SectionWithCard, EntityAddress } from 'poly-book-admin';
import { supplierAccountPropsTypes, W9FileLink } from 'poly-admin-ui';
import { NOTHING_UI_STRING } from 'poly-constants';
import { formatTaxId, formatSocialSecurity } from 'poly-utils';

import { BooleanTextWithDot } from '../utils.js';

export function FinancialSection({
  terms,
  acceptsCreditCard,
  is1099,
  taxClass,
  taxId,
  exempt,
  w9FileUrl,
  w9FileName,
  taxAddress1,
  taxAddress2,
  taxName,
  socialSecurityNumber,
  hasUserTaxPrivateFieldsAccess,
}) {
  return (
    <SectionWithCard
      title="Financial"
      list={[
        { title: 'AP Report', elem: NOTHING_UI_STRING },
        {
          title: 'W9',
          elem: exempt ? (
            'Exempt'
          ) : (
            <W9FileLink
              w9FileUrl={w9FileUrl}
              w9FileName={w9FileName}
              hasUserTaxPrivateFieldsAccess={hasUserTaxPrivateFieldsAccess}
            />
          ),
        },
        { title: 'Terms', elem: terms },
        { title: 'Is 1099', elem: <BooleanTextWithDot isTrue={is1099} /> },
        {
          title: 'Accepts Credit Card',
          elem: <BooleanTextWithDot isTrue={acceptsCreditCard} />,
        },

        { title: 'Company Type', elem: taxClass },
        {
          title: 'Tax ID No',
          elem: formatTaxId(taxId),
        },
        { title: 'Tax Name', elem: taxName },
        {
          title: 'Tax Address',
          elem: (
            <EntityAddress address={taxAddress1} addressTwo={taxAddress2} />
          ),
        },
        {
          title: 'Social Security #',
          elem: formatSocialSecurity(socialSecurityNumber),
        },
      ]}
    />
  );
}

FinancialSection.propTypes = supplierAccountPropsTypes.financialPropTypes;
