import { usePagination } from 'poly-admin-ui';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { getInvoicesQueryVars } from '../invoices.js';
import { INVOICES_QUERY } from '../../apollo/queries/invoices.js';

export const useSelectedInvoices = () => {
  const selectedRows = useSelector((state) => state.selectedRows);

  const { pagination } = usePagination();

  const { data, loading } = useQuery(
    INVOICES_QUERY,
    getInvoicesQueryVars(selectedRows, pagination),
  );

  return {
    data,
    loading,
    selectedRows,
  };
};

export const useFetchMore = ({
  fetchIf,
  getVariables,
  updateQuery,
  fetchMore,
  data,
}) => {
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  return {
    loadMore: () => {
      if (fetchIf({ ...data, isFetchingMore })) {
        setIsFetchingMore(true);
        fetchMore({
          variables: getVariables(data),
          updateQuery: (prev, next) => {
            setIsFetchingMore(false);
            return updateQuery(prev, next);
          },
        });
      }
    },
    isFetchingMore,
  };
};
