import * as R from 'ramda';
import React from 'react';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { CREATE_SUPPLIER_PERMISSION } from 'poly-security';
import { SupplierStatuses } from 'poly-constants';
import { Heading, PageHeaderContainer, Status } from 'poly-book-admin';
import { useNavigate } from 'poly-client-routing';
import { string } from 'prop-types';
import { forceTitleCase } from 'poly-utils';
import { SuppliersTable } from './SuppliersTable.js';
import { PageTabs } from '../../components/tabs/PageTabs.js';
import { SearchInput } from '../../containers/SearchInput.js';
import { LinkButtonS } from '../invoice/InvoicePage/common/styles.js';
import { routes } from '../../routes/constants.js';
import { usePrefetchSuppliers } from './apollo/prefetches.js';
import { supplierStatusesColors } from '../../utils/constants/statuses.js';
import { useSuppliersBySearch } from './apollo/useSuppliersBySearch.js';

// getTotalSuppliers :: SearchSuppliersQueryResult -> Number
const getTotalSuppliers = R.pathOr(0, ['searchSuppliers', 'total']);

function SupplierTabTitle({ status }) {
  const { result } = useSuppliersBySearch({ status });

  const count = getTotalSuppliers(result);
  const color = supplierStatusesColors[status];
  const text = forceTitleCase(status);

  return <Status text={`${text} (${count})`} {...{ color }} />;
}

SupplierTabTitle.displayName = 'SupplierTabTitle';

SupplierTabTitle.propTypes = {
  status: string,
};

const suppliersTabs = R.map(
  (status) => [
    <SupplierTabTitle {...{ status }} />,
    status,
    <SuppliersTable {...{ status }} />,
  ],
  R.values(SupplierStatuses),
);

function TabsWithPrefetch(props) {
  const prefetchSuppliersProps = usePrefetchSuppliers();

  return <PageTabs {...props} {...prefetchSuppliersProps} />;
}

function AddSupplierLink() {
  const navigate = useNavigate();

  const ifHasPermission = useHasUserAccessWithPermission(
    CREATE_SUPPLIER_PERMISSION,
  );

  return ifHasPermission ? (
    <LinkButtonS type="button" onClick={() => navigate(routes.ADD_SUPPLIER)}>
      Add New
    </LinkButtonS>
  ) : null;
}

export function SuppliersPage() {
  return (
    <>
      <PageHeaderContainer>
        <Heading.h2>Suppliers</Heading.h2>
        <AddSupplierLink />
      </PageHeaderContainer>
      <TabsWithPrefetch
        toolBar={<SearchInput />}
        tabs={suppliersTabs}
        defaultValue={SupplierStatuses.ACTIVE}
      />
    </>
  );
}
