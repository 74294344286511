import * as R from 'ramda';
import { useState } from 'react';
import {
  highlightTextToReactElement,
  filterDocumentsMatchedByPaths,
} from 'poly-client-utils';
import { assocBy } from 'poly-utils';

export const useSearchTextState = () => {
  const [searchText, setSearchText] = useState('');

  const onSearchTextChange = (event) => {
    const newText = event.target.value;
    setSearchText(newText);
  };

  return [searchText, onSearchTextChange];
};

// filterBySearchTextWithHighlight :: ([Path],  String) -> [a] -> [a]
//   a = Object
export const filterBySearchTextWithHighlight = (searchPaths, searchText) =>
  R.compose(
    R.map(
      assocBy(
        'highlightSearchMatch',
        R.always(highlightTextToReactElement(searchText)),
      ),
    ),
    filterDocumentsMatchedByPaths(searchPaths, searchText),
  );

// getHighlightedSearchItemValue :: (a -> String) -> a -> ReactNode
//   a = Object
export const getHighlightedSearchItemValue = (getterFn) => (searchItem) =>
  R.compose(searchItem.highlightSearchMatch, getterFn)(searchItem);
