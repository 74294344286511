import * as R from 'ramda';
import { NOTHING_UI_STRING, PSCategoriesDescription } from 'poly-constants';

/**
 * getGLCodeByCategory :: String -> ServiceType -> String
 */
export const getGLCodeByCategory = R.curry((propName, data) =>
  R.compose(
    R.defaultTo(NOTHING_UI_STRING),
    R.when(R.complement(R.isNil), R.nth(1)),
    R.find(R.__, PSCategoriesDescription),
    R.propEq(0),
    R.prop(propName),
  )(data),
);
