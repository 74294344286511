import { gql } from '@apollo/client';
import { ASC_SORT_ORDER, OCRInvoiceStatuses } from 'poly-constants';
import { usePagination, useProcessState } from 'poly-admin-ui';
import {
  useEntitiesByReactiveReduxSearch,
  commonSortQuery,
} from 'poly-client-utils';

import { batchFields } from './fragments.js';
import { approveInvoicesFormId } from '../BatchPage/common/constants.js';
import { OCR_INVOICES_BY_SEARCH_SUB } from '../../invoice/apollo/useOcrInvoicesBySearch.js';
import { useBatchDetails } from './useBatchSubscription.js';

const batchInvoicesFields = gql`
  fragment batchInvoicesFields on OCRInvoice {
    _id
    invoiceId
    invoiceFileLink
    dueDate
    lines {
      serviceType {
        psCategoryId
      }
    }
    invoiceNumber
    invoiceDate
    total
    supplier {
      _id
      company {
        name
      }
    }
    property {
      _id
      name
    }
  }
`;

export const BATCHES_QUERY = gql`
  query BATCHES($searchInput: CollectionSearchParams) {
    searchBatches(input: $searchInput) {
      hits {
        ...batchFields
      }
      total
    }
  }

  ${batchFields}
`;

export const BATCHES_SUBS = gql`
  subscription BATCHES($input: CollectionSearchParams) {
    searchBatchChanged(input: $input) {
      id
      type
    }
  }
`;

const BATCHES_INVOICES_QUERY = gql`
  query BATCHES_INVOICES_QUERY(
    $batchId: ID!
    $searchInput: CollectionSearchParams
  ) {
    batch(id: $batchId) {
      _id
      searchOCRInvoices(input: $searchInput) {
        hits {
          ...batchInvoicesFields
        }
        total
      }
      workflow {
        ... on TiaaBatchWorkflow {
          status
        }
      }
    }
  }
  ${batchInvoicesFields}
`;

const defaultSort = commonSortQuery(['invoiceId'])(ASC_SORT_ORDER);

export const useBatchInvoicesBySearch = (sort = defaultSort) => {
  const { pagination } = usePagination();
  const { process } = useProcessState(approveInvoicesFormId);
  const { data, loading: detailsLoading } = useBatchDetails();

  const query = {
    bool: {
      must_not: [{ match: { status: OCRInvoiceStatuses.BLOCKED } }],
    },
  };

  const skipQuery = process || !data?.batch?._id || detailsLoading;

  const { result, loading } = useEntitiesByReactiveReduxSearch({
    gqlSearchQuery: BATCHES_INVOICES_QUERY,
    gqlSearchChangedQuery: OCR_INVOICES_BY_SEARCH_SUB,
    additionalVars: { batchId: data?.batch._id },
    pagination,
    skipQuery,
    query,
    sort,
  });

  return {
    loading: detailsLoading || loading,
    result,
  };
};
