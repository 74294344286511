import * as R from 'ramda';
import React from 'react';
import { FormPage, Loader } from 'poly-book-admin';
import arrayMutators from 'final-form-arrays';
import { isBefore } from 'date-fns';
import { useOnSubmitSetStopSubmitting } from 'poly-admin-ui';
import { usePristineSubscribe } from 'poly-client-routing';
import { ensureIsDate } from 'poly-utils';
import {
  invoiceDataToUpdateMutation,
  invoiceToFormData,
} from './invoiceEditUtils.js';
import { invoiceEditSections } from './sections.js';
import { INVOICE_EDIT_FORM_ID } from './invoiceEditConstants.js';
import { InvoiceWorkflow } from '../../../../utils/constants/invoice.js';
import { useHandleInvoiceSave } from '../common/hooks.js';
import { useOcrInvoiceDetailsForEditForm } from '../../apollo/useOcrInvoiceDetailsForEditForm.js';
import { useUpdateOCRInvoice } from '../../apollo/useUpdateOcrInvoice.js';

// dueDateAfterInvoiceDate :: Invoice -> Boolean
const dueDateAfterInvoiceDate = ({ invoiceDate, dueDate }) =>
  isBefore(ensureIsDate(dueDate), invoiceDate);

// invoiceDateAfterReceiveDate :: Invoice -> Boolean
const invoiceDateAfterReceiveDate = ({ invoiceDate, receiveDate }) =>
  isBefore(ensureIsDate(receiveDate), invoiceDate);

// emptyObjectUnless :: (Any -> Boolean, Any -> Any) -> Object
const emptyObjectUnless = R.ifElse(R.__, R.__, () => ({}));

const validateForm = R.compose(
  R.converge(R.unapply(R.mergeAll), [
    emptyObjectUnless(dueDateAfterInvoiceDate, () => ({
      dueDate: "Due date can't be earlier than invoice date",
    })),
    emptyObjectUnless(invoiceDateAfterReceiveDate, () => ({
      invoiceDate: "Receive date can't be earlier than invoice date",
    })),
  ]),
);

export function InvoiceEditForm() {
  const { data, loading } = useOcrInvoiceDetailsForEditForm();

  const { updateOCRInvoice } = useUpdateOCRInvoice();

  const { handleInvoiceSave } = useHandleInvoiceSave();

  const onSubmitHandler = (invoiceUpdate) =>
    handleInvoiceSave({
      saveWorkflow: InvoiceWorkflow.EDIT,
      invoice: data?.ocrInvoice,
      invoiceUpdate: {
        id: data?.ocrInvoice?._id,
        update: invoiceDataToUpdateMutation(invoiceUpdate),
      },
      whenNoDuplicates: () =>
        updateOCRInvoice({
          id: data?.ocrInvoice?._id,
          update: invoiceDataToUpdateMutation(invoiceUpdate),
        }),
    });

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    INVOICE_EDIT_FORM_ID,
    onSubmitHandler,
  );

  const subscriptionProps = usePristineSubscribe();

  if (loading) return <Loader />;

  return (
    <FormPage
      {...subscriptionProps}
      validate={validateForm}
      sections={invoiceEditSections}
      initialValues={invoiceToFormData(data?.ocrInvoice)}
      id={INVOICE_EDIT_FORM_ID}
      mutators={{ ...arrayMutators }}
      onSubmit={onSubmit}
      validateOnBlur
    />
  );
}

InvoiceEditForm.displayName = 'InvoiceEditForm';
