import React from 'react';
import {
  collectionNames,
  filesCollections,
  UpdateCollections,
} from 'poly-constants';
import {
  entities,
  NotFoundPage,
  useEntityFiles,
  useEntityUpdates,
  useShowByAllowedTabs,
} from 'poly-admin-ui';
import { ToolBarBtnDivider, Loader } from 'poly-book-admin';
import { READ_FILE_PERMISSION, READ_UPDATE_PERMISSION } from 'poly-security';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { PageTabs } from '../../../components/tabs/PageTabs.js';
import { BatchInvoicesTable } from './BatchInvoicesTable.js';
import { BatchHeader } from './BatchPageHeader.js';
import { batchTabs } from './common/constants.js';
import {
  BATCH_UPDATES,
  useBatchDetails,
  useBatchSubscription,
} from '../apollo/useBatchSubscription.js';
import { FilesTab } from '../../../containers/tabs/FilesTab.js';
import { UpdatesTab } from '../../../containers/tabs/UpdatesTab.js';
import { CheckPaymentFileBtn as CheckPaymentFileBtnImp } from './CheckPaymentFileBtn.js';
import { GenerateBatchApprovalFileBtn } from './GenerateBatchApprovalFileBtn.js';
import { isAllowedCheckPaymentFile } from './common/utils.js';

function BatchFilesTab() {
  const { data, loading } = useBatchDetails();

  if (loading) return <Loader />;

  return (
    <FilesTab
      {...data}
      collection={filesCollections.BATCHES}
      entity={{
        _id: data?.batch?._id,
        name: entities.BATCH,
        humanReadableId: data?.batch?.batchId,
      }}
    />
  );
}

function BatchUpdatesTab() {
  const { data, loading } = useBatchDetails();

  if (loading) return <Loader />;

  return (
    <UpdatesTab
      {...data}
      collection={UpdateCollections.batches}
      updatesQuery={BATCH_UPDATES}
      entity={{
        _id: data?.batch?._id,
        name: entities.BATCH,
        humanReadableId: data?.batch?.batchId,
      }}
    />
  );
}

function CheckPaymentFileBtnWithDivider() {
  return (
    <>
      <CheckPaymentFileBtnImp />
      <ToolBarBtnDivider />
    </>
  );
}

function CheckPaymentFileBtn() {
  const ifHasPermission = useHasUserAccessWithPermission(
    READ_UPDATE_PERMISSION,
  );

  const { data } = useBatchDetails();

  if (!isAllowedCheckPaymentFile(data?.batch)) return null;

  return ifHasPermission ? <CheckPaymentFileBtnWithDivider /> : null;
}

function BatchPageToolBarC() {
  return (
    <>
      <CheckPaymentFileBtn />
      <GenerateBatchApprovalFileBtn />
    </>
  );
}

function BatchPageToolBar() {
  const isAllowedToShow = useShowByAllowedTabs([
    batchTabs.batchInvoices,
    undefined,
  ]);

  return isAllowedToShow ? <BatchPageToolBarC /> : null;
}

function BatchPageC() {
  return (
    <>
      <BatchHeader />
      <PageTabs
        tabs={[
          [
            'Invoices',
            batchTabs.batchInvoices,
            <BatchInvoicesTable key={batchTabs.batchInvoices} />,
          ],
          [
            'Updates',
            batchTabs.batchUpdates,
            <BatchUpdatesTab key={batchTabs.batchUpdates} />,
            READ_UPDATE_PERMISSION,
          ],
          [
            'Files',
            batchTabs.batchFiles,
            <BatchFilesTab key={batchTabs.batchFiles} />,
            READ_FILE_PERMISSION,
          ],
        ]}
        toolBar={<BatchPageToolBar />}
        defaultValue={batchTabs.batchInvoices}
      />
    </>
  );
}

export function BatchPage() {
  const { result } = useBatchSubscription();

  const entity = {
    _id: result?.batch?._id,
    name: entities.BATCH,
  };

  useEntityFiles({
    collection: collectionNames.batches,
    entity,
  });

  useEntityUpdates({ query: BATCH_UPDATES, entity });

  if (result.loading) return <Loader />;
  if (!result?.batch) return <NotFoundPage />;

  return <BatchPageC />;
}
