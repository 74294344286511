import * as R from 'ramda';
import React from 'react';
import { useRouterParams } from 'poly-client-routing';
import { ITEMS_LIMIT } from 'poly-admin-ui';
import { commonSortQuery } from 'poly-client-utils';
import { DESC_SORT_ORDER, PRINT_TYPES } from 'poly-constants';
import { useOcrInvoicesByTextSearch } from '../../invoice/apollo/useOcrInvoicesBySearch.js';
import { TablePrintBtn } from '../../../containers/buttons/TablePrintPDFBtn.js';
import { useSupplierAccount } from '../apollo/useSupplierAccount.js';

// getOCRInvoices :: SearchOCRInvoicesQueryResult -> [OCRInvoice]
export const getOCRInvoices = R.pathOr([], ['searchOCRInvoices', 'hits']);

// getMetaData :: Supplier -> { title: String }
const getMetaData = R.applySpec({ title: R.path(['company', 'name']) });

export function SupplierInvoicesPrintBtn() {
  const { supplierId } = useRouterParams(['supplierId']);

  const { result, loading: invoiceLoading } = useOcrInvoicesByTextSearch({
    query: {
      match: { supplierId },
    },
    sort: commonSortQuery(['invoiceId'])(DESC_SORT_ORDER),
  });

  const { supplier, loading: supplierLoading } = useSupplierAccount();

  const data = getOCRInvoices(result);

  if (invoiceLoading || supplierLoading) {
    return null;
  }

  return (
    <TablePrintBtn
      data={data}
      style={{ marginLeft: 30 }}
      printType={PRINT_TYPES.SUPPLIER_INVOICES_TIAA}
      metaData={getMetaData(supplier)}
      showWarningModal={data.length > ITEMS_LIMIT}
    />
  );
}
