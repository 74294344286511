import React from 'react';
import { useDispatch } from 'react-redux';
import { TextButton } from 'poly-book-admin';
import { string, shape } from 'prop-types';
import { ModalNames } from '../../../utils/constants/modals.js';
import { setOpenModal } from '../../../state/modules/modal.js';

export function AddFileBtn({ collection, entity }) {
  const dispatch = useDispatch();

  const onCancel = () => dispatch(setOpenModal({ name: null }));

  const onClick = () =>
    dispatch(
      setOpenModal({
        name: ModalNames.ADD_FILES,
        payload: {
          documentId: entity._id,
          collection,
        },
      }),
    );

  return (
    <TextButton onCancel={onCancel} onClick={onClick}>
      Add New
    </TextButton>
  );
}

AddFileBtn.propTypes = {
  collection: string,
  entity: shape({
    name: string,
    _id: string,
    humanReadableId: string,
  }),
};
