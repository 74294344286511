import styled from 'styled-components';
import React from 'react';
import { arrayOf, node, oneOfType, string } from 'prop-types';

import { ColumnDirection } from '../lib/ColumnDirection.js';
import { FlexContainer } from '../lib/FlexContainer.js';

const HeaderLinksWrapperS = styled(ColumnDirection)`
  justify-content: space-evenly;
  align-items: flex-end;
  width: auto;
`;

const HeaderTopLinks = styled(FlexContainer)`
  flex-direction: row;
  margin-right: 15px;
`;

const HeaderBottomLinks = styled(HeaderTopLinks)`
  margin: 0;

  > div:first-child {
    margin-right: 15px;
  }
`;

export function PageHeaderLinks({ topLinks, bottomLinks, className }) {
  return (
    <HeaderLinksWrapperS className={className}>
      {!!topLinks && <HeaderTopLinks>{topLinks}</HeaderTopLinks>}
      {!!bottomLinks && <HeaderBottomLinks>{bottomLinks}</HeaderBottomLinks>}
    </HeaderLinksWrapperS>
  );
}

PageHeaderLinks.propTypes = {
  className: string,
  topLinks: oneOfType([arrayOf(node), node]),
  bottomLinks: oneOfType([arrayOf(node), node]),
};

PageHeaderLinks.defaultProps = {
  topLinks: null,
  bottomLinks: null,
};

PageHeaderLinks.displayName = 'PageHeaderLinks';
