import React from 'react';
import { useDispatch } from 'react-redux';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { UPDATE_SERVICE_TYPE } from 'poly-security';
import { TextButton } from 'poly-book-admin';
import { shape, string } from 'prop-types';
import { setOpenModal } from '../../state/modules/modal.js';
import { ModalNames } from '../../utils/constants/modals.js';

export function EditServiceTypeBtn({ serviceType }) {
  const dispatch = useDispatch();

  const ifHasPermission = useHasUserAccessWithPermission(UPDATE_SERVICE_TYPE);

  const onClick = () =>
    dispatch(
      setOpenModal({
        name: ModalNames.EDIT_SERVICE_TYPE,
        payload: { serviceType },
      }),
    );

  return ifHasPermission ? (
    <TextButton onClick={onClick}>Edit</TextButton>
  ) : null;
}

EditServiceTypeBtn.displayName = 'EditServiceTypeBtn';

EditServiceTypeBtn.propTypes = {
  serviceType: shape({
    status: string.isRequired,
    name: string.isRequired,
  }),
};
