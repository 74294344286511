import { insertParamsIntoURL } from 'poly-utils';
import { useNavigate } from 'poly-client-routing';
import { useNotificationContext } from 'poly-book-admin';
import { useMutation } from '@apollo/client';
import { routes } from '../../../routes/constants.js';
import { UPDATE_INVOICE_MUTATION } from './useVerifyInvoice.js';

export const useUpdateOCRInvoice = () => {
  const [mutate] = useMutation(UPDATE_INVOICE_MUTATION, {
    alias: 'useUpdateOCRInvoice',
  });

  const { showSuccessNotification, showErrorNotification } =
    useNotificationContext();

  const navigate = useNavigate();

  return {
    updateOCRInvoice: ({ id, update }) =>
      mutate({
        variables: {
          invoiceId: id,
          update,
        },
      })
        .then(() => {
          showSuccessNotification('Invoice was successfully updated');
          navigate(insertParamsIntoURL({ invoiceId: id }, routes.INVOICE));
        })
        .catch(() => {
          showErrorNotification('Fail to update invoice');
        }),
  };
};
