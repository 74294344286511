import React from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateQueryParams, useNavigate } from 'poly-client-routing';
import {
  CONFIRM_CLOSE_FORM_BTN_CAPTION,
  closeConfirmModal,
} from 'poly-admin-ui';
import { shape, string } from 'prop-types';
import { BasicConfirmButtonComp } from './BasicConfirmButtonComp.js';
import { setOpenModal } from '../../../../state/modules/modal.js';

export function ConfirmCloseEditFormBtn(props) {
  const dispatch = useDispatch();

  const updateQueryParams = useUpdateQueryParams();

  const onSubmit = () => {
    updateQueryParams({ isEdit: false });
    dispatch(closeConfirmModal());
  };

  return (
    <BasicConfirmButtonComp
      {...props}
      caption={CONFIRM_CLOSE_FORM_BTN_CAPTION}
      onSubmit={onSubmit}
    />
  );
}

export function ConfirmCloseFormBtn(props) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onSubmit = () => {
    navigate(props?.confirmBtnPayload?.url);
    dispatch(closeConfirmModal());
  };

  return (
    <BasicConfirmButtonComp
      {...props}
      caption={CONFIRM_CLOSE_FORM_BTN_CAPTION}
      onSubmit={onSubmit}
    />
  );
}

ConfirmCloseFormBtn.propTypes = {
  confirmBtnPayload: shape({
    url: string,
  }),
};

export function ConfirmCloseModalBtn(props) {
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(setOpenModal({ name: null }));
    dispatch(closeConfirmModal());
  };

  return (
    <BasicConfirmButtonComp
      {...props}
      caption={CONFIRM_CLOSE_FORM_BTN_CAPTION}
      onSubmit={onSubmit}
    />
  );
}
