import * as R from 'ramda';
import { ifNotEmpty, validatePhone, validateFormData } from 'poly-client-utils';
import { validateEmail, assocBy, isNilOrEmpty } from 'poly-utils';

// getPhoneByProp :: String -> FormData -> String | Null
const getPhoneByProp = (prop) =>
  R.compose(R.when(isNilOrEmpty, R.always(null)), R.prop(prop));

// getProfilePhoneNumbers :: FormData -> { cellPhoneNumber: String, workPhoneNumber: String }
// UserPhone = {type: String, number: String}
const getProfilePhoneNumbers = R.compose(
  R.applySpec({
    cellPhoneNumber: getPhoneByProp('cell'),
    workPhoneNumber: getPhoneByProp('work'),
  }),
);

// prepareClientUserFormDataForMutation :: FormData -> UserInput
export const prepareClientUserFormDataForMutation = R.compose(
  R.pick([
    'emails',
    'firstName',
    'lastName',
    'userGroupId',
    'userId',
    'profile',
    'status',
  ]),
  assocBy('profile', getProfilePhoneNumbers),
  assocBy('emails', R.compose(R.of, R.prop('email'))),
);

const validationConfig = [
  {
    path: ['email'],
    validators: [
      [R.identity, 'Email is required'],
      [ifNotEmpty(validateEmail), 'Incorrect email'],
    ],
  },
  {
    path: ['work'],
    validators: [[ifNotEmpty(validatePhone), 'Incorrect phone number']],
  },
  {
    path: ['cell'],
    validators: [[ifNotEmpty(validatePhone), 'Incorrect phone number']],
  },
];

export const validateUser = validateFormData(validationConfig);
