import { gql } from '@apollo/client';

export const INVOICES_QUERY = gql`
  query NEW_INVOICES($searchInput: CollectionSearchParams!) {
    searchOCRInvoices(input: $searchInput) {
      total
      hits {
        _id
        invoiceId
        invoiceFileLink
        dueDate
        lines {
          serviceType {
            psCategoryId
          }
        }
        invoiceNumber
        invoiceDate
        total
        supplier {
          _id
          company {
            name
          }
        }
        property {
          _id
          name
        }
      }
    }
  }
`;
