import React from 'react';
import * as R from 'ramda';
import { UserStatuses } from 'poly-constants';
import { forceTitleCase } from 'poly-utils';
import { Select } from 'poly-book-admin';

// getUserStatusOptions :: UserStatuses -> [Option]
const getUserStatusOptions = R.compose(
  R.map(R.applySpec({ value: R.identity, label: forceTitleCase })),
  R.values,
);

export function UserStatusSelect(props) {
  const options = getUserStatusOptions(UserStatuses);

  return <Select {...props} options={options} />;
}
