import * as R from 'ramda';
import React from 'react';
import { bool } from 'prop-types';
import { formatTotal } from 'poly-utils';
import { Loader } from 'poly-book-admin';

import { Report1099TableC } from './Report1099TableC.js';
import { SupplierLink } from '../../../containers/links/Links.js';

export const report1099TableConfig = [
  ['Name', SupplierLink, R.prop('name')],
  ['Tax ID', R.prop('taxId'), R.prop('taxId')],
  ['1099', R.prop('is1099'), R.prop('is1099')],
  ['Address', R.prop('address'), R.prop('address')],
  ['Address Two', R.prop('addressTwo'), R.prop('addressTwo')],
  ['City', R.prop('city'), R.prop('city')],
  ['State', R.prop('state'), R.prop('state')],
  ['Zip', R.prop('zip'), R.prop('zip')],
  [
    'Amount',
    R.compose(formatTotal, R.prop('compensation')),
    R.prop('compensation'),
  ],
];

export function Report1099Table({ loading, ...tableProps }) {
  return loading ? <Loader /> : <Report1099TableC {...tableProps} />;
}

Report1099Table.propTypes = {
  loading: bool,
};
