import * as R from 'ramda';
import {
  uiAndRealInvoiceStatusesRel,
  uiBatchStatusesColors,
  uiInvoiceStatusesColors,
} from './constants/statuses.js';

// getStatusColorAndText :: (Object, Object) -> Object
const getStatusColorAndText = (statusesRel, colors) =>
  R.pipe(
    R.prop(R.__, statusesRel),
    R.converge(
      (color, text) => ({ color, text }),
      [R.prop(R.__, colors), R.identity],
    ),
  );

// getBatchStatusColorAndText :: Object -> String
//   -> {text: String, color: String}
export const getBatchStatusColorAndText = (batchUIStatuses) =>
  getStatusColorAndText(batchUIStatuses, uiBatchStatusesColors);

// getInvoiceStatusColorAndText :: String -> Object
export const getInvoiceStatusColorAndText = getStatusColorAndText(
  uiAndRealInvoiceStatusesRel,
  uiInvoiceStatusesColors,
);
