import * as R from 'ramda';
import {
  performExcelExport,
  createExcelExportCell,
  ExcelExportCellType,
} from 'poly-client-utils';

import { propToTitleCase } from '../../../utils/general.js';

// getSupplierTaxXLSRowConfig :: Supplier -> [ExcelExportDataCell]
const getSupplierTaxXLSRowConfig = R.compose(
  R.zipWith(createExcelExportCell, [
    ...R.repeat(ExcelExportCellType.default, 4),
    ExcelExportCellType.defaultCurrency,
  ]),
  R.juxt([
    R.propOr('', 'name'),
    R.propOr('', 'is1099'),
    propToTitleCase('companyType'),
    R.propOr('', 'taxId'),
    R.propOr(0, 'compensation'),
  ]),
);

// getSuppliersTaxXLSExcelConfig :: [Supplier] -> Object
export const getSuppliersTaxXLSExcelConfig = (suppliers) => ({
  exportFileName: 'supplier_tax.xlsx',
  columnWidths: [40, 15, 45, 15, 15],
  rows: [
    R.zipWith(
      createExcelExportCell,
      [...R.repeat(ExcelExportCellType.tableHeader, 5)],
      ['Name', 'Is 1099', 'Company Type', 'Tax ID', 'Compensation'],
    ),
    ...R.map(getSupplierTaxXLSRowConfig, suppliers),
  ],
});

export const generateXLSReport = (suppliers) => {
  const excelConfig = getSuppliersTaxXLSExcelConfig(suppliers);
  performExcelExport(excelConfig);
};
