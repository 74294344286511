import { gql } from '@apollo/client';

export const commonSupplierFields = gql`
  fragment commonSupplierFields on Supplier {
    _id
    company {
      name
      address {
        formatted_address
      }
      services {
        _id
        name
      }
    }
  }
`;
