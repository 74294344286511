import { bool, func, string } from 'prop-types';
import React from 'react';
import { Button } from 'poly-book-admin';

export function BasicConfirmButtonComp({ onSubmit, loading, caption }) {
  return (
    <Button size="small" disabled={loading} loader={loading} onClick={onSubmit}>
      {caption}
    </Button>
  );
}

BasicConfirmButtonComp.propTypes = {
  onSubmit: func.isRequired,
  loading: bool,
  caption: string.isRequired,
};

BasicConfirmButtonComp.defaultProps = {
  loading: false,
};
