import React from 'react';
import styled from 'styled-components';
import { Button } from 'poly-book-admin';
import { CancelModalFormBtn as CancelModalFormBtnImp } from 'poly-admin-ui/src/components/Button/CancelModalFormBtn.js';
import { ActionButtons as ActionButtonsImp } from 'poly-admin-ui/src/components/Button/ActionButtons.js';
import { CancelFormBtn as CancelFormBtnImp } from 'poly-admin-ui/src/components/Button/CancelFormBtn.js';

import { confirmButtonNames } from '../modal/confirmButtons/constants.js';

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  & > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const SubmitBtn = styled(Button).attrs(() => ({
  size: 'small',
  type: 'submit',
}))``;

export const EditBtn = styled(Button).attrs(() => ({
  styleType: 'basicSecondary',
  type: 'button',
  size: 'small',
  children: 'Edit',
}))`
  background-color: transparent;
`;

export const confirmCancelFormPayload = {
  message: 'You have unsaved data. Are you sure you want to close form?',
  icon: 'confirm',
  cancelBtnCaption: 'No',
};

export function ActionButtons(props) {
  return (
    <ActionButtonsImp
      {...props}
      confirmButtonName={confirmButtonNames.CONFIRM_CLOSE_EDIT_FORM}
    />
  );
}

export function CancelFormBtn(props) {
  return (
    <CancelFormBtnImp
      {...props}
      confirmButtonName={confirmButtonNames.CONFIRM_CLOSE_FORM}
    />
  );
}

export function CancelModalFormBtn(props) {
  return (
    <CancelModalFormBtnImp
      {...props}
      confirmButtonName={confirmButtonNames.CONFIRM_CLOSE_MODAL}
    />
  );
}
