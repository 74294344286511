import React from 'react';
import { formatSelectOptionsByConstants } from 'poly-client-utils';
import { SupplierTypes } from 'poly-constants';
import { Select } from 'poly-book-admin';

export function SupplierTypeSelect(props) {
  const options = formatSelectOptionsByConstants(SupplierTypes);

  const selectProps = {
    ...props,
    options,
    disabled: true,
    name: 'SupplierTypeSelect',
  };

  return <Select {...selectProps} />;
}

SupplierTypeSelect.displayName = 'SupplierTypeSelect';
