import * as R from 'ramda';
import {
  EQUALS_AIT_OPERATOR,
  FLIPPED_CONTAINS_AIT_OPERATOR,
} from './operators.js';

// getAccessItemTypeValuesByKey :: String -> UserGroupAccessItem -> [String]
// Note: this is ad-hoc function that supports
// existing features while we transition to user groups.
// Therefore it isn't recommended to be used by new features/logic.
export const getAccessItemTypeValuesByKey = (key) =>
  R.compose(
    R.reject(R.isNil),
    R.flatten,
    R.juxt([
      R.pathOr(null, ['types', key, EQUALS_AIT_OPERATOR]),
      R.pathOr([], ['types', key, FLIPPED_CONTAINS_AIT_OPERATOR]),
    ]),
  );
