import { useMutation } from '@apollo/client';
import { UPDATE_PROPERTY_MUTATION } from '../../hocs/properties/mutations.js';

export const useUpdateProperty = () => {
  const [mutate] = useMutation(UPDATE_PROPERTY_MUTATION);

  return {
    updateProperty: ({ id, input }) =>
      mutate({
        variables: {
          id,
          input,
        },
      }),
  };
};
