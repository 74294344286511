import React from 'react';
import { LoginPageBaseAdmin } from 'poly-admin-ui';
import { routes } from '../routes/constants.js';

export const APP_TITLE = 'TIAA Payments Platform';

export function LoginPage() {
  return (
    <LoginPageBaseAdmin
      title={APP_TITLE}
      homePageRoute={routes.BATCHES}
      forgotPasswordRoute={routes.FORGOT_PASSWD}
    />
  );
}

LoginPage.displayName = 'LoginPage';
