import React from 'react';
import { number } from 'prop-types';
import * as R from 'ramda';
import { SectionWithCard } from 'poly-book-admin';
import { formatTotal } from 'poly-utils';
import { NOTHING_UI_STRING } from 'poly-constants';

const formatCurrencyOrEmpty = R.ifElse(
  R.isNil,
  R.always(NOTHING_UI_STRING),
  formatTotal,
);

export function RatesSection({ hourly, overTime, weekend, trip }) {
  return (
    <SectionWithCard
      title="Rates"
      list={[
        {
          title: 'Hourly',
          elem: formatCurrencyOrEmpty(hourly),
          width: 25,
        },
        {
          title: 'After Hours',
          elem: formatCurrencyOrEmpty(overTime),
          width: 25,
        },
        {
          title: 'Weekend',
          elem: formatCurrencyOrEmpty(weekend),
          width: 25,
        },
        {
          title: 'Trip Charge',
          elem: formatCurrencyOrEmpty(trip),
          width: 25,
        },
      ]}
    />
  );
}

RatesSection.propTypes = {
  hourly: number,
  overTime: number,
  weekend: number,
  trip: number,
};

RatesSection.defaultProps = {
  hourly: null,
  overTime: null,
  weekend: null,
  trip: null,
};
