import * as R from 'ramda';
import React from 'react';
import { useEntityDuplication } from 'poly-client-utils';
import { shape, string } from 'prop-types';
import { DuplicationInput } from '../../../components/DuplicationInput.js';
import {
  serviceTypeNameQueryFromProps,
  serviceTypeNamesFromProps,
} from './utils.js';
import { SERVICE_TYPES_BY_SEARCH } from '../apollo/withServiceTypesBySearch.js';
import { ServiceTypeStatusSelect } from '../../../components/selects/ServiceTypeStatusSelect.js';
import { ServiceTypeXeroAccountCodeSelect } from '../../../components/selects/ServiceTypeXeroAccountCodeSelect.js';

function ServiceTypeInputComp({ formData, ...restProps }) {
  const skipServiceTypeId = formData?._id || '';

  const duplicationProps = useEntityDuplication({
    query: SERVICE_TYPES_BY_SEARCH,
    itemsFromProps: serviceTypeNamesFromProps,
    variablesFromProps: serviceTypeNameQueryFromProps,
    props: { ...restProps, skipServiceTypeId },
  });

  return (
    <DuplicationInput
      {...restProps}
      {...duplicationProps}
      enabledSimilarNamesInput={false}
      skipServiceTypeId={skipServiceTypeId}
    />
  );
}

ServiceTypeInputComp.propTypes = {
  formData: shape({
    _id: string,
  }),
};

export const createServiceTypeModalSections = [
  {
    label: '',
    order: 1,
    layout: { column: 1 },
    fields: [
      {
        label: 'Status',
        order: 1,
        field: {
          name: 'status',
          Component: ServiceTypeStatusSelect,
        },
        required: true,
      },
      {
        label: 'Service Type',
        order: 2,
        field: {
          name: 'name',
          withFormData: true,
          Component: ServiceTypeInputComp,
        },
        validators: [[R.identity, 'Service name is required']],
        required: true,
      },
      {
        label: 'PeopleSoft ID',
        order: 3,
        field: {
          name: 'xeroAccountCode',
          Component: ServiceTypeXeroAccountCodeSelect,
        },
        renderIf: R.propSatisfies(R.isNil, 'isEdit'),
      },
    ],
  },
];
