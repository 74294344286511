import React from 'react';
import { formatSelectOptionsWithTitleCased } from 'poly-client-utils';
import { ServiceTypeStatuses } from 'poly-constants';
import { Select } from 'poly-book-admin';

export function ServiceTypeStatusSelect(props) {
  const options = formatSelectOptionsWithTitleCased(ServiceTypeStatuses);

  const selectProps = {
    ...props,
    options,
    name: 'ServiceTypeStatusSelect',
  };

  return <Select {...selectProps} />;
}

ServiceTypeStatusSelect.displayName = 'ServiceTypeStatusSelect';
