export const DEFAULT_OCR_INVOICE_LINE_MEASURE = 'EA';

export const OCRInvoiceStatuses = {
  VOID: 'VOID',
  XTRACTA_EXPORT: 'XTRACTA_EXPORT',
  SCANNING: 'SCANNING',
  NEW: 'NEW',
  OCR_READY: 'OCR_READY',
  OCR_ERROR: 'OCR_ERROR',
  VERIFIED: 'VERIFIED',
  BLOCKED: 'BLOCKED',
  PENDING: 'PENDING',
  EXCEPTION: 'EXCEPTION',
  APPROVED: 'APPROVED',
  PAID: 'PAID',
};
