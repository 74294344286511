import React from 'react';
import { useModalContext } from 'poly-admin-ui';
import { TextButton } from 'poly-book-admin';
import { string } from 'prop-types';

import { EditUserForm, editUserFormId } from './userForm/EditUserForm.js';

export function EditUserButton({ userId }) {
  const { openModal } = useModalContext();

  const openEditUserForm = () =>
    openModal({
      id: editUserFormId,
      title: 'Edit User',
      content: <EditUserForm userId={userId} />,
    });

  return <TextButton onClick={openEditUserForm}>Edit</TextButton>;
}

EditUserButton.propTypes = {
  userId: string.isRequired,
};
