import { usePagination } from 'poly-admin-ui';
import { gql } from '@apollo/client';
import { ASC_SORT_ORDER } from 'poly-constants';
import {
  keywordSortQuery,
  useEntitiesByReactiveReduxSearch,
} from 'poly-client-utils';

export const PROPERTIES_BY_SEARCH = gql`
  query PROPERTIES_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchProperties(input: $searchInput) {
      hits {
        _id
        name
        address {
          formatted_address
        }
        clientOuterId
      }
      total
    }
  }
`;

export const PROPERTIES_BY_SEARCH_SUB = gql`
  subscription PROPERTIES_BY_SEARCH_SUB($searchInput: CollectionSearchParams) {
    searchPropertyChanged(input: $searchInput) {
      id
      type
    }
  }
`;

const propertiesDefaultSort = keywordSortQuery(['name'])(ASC_SORT_ORDER);

export const usePropertiesBySearchQuery = (
  status,
  sort = propertiesDefaultSort,
) => {
  const { pagination } = usePagination();

  const { loading, result } = useEntitiesByReactiveReduxSearch({
    gqlSearchQuery: PROPERTIES_BY_SEARCH,
    gqlSearchChangedQuery: PROPERTIES_BY_SEARCH_SUB,
    query: {
      bool: {
        must: {
          term: { status },
        },
      },
    },
    sort,
    pagination,
    skipQuery: !status,
  });

  return {
    loading,
    result,
  };
};
