import * as R from 'ramda';
import { roundTo } from './numbers.js';

/**
 * calcOCRInvoiceTotalByLines :: [Line] -> Number
 * Line = Object
 */
export const calcOCRInvoiceTotalByLines = R.compose(
  roundTo(2),
  R.reduce((acc, { quantity = 0, price = 0 }) => acc + quantity * price, 0),
  R.reject(R.isNil),
  R.defaultTo([]),
);
