import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { object, oneOfType, shape, string, bool } from 'prop-types';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { PageHeaderComponentByTabQuery } from 'poly-admin-ui';
import { UPDATE_PROPERTY_PERMISSION } from 'poly-security';
import { useRouterParams } from 'poly-client-routing';
import { forceTitleCase } from 'poly-utils';
import {
  TableLayoutListWrapper,
  SectionWithCard,
  SectionsWrapper,
  ReadOnlyEditor,
  EntityAddress,
  EntityStatus,
  CardsWrapper,
  SideSection,
  Heading,
  Loader,
} from 'poly-book-admin';

import {
  propertyTabs,
  editPropertyFormId,
} from '../../../utils/constants/property.js';
import { BackButton } from '../../../containers/buttons/BackButton.js';
import { ActionButtons } from '../../../containers/buttons/ActionButtons.js';
import { propertyStatusesColors } from '../../../utils/constants/statuses.js';
import { usePropertyDetails } from '../apollo/withPropertyDetails.js';
import { preparePropertyDetails } from './propertyPageUtils.js';

// getCommonProps :; SearchPropertiesQueryResult -> Object
const getCommonProps = R.applySpec({
  color: R.compose(
    R.prop(R.__, propertyStatusesColors),
    R.path(['property', 'status']),
  ),
  name: R.path(['property', 'name']),
  propertyId: R.path(['property', '_id']),
  cardNumber: R.path(['property', 'cardNumber']),
  status: R.compose((x) => forceTitleCase(x), R.path(['property', 'status'])),
});

// getGeneralInfoSectionProps :: Object -> Object
const getGeneralInfoSectionProps = (props) => {
  const {
    fax,
    tier,
    type,
    phone,
    square,
    address,
    affiliate,
    occupancy,
    ownership,
    clientName,
    budgetCode,
    addressTwo,
    peopleSoftID,
  } = props;

  return {
    list: [
      {
        title: 'Address',
        elem: <EntityAddress address={address} addressTwo={addressTwo} />,
      },
      {
        title: 'Main Account',
        elem: clientName,
      },
      {
        title: 'Phone',
        elem: phone,
      },
      {
        title: 'Fax',
        elem: fax,
      },
      {
        title: 'Occupancy',
        elem: occupancy,
      },
      {
        title: 'Ownership',
        elem: ownership,
      },
      {
        title: 'Budget Code',
        elem: budgetCode,
      },
      {
        title: 'PeopleSoft ID',
        elem: peopleSoftID,
      },
      {
        title: 'Tier',
        elem: tier,
      },
      {
        title: 'Affiliate',
        elem: affiliate,
      },
      {
        title: 'Property Type',
        elem: type,
      },
      {
        title: 'Rentable Sq Ft',
        elem: square,
      },
    ],
  };
};

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

function PropertyPageHeaderComp({
  name,
  color,
  status,
  cardNumber,
  propertyId,
  permission,
  showEditBtn,
}) {
  return (
    <PageHeaderComponentByTabQuery key={propertyId}>
      <FlexContainer>
        <BackButton defaultLink="/properties" />
        <Heading.h2>{name}</Heading.h2>
        <EntityStatus
          cardNumber={cardNumber}
          title="Customer / Property"
          status={{ color, text: status }}
        />
      </FlexContainer>
      {showEditBtn ? (
        <ActionButtons
          formId={editPropertyFormId}
          tab={propertyTabs.propertyInfo}
          processName={editPropertyFormId}
          permission={permission}
        />
      ) : null}
    </PageHeaderComponentByTabQuery>
  );
}

PropertyPageHeaderComp.propTypes = {
  name: string,
  color: string,
  status: string,
  showEditBtn: bool,
  propertyId: string,
  cardNumber: string,
  permission: string.isRequired,
};

export function PropertyPageHeader() {
  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_PROPERTY_PERMISSION,
  );

  const { propertyId } = useRouterParams(['propertyId']);

  const { data } = usePropertyDetails(propertyId);

  const commonProps = getCommonProps(data);

  return (
    <PropertyPageHeaderComp
      {...commonProps}
      showEditBtn={ifHasPermission}
      permission={UPDATE_PROPERTY_PERMISSION}
    />
  );
}

function GeneraInfoSection(props) {
  const generalInfoSectionProps = getGeneralInfoSectionProps(props);

  return <SectionWithCard {...generalInfoSectionProps} />;
}

const RemarksWrapper = SectionWithCard.withComponent(TableLayoutListWrapper);

function RemarksSection({ remarks }) {
  return (
    <RemarksWrapper title="Remarks">
      <ReadOnlyEditor value={remarks} readOnly />
    </RemarksWrapper>
  );
}

RemarksSection.propTypes = {
  remarks: oneOfType([object, string]),
};

function PropertyDetailsComp({ remarks, ...props }) {
  return (
    <CardsWrapper>
      <SectionsWrapper>
        <SideSection>
          <GeneraInfoSection {...props} />
        </SideSection>
        <SideSection>
          <RemarksSection {...{ remarks }} />
        </SideSection>
      </SectionsWrapper>
    </CardsWrapper>
  );
}

PropertyDetailsComp.propTypes = {
  remarks: oneOfType([object, string]),
};

function PropertyDetailsInfoTab({ property, ...restProps }) {
  const commonProps = preparePropertyDetails(property);

  return <PropertyDetailsComp {...restProps} {...commonProps} />;
}

PropertyDetailsInfoTab.displayName = 'PropertyDetailsInfoTab';

PropertyDetailsInfoTab.propTypes = {
  property: shape({
    phone: string,
    fax: string,
    remarks: oneOfType([object, string]),
    occupancy: string,
    ownership: string,
    budgetCode: string,
    peopleSoftID: string,
    tier: string,
    affiliate: string,
    type: string,
  }),
};

export function PropertyDetailsTab() {
  const { propertyId } = useRouterParams(['propertyId']);

  const { data, loading } = usePropertyDetails(propertyId);

  if (loading) return <Loader />;

  return <PropertyDetailsInfoTab {...data} />;
}
