import * as R from 'ramda';

import { AacContractMGRField } from '../components/AacContractMGRField.js';
import { getContactSelect } from '../../../fields/ContactSelect/getContactSelect.js';
import { halfWidth } from '../../../common.js';

export const peopleSection = [
  {
    label: 'People',
    id: 'peopleInfo',
    order: 3,
    layout: {
      column: 2,
    },
    fields: [
      {
        label: 'Assigned CSR',
        layout: { row: 1, width: halfWidth },
        order: 1,
        field: {
          name: 'aacContractMGR',
          Component: AacContractMGRField,
        },
      },
      {
        order: 3,
        layout: { row: 2, width: halfWidth },
        withFormData: true,
        field: {
          name: 'branchManager',
          withFormData: true,
          subFields: [
            ...getContactSelect({
              layout: { column: 1 },
              label: 'Branch Manager',
              placeholder: 'Start typing to find managers',
              labelSwitchAddNewMode: "Didn't find the manager?",
              labelSwitchFindMode:
                'New manager will be added when Branch Manager is created.',
              labelNotBelongProperty:
                'This manager is associated with another property. Would you like to move them to this property?',
              order: 2,
              name: 'branchManager',
              getClientId: R.pathOr('', ['client', '_id']),
              getPropertyId: R.pathOr('', ['_id']),
            }),
          ],
        },
      },
      {
        order: 4,
        layout: { row: 3, width: halfWidth },
        withFormData: true,
        field: {
          name: 'districtFacilityManager',
          withFormData: true,
          subFields: [
            ...getContactSelect({
              layout: { column: 1 },
              label: 'District Facility Manager',
              placeholder: 'Start typing to find managers',
              labelSwitchAddNewMode: "Didn't find the manager?",
              labelSwitchFindMode:
                'New manager will be added when Branch Manager is created.',
              labelNotBelongProperty:
                'This manager is associated with another property. Would you like to move them to this property?',
              order: 2,
              name: 'districtFacilityManager',
              getClientId: R.pathOr('', ['client', '_id']),
              getPropertyId: R.pathOr('', ['_id']),
            }),
          ],
        },
        renderIf: R.path([
          'client',
          'configs',
          'propertyFields',
          'districtFacilityManager',
        ]),
      },
    ],
  },
];
