import React from 'react';
import styled from 'styled-components';

import { supplierAccountPropsTypes } from 'poly-admin-ui';

const ServicesList = styled.div``;

const ServiceItem = styled.span`
  &:not(:last-child) {
    margin-bottom: 5px;
    display: inline-block;
    &:after {
      content: ',\\00a0';
    }
  }
`;

export function SupplierServices({ services }) {
  return (
    <ServicesList>
      {services.map(({ _id, name }) => (
        <ServiceItem key={_id}>{name}</ServiceItem>
      ))}
    </ServicesList>
  );
}

SupplierServices.propTypes = supplierAccountPropsTypes.servicesPropTypes;
