import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import { bool, string } from 'prop-types';
import { EntityStatus, MainHeader, PageHeaderContainer } from 'poly-book-admin';
import { UPDATE_CONTACT_USER_PERMISSION } from 'poly-security';
import { UserStatuses } from 'poly-constants';
import {
  pathOrNothingUI,
  useHasUserAccessWithPermission,
} from 'poly-client-utils';
import {
  formatBackLink,
  useRouterParams,
  useLocation,
} from 'poly-client-routing';
import {
  systemAccessStatusesColors,
  SectionWithBackBtn,
  BackBtn,
  useContactDetails,
} from 'poly-admin-ui';
import { EditBtn } from 'poly-admin-ui/src/components/Button/Button.js';
import { useDispatch } from 'react-redux';
import { routes } from '../../../routes/constants.js';
import { ModalNames } from '../../../utils/constants/modals.js';
import { setOpenModal } from '../../../state/modules/modal.js';

function EditContactBtn() {
  const dispatch = useDispatch();

  const ifHasPermission = useHasUserAccessWithPermission(
    UPDATE_CONTACT_USER_PERMISSION,
  );

  const onClick = () =>
    dispatch(
      setOpenModal({
        name: ModalNames.EDIT_PEOPLE,
        payload: { isOverflowAuto: true },
      }),
    );

  return ifHasPermission ? <EditBtn onClick={onClick} /> : null;
}

EditContactBtn.displayName = 'EditContactBtn';

function ContactBackBtn() {
  const [fallbackLink, setFallbackLink] = useState(routes.BATCHES);

  const location = useLocation();

  useEffect(() => {
    setFallbackLink(formatBackLink({ fallbackLink, router: location.state }));
  }, [location]);

  return <BackBtn fallbackLink={fallbackLink} />;
}

ContactBackBtn.displayName = 'ContactBackBtn';

function ContactPageHeaderComp({ userId, fullName, color, text, isStaff }) {
  return (
    <PageHeaderContainer key={userId}>
      <SectionWithBackBtn>
        <ContactBackBtn />
        <MainHeader>{fullName}</MainHeader>
        <EntityStatus title="Contact" status={{ color, text }} />
      </SectionWithBackBtn>
      {!isStaff && <EditContactBtn />}
    </PageHeaderContainer>
  );
}

ContactPageHeaderComp.propTypes = {
  userId: string.isRequired,
  fullName: string.isRequired,
  color: string.isRequired,
  text: string.isRequired,
  isStaff: bool.isRequired,
};

// getCommonProps :: SearchContactDetailsQueryResult -> Object
const getCommonProps = R.applySpec({
  userId: R.path(['contactUser', '_id']),
  fullName: R.path(['contactUser', 'fullName']),
  color: R.compose(
    R.prop(R.__, systemAccessStatusesColors),
    R.pathOr(UserStatuses.INACTIVE, ['contactUser', 'status']),
  ),
  text: R.compose(R.toLower, pathOrNothingUI(['contactUser', 'status'])),
  isStaff: R.path(['contactUser', 'isStaff']),
});

export function ContactPageHeader() {
  const { contactId } = useRouterParams(['contactId']);

  const { data } = useContactDetails(contactId);

  const properties = getCommonProps(data);

  return <ContactPageHeaderComp {...properties} />;
}

ContactPageHeader.displayName = 'ContactPageHeader';
