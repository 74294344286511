import React from 'react';
import styled from 'styled-components';
import { element, arrayOf, oneOfType } from 'prop-types';
import { getThemeColor } from '../utils.js';

const FiltersContainer = styled.div`
  display: flex;
  height: 80px;
  padding: 30px 0 0 30px;

  > div:not(:last-child) {
    margin-right: 15px;
  }
`;

const TableWithFilters = styled.div`
  position: relative;
  height: calc(100% - 90px);
  width: 100%;
`;

export function TableWithFiltersWrapper({ filters, table }) {
  return (
    <>
      <FiltersContainer>{filters}</FiltersContainer>
      <TableWithFilters>{table}</TableWithFilters>
    </>
  );
}

TableWithFiltersWrapper.propTypes = {
  table: element.isRequired,
  filters: oneOfType([element, arrayOf(element)]).isRequired,
};

export const FooterContainer = styled.div`
  right: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  box-shadow: 0 -2px 3px 0 #e6e6e6;
  padding: 15px 40px;
  background-color: ${getThemeColor(['white'])};
`;
