import { gql } from '@apollo/client';

export const UPDATE_PROPERTY_MUTATION = gql`
  mutation UPDATE_PROPERTY_MUTATION($id: ID!, $input: UpdatePropertyInput) {
    updateProperty(input: $input, id: $id) {
      property {
        _id
      }
    }
  }
`;
