import React from 'react';
import { formatSelectOptionsWithTitleCased } from 'poly-client-utils';
import { SupplierStatuses } from 'poly-constants';
import { Select } from 'poly-book-admin';

export function SupplierStatusSelect(props) {
  const selectProps = {
    ...props,
    name: 'SupplierStatusSelect',
    options: formatSelectOptionsWithTitleCased(SupplierStatuses),
  };

  return <Select {...selectProps} />;
}

SupplierStatusSelect.displayName = 'SupplierStatusSelect';
