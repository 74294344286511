import React from 'react';
import { number } from 'prop-types';

import { NotificationDotS } from './styles.js';
import { formatNotificatorDotValue } from './utils.js';

export function NotificationDot({ value }) {
  return (
    <NotificationDotS>{formatNotificatorDotValue(value)}</NotificationDotS>
  );
}

NotificationDot.propTypes = {
  value: number,
};

NotificationDot.defaultValues = {
  value: 0,
  dotProps: {},
};
