import React from 'react';
import { DefaultBodyWrapper, CardsWrapper, Loader } from 'poly-book-admin';
import { NotFoundPage, useContactDetailsSubscription } from 'poly-admin-ui';
import { ContactInfoTab } from './ContactInfoTab.js';
import { ContactPageHeader } from './ContactPageHeader.js';

function ContactPageC() {
  return (
    <>
      <ContactPageHeader />
      <DefaultBodyWrapper>
        <CardsWrapper>
          <ContactInfoTab />
        </CardsWrapper>
      </DefaultBodyWrapper>
    </>
  );
}

export function ContactPage() {
  const { result } = useContactDetailsSubscription();

  if (result.loading) return <Loader />;
  if (!result?.contactUser) return <NotFoundPage />;

  return <ContactPageC />;
}
