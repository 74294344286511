import styled from 'styled-components';
import { getThemeFont } from 'poly-book-admin';

export const LoadingMsg = styled.div`
  position: absolute;
  font-weight: ${getThemeFont(['regular'])};
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 80px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;
