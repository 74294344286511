import * as R from 'ramda';
import { NOTHING_UI_STRING } from 'poly-constants';

import { SupplierLink, PropertyLink } from '../../containers/links/Links.js';

// formatContactLinkByEntity :: LinkedEntities -> Link -> ReactElement
const formatContactLinkByEntity = ({
  linkedProperties = [],
  linkedSuppliers = [],
}) =>
  R.cond([
    [
      R.both(R.prop('propertyId'), R.prop('clientId')),
      ({ propertyId }) =>
        R.compose(
          R.when(R.identity, R.compose(PropertyLink, R.pick(['_id', 'name']))),
          R.find(R.propEq('_id', propertyId)),
        )(linkedProperties),
    ],
    [
      R.prop('supplierId'),
      ({ supplierId }) =>
        R.compose(
          R.when(
            R.identity,
            R.compose(
              SupplierLink,
              R.applySpec({
                _id: R.prop('_id'),
                name: R.path(['company', 'name']),
              }),
            ),
          ),
          R.find(R.propEq('_id', supplierId)),
        )(linkedSuppliers),
    ],
  ]);

/**
 * getContactReferences :: ContactData -> [References]
 */
export const getContactReferences = ({
  contactUser: { links, ...linkedEntities },
}) =>
  R.compose(
    R.objOf('references'),
    R.when(R.isEmpty, R.always(NOTHING_UI_STRING)),
    R.reject(R.isNil),
    R.map(formatContactLinkByEntity(linkedEntities)),
    R.defaultTo([]),
  )(links);
