import {
  useEntitiesByReactiveReduxSearch,
  useEntitiesByReactiveSearch,
} from 'poly-client-utils';
import { gql } from '@apollo/client';
import { usePagination } from 'poly-admin-ui';
import { useSelector } from 'react-redux';

const commonOcrInvoicesFields = gql`
  fragment commonOcrInvoicesFields on OCRInvoice {
    _id
    invoiceId
    invoiceNumber
    invoiceDate
    dueDate
    total
    invoiceFileLink
    workflow {
      ... on TiaaInvoiceWorkflow {
        check {
          _id
          checkNumber
          issuanceDate
        }
        status
      }
    }
    supplier {
      _id
      company {
        name
      }
    }
    property {
      _id
      name
    }
    batch {
      _id
      batchId
    }
  }
`;

export const OCR_INVOICES_BY_SEARCH_SUB = gql`
  subscription OCR_INVOICES_BY_SEARCH_SUB($input: CollectionSearchParams) {
    searchOCRInvoiceChanged(input: $input) {
      id
      type
    }
  }
`;

export const OCR_INVOICES_BY_SEARCH = gql`
  query OCR_INVOICES_BY_SEARCH($searchInput: CollectionSearchParams!) {
    searchOCRInvoices(input: $searchInput) {
      hits {
        ...commonOcrInvoicesFields
      }
      total
    }
  }
  ${commonOcrInvoicesFields}
`;

export const useOcrInvoicesBySearch = ({
  sort,
  pagination,
  query,
  skipQuery = false,
}) => {
  const searchText = useSelector((state) => state.searchText);

  const { loading, refetch, result } = useEntitiesByReactiveSearch({
    gqlSearchQuery: OCR_INVOICES_BY_SEARCH,
    gqlSearchChangedQuery: OCR_INVOICES_BY_SEARCH_SUB,
    sort,
    pagination,
    query,
    skipQuery,
    alias: 'useOcrInvoicesBySearch',
    searchText,
  });

  return {
    loading,
    refetch,
    result,
  };
};

export const useOcrInvoicesByTextSearch = ({
  sort,
  query,
  skipQuery = false,
}) => {
  const { pagination } = usePagination();

  const { loading, refetch, result } = useEntitiesByReactiveReduxSearch({
    gqlSearchQuery: OCR_INVOICES_BY_SEARCH,
    gqlSearchChangedQuery: OCR_INVOICES_BY_SEARCH_SUB,
    sort,
    query,
    pagination,
    skipQuery,
    alias: 'useOcrInvoicesByTextSearch',
  });

  return {
    loading,
    refetch,
    result,
  };
};
