import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { bool, string } from 'prop-types';
import {
  TableCardWithPaginationContainer,
  useMapConfigToTableProps,
  useSetInitialPaginationOnMount,
  useSetItemsCount,
  useTableSorting,
} from 'poly-admin-ui';
import { ASC_SORT_ORDER } from 'poly-constants';
import { commonSortQuery, formatDateProp } from 'poly-client-utils';
import { moneyColumnStyles, Table, Loader } from 'poly-book-admin';
import {
  InvoiceLink,
  InvoicePDFLink,
  InvoiceSupplierLink,
} from '../../../containers/links/Links.js';
import { BatchPageFooter } from './BatchPageFooter.js';
import { getGlCodeByCategoryId } from '../../../utils/constants/serviceTypes.js';
import { useBatchInvoicesBySearch } from '../apollo/useBatchInvoices.js';
import { calcInvoicesTotal } from '../../../utils/invoices.js';
import {
  formatCurrencyProp,
  formatTotal,
} from '../../../utils/foramatters/index.js';
import {
  filterSelectedInvoices,
  isAllowedSendToXero,
  isFundedStatus,
  isNewStatus,
} from './common/utils.js';
import { useSelectableTableLogic } from '../../../utils/hooks/table.js';
import { PropertyLinkComp } from '../../invoice/EntityInvoicesTab/index.js';

const withSelectStyles = `
  th:first-child,
  td:first-child {
    width: 60px;
  }
`;

const InvoicesTableC = styled(Table)`
  padding-top: 30px;
  min-width: 1024px;

  th:nth-child(5),
  td:nth-child(5),
  th:nth-child(6),
  td:nth-child(6),
  th:nth-child(7),
  td:nth-child(7) {
    width: 9%;
  }

  ${moneyColumnStyles(8)};

  @media (max-width: 1450px) {
    th:nth-child(5),
    td:nth-child(5),
    th:nth-child(6),
    td:nth-child(6),
    th:nth-child(7),
    td:nth-child(7) {
      width: 12%;
    }
  }

  ${({ selectedRows }) => selectedRows && withSelectStyles};
  ${({ selectedRows }) =>
    selectedRows ? moneyColumnStyles(9) : moneyColumnStyles(8)};
`;

const glCode = R.pipe(
  R.path(['lines', '0', 'serviceType', 'psCategoryId']),
  getGlCodeByCategoryId,
);

const tableConfig = [
  ['Invoice ID', InvoiceLink, commonSortQuery(['invoiceId'])],
  [
    'Supplier',
    InvoiceSupplierLink,
    commonSortQuery(['supplier', 'company', 'name', 'keyword']),
  ],
  ['Invoice #', InvoicePDFLink, commonSortQuery(['invoiceNumber', 'keyword'])],
  [
    'Property',
    PropertyLinkComp,
    commonSortQuery(['property', 'name', 'keyword']),
  ],
  [
    'Invoice Date',
    formatDateProp('invoiceDate'),
    commonSortQuery(['invoiceDate']),
  ],
  ['Due Date', formatDateProp('dueDate'), commonSortQuery(['dueDate'])],
  ['GL Code', glCode, glCode],
  ['Amount', formatCurrencyProp('total'), commonSortQuery(['total'])],
];

const TableContainer = styled.div`
  height: ${({ withApproveFooter }) =>
    withApproveFooter ? 'calc(100% - 60px)' : '100%'};
  overflow: auto;
`;

function BatchInvoicesTableC({ allowWorkflowAction, invoicesTotal, ...props }) {
  return (
    <>
      <TableContainer withApproveFooter={allowWorkflowAction}>
        <TableCardWithPaginationContainer>
          <InvoicesTableC {...props} />
        </TableCardWithPaginationContainer>
      </TableContainer>
      {allowWorkflowAction && <BatchPageFooter invoicesTotal={invoicesTotal} />}
    </>
  );
}

BatchInvoicesTableC.propTypes = {
  allowWorkflowAction: bool.isRequired,
  invoicesTotal: string.isRequired,
};

// getBatchInvoicesTotal :: SearchInvoicesQueryResult => Number
const getBatchInvoicesTotal = R.pathOr(0, [
  'batch',
  'searchOCRInvoices',
  'total',
]);

// getBatchInvoices :: SearchInvoicesQueryResult => [BatchInvoice]
const getBatchInvoices = R.pathOr([], ['batch', 'searchOCRInvoices', 'hits']);

// getInvoicesTotalValue :: (Batch, SelectedRows) -> Number
const getInvoicesTotalValue = (batch, selectedRows) =>
  R.compose(
    formatTotal,
    calcInvoicesTotal,
    R.when(R.always(isNewStatus(batch)), filterSelectedInvoices(selectedRows)),
    R.pathOr([], ['searchOCRInvoices', 'hits']),
  )(batch);

// getAllowWorkflowAction :: Batch -> Bool
const getAllowWorkflowAction = R.anyPass([
  isNewStatus,
  isFundedStatus,
  isAllowedSendToXero,
]);

export function BatchInvoicesTable() {
  useSetInitialPaginationOnMount();

  const selectedRows = useSelector((state) => state.selectedRows);

  const { sort, ...tableSortingProps } = useTableSorting({
    tableConfig,
    column: 1,
    order: ASC_SORT_ORDER,
  });

  const { result, loading } = useBatchInvoicesBySearch(sort);

  useSetItemsCount(getBatchInvoicesTotal, result);

  const tableProps = useMapConfigToTableProps(
    getBatchInvoices,
    tableConfig,
    result,
  );

  const allowWorkflowAction = getAllowWorkflowAction(result?.batch);

  const invoicesTotal = getInvoicesTotalValue(result?.batch, selectedRows);

  const allowSelectInvoices = isNewStatus(result?.batch);

  const selectableProps = useSelectableTableLogic(
    tableProps.rows,
    allowSelectInvoices,
  );

  if (loading) return <Loader />;

  return (
    <BatchInvoicesTableC
      {...tableSortingProps}
      {...tableProps}
      {...selectableProps}
      allowWorkflowAction={allowWorkflowAction}
      invoicesTotal={invoicesTotal}
      batch={result?.batch}
    />
  );
}
