import * as R from 'ramda';
import {
  SupplierSources,
  SupplierStatuses,
  SupplierTypes,
} from 'poly-constants';

export const addSupplierFormModal = 'addSupplierFormModal';

export const initialValues = {
  type: R.toUpper(SupplierTypes.ADMINISTRATIVE),
  status: R.toUpper(SupplierStatuses.ACTIVE),
  finance: {
    acceptsCreditCard: false,
  },
  mwbe: {
    minority: undefined,
    isMBE: false,
  },
  bank: {
    payByACH: false,
  },
  tax: {
    is1099: false,
  },
  misc: {
    source: SupplierSources.TIAA,
  },
  service_24_7: false,
  company: { servicesIds: [] },
  remarks: { ops: [] },
};
