import React from 'react';
import * as R from 'ramda';
import { arrayOf, shape, string } from 'prop-types';
import { propertyOccupancyTypes, propertyOwnershipTypes } from 'poly-constants';
import { formatSelectOptionsWithTitleCased } from 'poly-client-utils';
import { Select, Textarea, Input } from 'poly-book-admin';

import { halfWidth } from '../../../common.js';

const clientPropTypes = shape({
  configs: shape({
    propertyCustomFields: shape({
      buildingInfo: shape({
        ownerShip: arrayOf(string),
        status: arrayOf(string),
      }),
    }),
  }),
});

function OwnerShipSelector({ formData, ...props }) {
  const options = formatSelectOptionsWithTitleCased(propertyOwnershipTypes);

  return <Select {...props} options={options} />;
}

OwnerShipSelector.propTypes = {
  formData: shape({
    client: clientPropTypes,
  }),
};

function OccupancySelector({ formData, ...props }) {
  const options = formatSelectOptionsWithTitleCased(propertyOccupancyTypes);

  return <Select {...props} options={options} />;
}

OccupancySelector.propTypes = {
  formData: shape({
    client: clientPropTypes,
  }),
};

// getBuildingInfoConfigs :: { client: Client } -> ClientBuildingInfoConfig
const getBuildingInfoConfigs = R.pathOr({}, [
  'client',
  'configs',
  'propertyFields',
  'buildingInfo',
]);

// renderIfByProp :: { client: Client } -> Boolean
const renderIfByProp = (prop) =>
  R.compose(R.prop(prop), getBuildingInfoConfigs);

const renderSectionIf = R.compose(
  R.complement(R.isEmpty),
  R.reject(R.isNil),
  R.values,
  getBuildingInfoConfigs,
);

export const customPropertyBuildingType = 'Custom';

export const buildingTypeOptionsConfig = [
  'Church',
  'Parsonage',
  'Fellowship Hall',
  'Camp',
  'Vacant Land',
  'School',
  'Cemetery',
];
// getBuildingTypeOptions :: BuildingTypeOptionsConfig -> [Option]
const getBuildingTypeOptions = R.compose(
  R.map(R.applySpec({ value: R.identity, label: R.identity })),
  R.append(customPropertyBuildingType),
);

function BuildingTypeDropDown(props) {
  const options = getBuildingTypeOptions(buildingTypeOptionsConfig);

  return <Select {...props} options={options} />;
}

export const buildingInformationSection = [
  {
    label: 'Building Information',
    id: 'buildingInformation',
    order: 2,
    layout: {
      column: 1,
    },
    // render only when `buildingInfo` has at least one appropriate config
    renderIf: renderSectionIf,
    fields: [
      {
        label: 'Building Size',
        order: 1,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'buildingInfo.buildingSize',
          Component: Input,
        },
        renderIf: renderIfByProp('buildingSize'),
      },
      {
        label: 'Ownership',
        order: 2,
        layout: { row: 1, width: halfWidth },
        field: {
          name: 'buildingInfo.buildingOwnership',
          withFormData: true,
          Component: OwnerShipSelector,
        },
        renderIf: renderIfByProp('ownership'),
      },
      {
        label: 'Occupancy',
        order: 3,
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'buildingInfo.occupancy',
          withFormData: true,
          Component: OccupancySelector,
        },
        renderIf: renderIfByProp('occupancy'),
      },
      {
        label: 'LockBox Code',
        order: 4,
        layout: { row: 2, width: halfWidth },
        field: {
          name: 'buildingInfo.lockBoxCode',
          Component: Input,
        },
        renderIf: renderIfByProp('lockBoxCode'),
      },
      {
        label: 'Power Requirements',
        order: 5,
        layout: { row: 3, width: halfWidth },
        field: {
          name: 'buildingInfo.powerRequirements',
          Component: Input,
        },
        renderIf: renderIfByProp('powerRequirements'),
      },
      {
        label: 'Building Type',
        order: 6,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'buildingInfo.buildingType',
          Component: BuildingTypeDropDown,
        },
        renderIf: renderIfByProp('buildingType'),
      },
      {
        label: 'Custom Building Type',
        order: 7,
        layout: { row: 4, width: halfWidth },
        field: {
          name: 'buildingInfo.customBuildingType',
          Component: Input,
        },
        renderIf: R.both(
          renderIfByProp('buildingType'),
          R.pathEq(
            ['buildingInfo', 'buildingType'],
            customPropertyBuildingType,
          ),
        ),
      },
      {
        label: 'Parcel Tax ID',
        order: 8,
        layout: { row: 5, width: halfWidth },
        field: {
          name: 'buildingInfo.parcelTaxId',
          Component: Input,
        },
        renderIf: renderIfByProp('parcelTaxId'),
      },
      {
        label: 'Landlord Responsibility',
        order: 9,
        layout: { row: 6 },
        field: {
          name: 'buildingInfo.landlordResponsibility',
          Component: Textarea,
        },
        renderIf: renderIfByProp('landlordResponsibility'),
      },
    ],
  },
];
