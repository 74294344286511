import { gql } from '@apollo/client';

export const globalSearchQuery = gql`
  query globalSearchQuery($input: CollectionSearchParams) {
    searchProperties(input: $input) {
      hits {
        _id
        cardNumber
        name
        status
        client {
          _id
          name
        }
        address {
          formatted_address
        }
      }
      total
      maxScore
    }

    searchSuppliers(input: $input) {
      hits {
        _id
        cardNumber
        status
        company {
          name
          dba
          address {
            formatted_address
          }
        }
        misc {
          source
        }
        type
        remit {
          name
        }
        tax {
          name
        }
      }
      total
      maxScore
    }
    searchMasterSuppliers(input: $input) {
      hits {
        _id
        cardNumber
        status
        company {
          name
          dba
          address {
            formatted_address
          }
        }
        type
        remit {
          name
        }
        tax {
          name
        }
      }
      total
      maxScore
    }
    searchOCRInvoices(input: $input) {
      hits {
        _id
        invoiceNumber
        invoiceId
        workflow {
          ... on TiaaInvoiceWorkflow {
            check {
              _id
              checkNumber
              issuanceDate
            }
            status
          }
        }
        supplier {
          _id
          company {
            name
          }
        }
        total
      }
      total
      maxScore
    }
  }
`;
