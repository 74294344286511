import {
  ConfirmCloseEditFormBtn,
  ConfirmCloseFormBtn,
  ConfirmCloseModalBtn,
} from './components/ConfirmCloseFormBtn.js';
import { confirmButtonNames } from './constants.js';

export const confirmButtons = {
  [confirmButtonNames.CONFIRM_CLOSE_FORM]: ConfirmCloseFormBtn,
  [confirmButtonNames.CONFIRM_CLOSE_EDIT_FORM]: ConfirmCloseEditFormBtn,
  [confirmButtonNames.CONFIRM_CLOSE_MODAL]: ConfirmCloseModalBtn,
};
