import React from 'react';
import { element, func } from 'prop-types';
import { Text, Button, ModalActions } from 'poly-book-admin';

export function ConfirmPrintModalC({ onCancel, onConfirm, text }) {
  return (
    <>
      <Text size="16px">{text}</Text>
      <ModalActions>
        <Button
          size="small"
          type="button"
          styleType="basicSecondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button size="small" type="submit" onClick={onConfirm}>
          Yes, print 100
        </Button>
      </ModalActions>
    </>
  );
}

ConfirmPrintModalC.propTypes = {
  text: element.isRequired,
  onCancel: func.isRequired,
  onConfirm: func.isRequired,
};
