import React, { useState } from 'react';
import styled from 'styled-components';
import { TextButton } from 'poly-book-admin';
import { EXPORT_XLS_CAPTION } from 'poly-constants';
import { useLazyQuery } from '@apollo/client';
import { spendDetailReportXLS } from './spendDetailReportXLS.js';
import {
  SPEND_REPORTS_INVOICES,
  useSpendDetailReport,
} from '../withSpendDetailReport.js';
import { getSpendDetailQuery } from '../spendDetailsUtils.js';
import { checkIfIsDisabled } from './GeneratePDFBtn.js';

const TextButtonS = styled(TextButton)`
  margin-right: 15px;
`;

export function GenerateXLSBtn() {
  const [isXLSGeneration, setXLSGeneration] = useState(null);

  const [queryHandler] = useLazyQuery(SPEND_REPORTS_INVOICES);

  const { result, filters } = useSpendDetailReport();

  const disabled = checkIfIsDisabled(result);

  const onClick = async () => {
    if (!disabled) {
      setXLSGeneration(true);

      const { data } = await queryHandler({
        variables: {
          searchInput: {
            query: getSpendDetailQuery(filters),
            from: 0,
            size: 10000,
          },
        },
      });

      await spendDetailReportXLS(data?.searchOCRInvoices?.hits);
      setXLSGeneration(false);
    }
  };

  return (
    <TextButtonS
      disabled={disabled}
      loading={isXLSGeneration}
      onClick={onClick}
    >
      {EXPORT_XLS_CAPTION}
    </TextButtonS>
  );
}

GenerateXLSBtn.displayName = 'GenerateXLSBtn';
