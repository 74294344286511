import { routes } from './constants.js';
import { AuthLayout } from '../components/layouts/authLayout.js';
import { MainLayout } from '../components/layouts/mainLayout.js';
import { LoginPage } from '../pages/loginPage.js';
import { ForgotPasswordPage } from '../pages/forgotPasswd.js';
import { ResetPasswordPage } from '../pages/ResetPasswordPage.js';
import { QueuePage } from '../modules/invoice/QueuePage/QueuePage.js';
import { ApprovalPage } from '../modules/invoice/ApprovalPage/ApprovalPage.js';
import { BatchesPage } from '../modules/batch/BatchesPage.js';
import { SpendDetailsPage } from '../modules/invoice/SpendDetailsPage/SpendDetailsPage.js';
import { Report1099Page } from '../pages/Report1099Page/Report1099Page.js';
import { SupplierTaxReportPage } from '../modules/supplier/SupplierTaxReportPage/SupplierTaxReportPage.js';
import { PropertiesPage } from '../modules/property/PropertiesPage/PropertiesPage.js';
import { SuppliersPage } from '../modules/supplier/SuppliersPage.js';
import { BatchPage } from '../modules/batch/BatchPage/BatchPage.js';
import { InvoiceVerifyPage } from '../modules/invoice/InvoiceVerifyPage/InvoiceVerifyPage.js';
import { InvoicePage } from '../modules/invoice/InvoicePage/InvoicePage.js';
import { PropertyPage } from '../modules/property/PropertyPage/PropertyPage.js';
import { ServiceTypesPage } from '../modules/serviceType/ServiceTypesPage.js';
import { SupplierPage } from '../modules/supplier/SupplierDetailsPage/SupplierDetailsPage.js';
import { AddPropertyPage } from '../modules/property/AddPropertyPage/AddPropertyPage.js';
import { AddSupplierPage } from '../modules/supplier/AddSupplierPage.js';
import { ContactPage } from '../modules/contact/contactPage/ContactDetailsPage.js';
import { NotFoundPage } from '../pages/notFoundPage.js';
import { RecurringInvoicesPage } from '../modules/recurringInvoice/RecurringInvoicesPage.js';
import { MissingInvoicesPage } from '../modules/recurringInvoice/MissingInvoicesPage.js';
import { MasterSuppliersPage } from '../modules/masterSupplier/MasterSuppliersPage.js';
import { AddMasterSupplierPage } from '../modules/masterSupplier/AddMasterSupplierPage.js';
import { MasterSupplierPage } from '../modules/masterSupplier/MasterSupplierPage.js';
import { AddBranchSupplierPage } from '../modules/masterSupplier/AddBranchSupplierPage.js';
import { UserManagementPage } from '../pages/UserManagement/UserManagementPage.js';

const {
  LOGIN,
  FORGOT_PASSWD,
  QUEUE,
  APPROVAL,
  BATCHES,
  MISSING_INVOICES,
  RECURRING_INVOICES,
  SPEND_DETAIL,
  REPORT_1099,
  SUPPLIER_TAX,
  PROPERTIES,
  SUPPLIERS,
  BATCH,
  INVOICE,
  RESET_PASSWD,
  PROPERTY,
  INVOICE_VERIFY,
  SERVICE_TYPES,
  SUPPLIER,
  ADD_PROPERTY,
  ADD_SUPPLIER,
  CONTACT,
  MASTER_SUPPLIER,
  MASTER_SUPPLIERS,
  ADD_MASTER_SUPPLIER,
  ADD_BRANCH_SUPPLIER,
  NOT_FOUND,
  USER_MANAGEMENT,
} = routes;

export const routesConfig = [
  {
    layout: AuthLayout,
    routes: [
      [LOGIN, LoginPage],
      [FORGOT_PASSWD, ForgotPasswordPage],
      [RESET_PASSWD, ResetPasswordPage],
    ],
  },
  {
    layout: MainLayout,
    routes: [
      [QUEUE, QueuePage],
      [APPROVAL, ApprovalPage],
      [MISSING_INVOICES, MissingInvoicesPage],
      [RECURRING_INVOICES, RecurringInvoicesPage],
      [SPEND_DETAIL, SpendDetailsPage],
      [REPORT_1099, Report1099Page],
      [SUPPLIER_TAX, SupplierTaxReportPage],
      [PROPERTIES, PropertiesPage],
      [SUPPLIERS, SuppliersPage],
      [BATCH, BatchPage],
      [INVOICE_VERIFY, InvoiceVerifyPage],
      [INVOICE, InvoicePage],
      [PROPERTY, PropertyPage],
      [SERVICE_TYPES, ServiceTypesPage],
      [SUPPLIER, SupplierPage],
      [ADD_PROPERTY, AddPropertyPage],
      [ADD_SUPPLIER, AddSupplierPage],
      [CONTACT, ContactPage],
      [MASTER_SUPPLIERS, MasterSuppliersPage],
      [ADD_MASTER_SUPPLIER, AddMasterSupplierPage],
      [MASTER_SUPPLIER, MasterSupplierPage],
      [ADD_BRANCH_SUPPLIER, AddBranchSupplierPage],
      [USER_MANAGEMENT, UserManagementPage],
      [BATCHES, BatchesPage, true],
      [NOT_FOUND, NotFoundPage],
    ],
  },
];
