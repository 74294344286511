import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { bool, objectOf, shape, string } from 'prop-types';
import { DESC_SORT_ORDER, NOTHING_UI_STRING } from 'poly-constants';
import { assocBy, formatTotal, applyProp, forceTitleCase } from 'poly-utils';
import {
  commonSortQuery,
  pathOrNothingUI,
  formatDatePath,
  formatDateProp,
  useSubscriptionByChanges,
  useSortableTable,
  useHighlightMatchesBySearch,
} from 'poly-client-utils';
import {
  TableWithPaginationContainer,
  mapConfigToTableProps,
  moneyColumnStyles,
  TableCard,
  Table,
  Loader,
} from 'poly-book-admin';
import {
  InvoicePDFLink,
  PaginationS,
  useSetInitialPaginationOnMount,
  useSetItemsCount,
  useMapConfigToTableProps,
  usePagination,
} from 'poly-admin-ui';
import { useDispatch } from 'react-redux';
import {
  BatchLink,
  PropertyLink,
  InvoiceSupplierLink,
} from '../../../containers/links/Links.js';
import { createInvoiceExternalLink } from '../../../utils/invoices.js';
import { PROPERTIES_BY_SEARCH_SUB } from '../../property/apollo/usePropertiesBySearch.js';
import { SUPPLIER_SEARCH_CHANGED } from '../../supplier/apollo/useSuppliersBySearch.js';
import { useOcrInvoicesByTextSearch } from '../apollo/useOcrInvoicesBySearch.js';
import { setPagination } from '../../../state/modules/pagination.js';

const TableS = styled(Table)`
  td:nth-child(1),
  td:nth-child(2),
  th:nth-child(1),
  th:nth-child(2) {
    width: 200px;
  }

  ${moneyColumnStyles(5)};
`;

function InvoiceIdPDFLink({ _id, invoiceId }) {
  const link = createInvoiceExternalLink(_id);
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      {invoiceId}
    </a>
  );
}

InvoiceIdPDFLink.propTypes = {
  _id: string.isRequired,
  invoiceId: string,
};

InvoiceIdPDFLink.defaultProps = {
  invoiceId: '–',
};

export function PropertyLinkComp({ property }) {
  return <PropertyLink {...property} />;
}

PropertyLinkComp.propTypes = {
  property: shape({
    _id: string,
    name: string,
  }),
};

const BatchLinkFromProps = R.ifElse(
  R.prop('batch'),
  R.pipe(R.prop('batch'), BatchLink),
  R.always(NOTHING_UI_STRING),
);

// mapDataToAddStatus :: [Invoice] -> [Invoice]
const mapDataToAddStatus = R.map(
  assocBy('status', R.compose(forceTitleCase, R.path(['workflow', 'status']))),
);

const commonTableConfig = [
  ['Invoice ID', InvoiceIdPDFLink, commonSortQuery(['invoiceId'])],
  ['Invoice #', InvoicePDFLink, commonSortQuery(['invoiceNumber'])],
  ['Inv Date', formatDateProp('invoiceDate'), commonSortQuery(['invoiceDate'])],
  ['Amount', applyProp(formatTotal)('total'), commonSortQuery(['total'])],
  ['Batch ID', BatchLinkFromProps, commonSortQuery(['batch', 'batchId'])],
  [
    'Check #',
    pathOrNothingUI(['workflow', 'check', 'checkNumber']),
    commonSortQuery(['check', 'checkNumber']),
  ],
  [
    'Date Paid',
    formatDatePath(['workflow', 'check', 'issuanceDate']),
    commonSortQuery(['check', 'issuanceDate']),
  ],
  ['Status', R.prop('status'), commonSortQuery(['workflow', 'status'])],
];

const supplierInvoicesTableConfig = R.insert(
  1,
  [
    'Property',
    PropertyLinkComp,
    commonSortQuery(['property', 'name', 'keyword']),
  ],
  commonTableConfig,
);

const propertyInvoicesTableConfig = R.insert(
  1,
  [
    'Supplier',
    InvoiceSupplierLink,
    commonSortQuery(['supplier', 'company', 'name', 'keyword']),
  ],
  commonTableConfig,
);

const useSupplierInvoicesBySearch = ({ sort, query }) => {
  const { loading, refetch, result } = useOcrInvoicesByTextSearch({
    sort,
    query,
  });

  useSubscriptionByChanges({
    gqlQueryChanged: SUPPLIER_SEARCH_CHANGED,
    refetch,
    result,
  });

  return {
    loading,
    refetch,
    result,
  };
};

export const usePropertyInvoicesBySearch = ({ sort, query }) => {
  const { loading, refetch, result } = useOcrInvoicesByTextSearch({
    sort,
    query,
  });

  useSubscriptionByChanges({
    gqlQueryChanged: PROPERTIES_BY_SEARCH_SUB,
    refetch,
    result,
  });

  return {
    loading,
    refetch,
    result,
  };
};

function TableCardComp({ paginationVisible = true, ...restProps }) {
  const { pagination } = usePagination();
  const dispatch = useDispatch();

  return (
    <TableCard>
      <TableWithPaginationContainer paginationVisible>
        <TableS {...restProps} />
      </TableWithPaginationContainer>
      {paginationVisible && (
        <PaginationS
          {...{
            onChange: (payload) => dispatch(setPagination(payload)),
            ...pagination,
          }}
        />
      )}
    </TableCard>
  );
}

TableCardComp.propTypes = {
  paginationVisible: bool,
};

// getHits :: SearchOcrInvoiceQueryResult -> [Invoice]
const getHits = R.compose(
  mapDataToAddStatus,
  R.pathOr([], ['searchOCRInvoices', 'hits']),
);

const useCommonInvoicesTabLogic = (data, tableConfig) => {
  const hits = getHits(data);

  const { rows, ...restTableProps } = useMapConfigToTableProps(
    R.identity,
    tableConfig,
    hits,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [
      ['invoiceId'],
      ['property', 'name'],
      ['invoiceNumber'],
      ['batch', 'batchId'],
      ['workflow', 'check', 'checkNumber'],
      ['status'],
    ],
    rows,
  );

  return {
    ...restTableProps,
    rows: highlightedRows,
  };
};

// getInvoicesTotalCount :: SearchInvoicesQueryResult -> Number
const getInvoicesTotalCount = R.pathOr(0, ['searchOCRInvoices', 'total']);

export function SupplierOCRInvoicesTab({ query, ...restProps }) {
  useSetInitialPaginationOnMount();

  const { sort, ...tableSortingProps } = useSortableTable({
    tableConfig: supplierInvoicesTableConfig,
    column: 1,
    order: DESC_SORT_ORDER,
  });

  const { result, loading } = useSupplierInvoicesBySearch({ sort, query });

  useSetItemsCount(getInvoicesTotalCount, result);

  const commonInvoicesTabProps = useCommonInvoicesTabLogic(
    result,
    supplierInvoicesTableConfig,
  );

  if (loading) return <Loader />;

  return (
    <TableCardComp
      {...restProps}
      {...tableSortingProps}
      {...commonInvoicesTabProps}
    />
  );
}

SupplierOCRInvoicesTab.propTypes = {
  query: shape({
    match: objectOf(string),
  }),
};

export function PropertyOCRInvoicesTab({ query, ...restProps }) {
  useSetInitialPaginationOnMount();

  const { sort, ...tableSortingProps } = useSortableTable({
    tableConfig: propertyInvoicesTableConfig,
    column: 1,
    order: DESC_SORT_ORDER,
  });

  const { result, loading } = usePropertyInvoicesBySearch({ sort, query });

  useSetItemsCount(getInvoicesTotalCount, result);

  const commonInvoicesTabProps = useCommonInvoicesTabLogic(
    result,
    propertyInvoicesTableConfig,
  );

  if (loading) return <Loader />;

  return (
    <TableCardComp
      {...restProps}
      {...tableSortingProps}
      {...commonInvoicesTabProps}
    />
  );
}

PropertyOCRInvoicesTab.propTypes = {
  query: shape({
    match: objectOf(string),
  }),
};

export const PrintPropertyInvoicesTable = mapConfigToTableProps(
  R.compose(mapDataToAddStatus, R.prop('data')),
  propertyInvoicesTableConfig,
)(TableS);

export const PrintSupplierInvoicesTable = mapConfigToTableProps(
  R.compose(mapDataToAddStatus, R.prop('data')),
  supplierInvoicesTableConfig,
)(TableS);
