import * as R from 'ramda';
import React, { useMemo } from 'react';
import { func, object, string } from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { usePristineSubscribe, useNavigate } from 'poly-client-routing';
import { invoiceToTypes, propertyStatuses } from 'poly-constants';
import { FormPage, Loader } from 'poly-book-admin';
import { insertParamIntoURL } from 'poly-utils';

import { FormContext } from '../utils.js';
import { propertyFormSections } from './form/sections/index.js';
import { CLIENT_CONFIGS_QUERY } from '../../../hooks/clients/queries.js';
import { CREATE_PROPERTY_MUTATION } from '../../../hocs/properties/queries.js';
import { useOnSubmitSetStopSubmitting } from '../../../hooks/useOnSubmitSetStopSubmitting.js';
import { useNotificationState } from '../../../hooks/useNotificationState.js';
import { useSaveContacts } from '../fields/ContactSelect/useSaveContacts.js';
import { useProcessState } from '../../../hooks/useProcessState.js';
import { propertyFormObjectToMutation } from './utils/utils.js';

const useOnPropertySubmit = ({
  formId,
  clientId,
  propertyRootUrl,
  propertyCustomFields,
}) => {
  const navigate = useNavigate();
  const { setProcesses } = useProcessState(formId);
  const { showSuccessNotification } = useNotificationState();

  const saveContacts = useSaveContacts();

  const [createProperty] = useMutation(CREATE_PROPERTY_MUTATION);

  return async (formData) => {
    // first save contact (branchManager)
    const [branchManagerResult] = await saveContacts(formData, '', clientId, [
      'branchManager',
    ]).catch(() => setProcesses({ [formId]: false }));

    const branchManagerId = R.path(['contactId'], branchManagerResult);

    let toMutationObject = {
      ...formData,
      branchManagerId: R.path(['contactId'], branchManagerResult),
    };

    if (propertyCustomFields.districtFacilityManager) {
      const [districtFacilityManagerResult] = await saveContacts(
        formData,
        '',
        clientId,
        ['districtFacilityManager'],
      ).catch(() => setProcesses({ [formId]: false }));

      toMutationObject = {
        ...toMutationObject,
        districtFacilityManagerId: R.path(
          ['contactId'],
          districtFacilityManagerResult,
        ),
      };
    }

    // then save property
    const response = await createProperty({
      variables: {
        input: {
          ...propertyFormObjectToMutation(toMutationObject),
          clientId,
        },
      },
    }).catch(() => setProcesses({ [formId]: false }));

    const propertyId = R.path(
      ['data', 'createProperty', 'property', '_id'],
      response,
    );

    if (propertyId) {
      const propertyBranchManager = {
        branchManager: {
          ...formData.branchManager,
          contact: {
            isCreateMode: false,
            isAddContactToProperty: true,
          },
          _id: branchManagerId,
        },
      };

      // then add contact (branchManager) to this property
      await saveContacts(propertyBranchManager, propertyId, clientId, [
        'branchManager',
      ]).catch(() => setProcesses({ [formId]: false }));

      if (propertyCustomFields.districtFacilityManager) {
        await saveContacts(
          {
            districtFacilityManager: {
              ...formData.branchManager,
              contact: {
                isCreateMode: false,
                isAddContactToProperty: true,
              },
              _id: toMutationObject.districtFacilityManagerId,
            },
          },
          propertyId,
          clientId,
          ['districtFacilityManager'],
        ).catch(() => setProcesses({ [formId]: false }));
      }

      // after success action
      showSuccessNotification('Property was successfully created');
      const propertyUrl = insertParamIntoURL(
        'propertyId',
        propertyRootUrl,
        propertyId,
      );
      navigate(propertyUrl);
    }
  };
};

export function AddPropertyForm({
  formId,
  clientId,
  onCancel,
  propertyRootUrl,
  additionalInitialValues,
  transformConfig = R.identity,
  ...props
}) {
  const pristineSubscribeProps = usePristineSubscribe();

  const { data, loading } = useQuery(CLIENT_CONFIGS_QUERY, {
    variables: {
      id: clientId,
    },
  });

  const client = R.propOr({}, 'client', data);

  const propertyCustomFields = R.pathOr(
    {},
    ['configs', 'propertyFields'],
    client,
  );

  const handleSubmit = useOnPropertySubmit({
    formId,
    clientId,
    propertyRootUrl,
    propertyCustomFields,
  });

  const { onSubmit } = useOnSubmitSetStopSubmitting(formId, handleSubmit);

  const contextValue = useMemo(
    () => ({ formId, onCancel }),
    [formId, onCancel],
  );

  const formProps = {
    id: formId,
    initialValues: {
      client,
      status: propertyStatuses.ACTIVE,
      invoiceTo: invoiceToTypes.MAIN_ACCOUNT,
      branchManager: {
        contact: { isCreateMode: false },
      },
      ...(!!additionalInitialValues && additionalInitialValues),
    },
    subscription: {},
    sections: transformConfig(propertyFormSections),
    onSubmit,
    initialValuesEqual: R.equals,
    ...pristineSubscribeProps,
    ...props,
  };
  return loading ? (
    <Loader />
  ) : (
    <FormContext.Provider value={contextValue}>
      <FormPage {...formProps} />;
    </FormContext.Provider>
  );
}
AddPropertyForm.displayName = 'EditPropertyForm';

AddPropertyForm.propTypes = {
  formId: string.isRequired,
  propertyRootUrl: string.isRequired,
  onCancel: func,
  transformConfig: func,
  clientId: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  additionalInitialValues: object,
};
