import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { formatTotal } from 'poly-utils';
import { NOTHING_UI_STRING, OCRInvoiceStatuses } from 'poly-constants';
import {
  FlexColumn,
  FlexFullWidthJustifyStart,
  SearchResultItemContainer,
  SearchResultItemDivider,
  SearchResultItemLinked,
  SearchResultItemTitle,
  FlexContainer,
  highlightedTextPropType,
} from 'poly-admin-ui';

const CheckSearchItemContainer = styled(SearchResultItemContainer)`
  justify-content: flex-start;
`;

function CheckSearchResultComp({
  invoiceId,
  invoiceNumber,
  checkNumber,
  status,
  supplierName,
  amount,
}) {
  return (
    <CheckSearchItemContainer>
      <FlexColumn>
        <FlexFullWidthJustifyStart>
          <FlexContainer>
            <SearchResultItemLinked>{status}</SearchResultItemLinked>
            <SearchResultItemTitle>
              CK#
              {checkNumber}
            </SearchResultItemTitle>
          </FlexContainer>
        </FlexFullWidthJustifyStart>
        <SearchResultItemDivider />
        {status !== OCRInvoiceStatuses.BLOCKED && (
          <SearchResultItemLinked key={invoiceId}>
            Amount {amount}
          </SearchResultItemLinked>
        )}
      </FlexColumn>
      <FlexColumn>
        <FlexContainer>
          <SearchResultItemLinked>{invoiceId}</SearchResultItemLinked>
          <SearchResultItemLinked>{supplierName}</SearchResultItemLinked>
        </FlexContainer>
        <SearchResultItemTitle>
          INV#
          {invoiceNumber}
        </SearchResultItemTitle>
      </FlexColumn>
    </CheckSearchItemContainer>
  );
}

CheckSearchResultComp.propTypes = {
  invoiceNumber: highlightedTextPropType.isRequired,
  checkNumber: highlightedTextPropType.isRequired,
  status: highlightedTextPropType.isRequired,
  invoiceId: highlightedTextPropType.isRequired,
  supplierName: highlightedTextPropType.isRequired,
  amount: highlightedTextPropType.isRequired,
};

// getCommonProps :: Properties -> Object
const getCommonProps = R.applySpec({
  invoiceNumber: R.prop('invoiceNumber'),
  checkNumber: R.pathOr(NOTHING_UI_STRING, [
    'workflow',
    'check',
    'checkNumber',
  ]),
  status: R.compose(R.toUpper, R.path(['workflow', 'status'])),
  invoiceId: R.prop('invoiceId'),
  supplierName: R.path(['supplier', 'company', 'name']),
  amount: R.compose(formatTotal, R.prop('total')),
});

export function ChecksSearchResult(props) {
  const commonProps = getCommonProps(props);

  return <CheckSearchResultComp {...props} {...commonProps} />;
}
