import { gql, useQuery } from '@apollo/client';
import { ALL_VALUES } from '../../../utils/constants/general.js';

const DEFAULT_PROPERTY_QUERY = gql`
  query DEFAULT_PROPERTY_QUERY($id: ID!) {
    property(id: $id) {
      _id
      name
    }
  }
`;

export const useDefaultProperty = (value) => {
  const { data, loading } = useQuery(DEFAULT_PROPERTY_QUERY, {
    variables: { id: value },
    skip: !value || value === ALL_VALUES,
  });

  return { data, loading };
};
