import React from 'react';
import { useDispatch } from 'react-redux';
import {
  EditPeopleForm as CommonEditPeopleForm,
  useProcessState,
} from 'poly-admin-ui';
import { useRouterParams } from 'poly-client-routing';
import { editPeopleFormId } from '../constants.js';
import { ModalFormWrapper } from '../../../containers/modal/ModalWrapper.js';
import { setOpenModal } from '../../../state/modules/modal.js';

function EditPeopleForm(props) {
  const dispatch = useDispatch();

  const { contactId } = useRouterParams(['contactId']);

  const onSuccess = () => dispatch(setOpenModal({ name: null }));

  return (
    <CommonEditPeopleForm
      {...props}
      onSuccess={onSuccess}
      formId={editPeopleFormId}
      contactId={contactId}
    />
  );
}

export function EditPeopleModal(props) {
  const { process } = useProcessState(editPeopleFormId);
  return (
    <ModalFormWrapper
      {...props}
      actionInProgress={!!process}
      title="Edit Contact"
      buttonLabel="Save"
      formId={editPeopleFormId}
      Form={EditPeopleForm}
    />
  );
}
