import * as R from 'ramda';
import { string } from 'prop-types';
import { useDispatch } from 'react-redux';
import React, { useEffect, useCallback } from 'react';
import { debounceWithDefaultThreshold } from 'poly-client-utils';

import { SearchInputComp } from '../components/SearchInputC.js';
import { setSearch } from '../state/modules/search.js';

export function SearchInput({ placeholder }) {
  const dispatch = useDispatch();

  const callSetSearchInput = useCallback(
    debounceWithDefaultThreshold((text) => dispatch(setSearch(text))),
    [],
  );

  const clearSearchText = () => dispatch(setSearch(''));

  useEffect(() => {
    clearSearchText();

    return () => clearSearchText();
  }, []);

  const inputProps = {
    name: 'TableSearchInput',
    dataTestId: 'table-search-input',
    placeholder: placeholder || 'Search table',
    onChange: R.compose(callSetSearchInput, R.pathOr('', ['target', 'value'])),
  };

  return <SearchInputComp {...inputProps} />;
}

SearchInput.displayName = 'SearchInput';
SearchInput.propTypes = { placeholder: string };
