import { gql, useMutation } from '@apollo/client';

const APPROVE_BATCH_MUTATION = gql`
  mutation APPROVE_BATCH($batchId: ID!, $invoiceIds: [ID]!) {
    approveForPayment(batchId: $batchId, invoiceIds: $invoiceIds)
  }
`;

export const useApproveInvoices = () => {
  const [mutate] = useMutation(APPROVE_BATCH_MUTATION);

  return {
    approveBatch: (variables) => mutate({ variables }),
  };
};
