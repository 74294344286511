import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useNotificationContext } from 'poly-book-admin';
import { useCurrentUserQuery } from 'poly-client-utils';
import { OCRInvoiceStatuses } from 'poly-constants';
import { insertParamsIntoURL } from 'poly-utils';

import { routes } from '../../routes/constants.js';
import { ModalNames } from '../constants/modals.js';
import { setOpenModal } from '../../state/modules/modal.js';

const ASSIGN_MANAGER_TO_INVOICE_MUTATION = gql`
  mutation ASSIGN_MANAGER_TO_INVOICE_MUTATION(
    $invoiceId: ID!
    $managerId: ID!
  ) {
    assignManagerToOCRInvoice(invoiceId: $invoiceId, managerId: $managerId)
  }
`;

const useAssignManagerToInvoice = () => {
  const [mutate] = useMutation(ASSIGN_MANAGER_TO_INVOICE_MUTATION, {
    alias: 'useAssignManager',
  });

  return {
    assignManagerToOCRInvoice: ({ invoiceId, managerId }) =>
      mutate({ variables: { invoiceId, managerId } }).then(
        R.path(['data', 'assignManagerToOCRInvoice']),
      ),
  };
};

const getXtractaCallbackUrl = (invoiceId) =>
  `${window.location.origin}${insertParamsIntoURL(
    { invoiceId },
    routes.INVOICE_VERIFY,
  )}`;

const extractReviewURL = R.path(['data', 'reviewUrlForDocument']);

const INVOICE_REVIEW = gql`
  query INVOICE_REVIEW($id: ID!, $callbackUrl: NonEmptyString!) {
    reviewUrlForDocument(id: $id, callbackUrl: $callbackUrl)
  }
`;

export const useHandleXtractaDocument = () => {
  const dispatch = useDispatch();

  const [queryHandler] = useLazyQuery(INVOICE_REVIEW);

  const handleXtractaDocument = (invoiceId) => {
    dispatch(
      setOpenModal({
        name: ModalNames.OPEN_XTRACTA_MODAL,
        payload: 'Getting Xtracta url',
      }),
    );
    queryHandler({
      variables: {
        id: invoiceId,
        callbackUrl: getXtractaCallbackUrl(invoiceId),
      },
    })
      .then(
        R.ifElse(extractReviewURL, extractReviewURL, (resp) =>
          Promise.reject(resp),
        ),
      )
      .then((xtractaUrl) => window.location.replace(xtractaUrl))
      .finally(() => dispatch(setOpenModal({ name: null })));
  };

  return {
    handleXtractaDocument,
  };
};

export const statusIsNotVerified = R.pipe(
  R.pathEq(['workflow', 'status'], OCRInvoiceStatuses.VERIFIED),
  R.not,
);

export const handleInvoicePickup = async ({
  assignManagerToOCRInvoice,
  handleXtractaDocument,
  showError,
  invoice,
  user,
}) => {
  if (!invoice?._id || !user?._id) return;

  try {
    const isManagerAssigned = await assignManagerToOCRInvoice({
      invoiceId: invoice._id,
      managerId: user._id,
    });

    if (isManagerAssigned && statusIsNotVerified(invoice)) {
      handleXtractaDocument(invoice._id);
    }
  } catch {
    showError('An error occurred during manager assignment');
  }
};

export const usePickupInvoice = () => {
  const { user } = useCurrentUserQuery();

  const { assignManagerToOCRInvoice } = useAssignManagerToInvoice();
  const { handleXtractaDocument } = useHandleXtractaDocument();

  const { showErrorNotification } = useNotificationContext();

  const pickUpInvoice = (invoice) =>
    handleInvoicePickup({
      assignManagerToOCRInvoice,
      handleXtractaDocument,
      showError: showErrorNotification,
      invoice,
      user,
    });

  return {
    pickUpInvoice,
  };
};
