import React from 'react';
import { formatSelectOptionsByConstants } from 'poly-client-utils';
import { propertyTypes } from 'poly-constants';
import { Select } from 'poly-book-admin';

export function PropertyTypeSelect(props) {
  const selectProps = {
    ...props,
    name: 'PropertyTypeSelect',
    options: formatSelectOptionsByConstants(propertyTypes),
  };

  return <Select {...selectProps} />;
}

PropertyTypeSelect.displayName = 'PropertyTypeSelect';
