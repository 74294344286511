import { useServiceTypesBySearch } from '../../../serviceType/apollo/withServiceTypesBySearch.js';

export const useServiceTypeDetails = (serviceTypeId) => {
  const { loading, result } = useServiceTypesBySearch({
    query: { term: { _id: serviceTypeId } },
    skipQuery: !serviceTypeId,
  });

  return {
    loading,
    serviceType: result?.searchServiceTypes?.hits[0],
  };
};
