import React from 'react';
import styled from 'styled-components';
import { bool, string } from 'prop-types';
import { FooterContainer, getThemeColor, getThemeFont } from 'poly-book-admin';
import { useBatchDetails } from '../apollo/useBatchSubscription.js';
import {
  isAllowedSendToXero,
  isFundedStatus,
  isNewStatus,
} from './common/utils.js';
import { ApproveInvoicesBtn } from './ApproveBatchBtn.js';
import { SendToXeroBtn } from './SendToXeroBtn.js';
import { PayCityBankBtn } from './PayCitiBankBtn.js';

const TotalTitle = styled.div`
  font-weight: ${getThemeFont(['regular'])};
  font-size: 13px;
  color: ${getThemeColor(['secondary'])};
  letter-spacing: 0.8px;
  margin-right: 5px;
`;

const TotalValue = styled.div`
  font-weight: ${getThemeFont(['semibold'])};
  color: ${getThemeColor(['primary'])};
  font-size: 14px;
`;

const TotalContainer = styled.div`
  display: flex;
  align-items: center;
`;

function BatchPageFooterC({
  showApproveBtn,
  showPayCitiBtn,
  showSendXeroBtn,
  invoicesTotal,
}) {
  return (
    <FooterContainer>
      {showApproveBtn && <ApproveInvoicesBtn />}
      {showSendXeroBtn && <SendToXeroBtn />}
      {showPayCitiBtn && <PayCityBankBtn />}
      <TotalContainer>
        <TotalTitle>Total:</TotalTitle>
        <TotalValue>{invoicesTotal}</TotalValue>
      </TotalContainer>
    </FooterContainer>
  );
}

BatchPageFooterC.displayName = 'BatchActionsFooter';
BatchPageFooterC.propTypes = {
  invoicesTotal: string.isRequired,
  showApproveBtn: bool.isRequired,
  showPayCitiBtn: bool.isRequired,
  showSendXeroBtn: bool.isRequired,
};

export function BatchPageFooter(props) {
  const { data, loading } = useBatchDetails();

  if (loading) return null;

  return (
    <BatchPageFooterC
      {...props}
      showApproveBtn={isNewStatus(data?.batch)}
      showPayCitiBtn={isFundedStatus(data?.batch)}
      showSendXeroBtn={isAllowedSendToXero(data?.batch)}
    />
  );
}
