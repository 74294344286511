import React from 'react';
import { ServiceTypeStatuses } from 'poly-constants';
import {
  defaultTheme,
  Heading,
  PageHeaderContainer,
  PageHeaderLinks,
  Status,
} from 'poly-book-admin';
import { string } from 'prop-types';
import { useSetInitialPaginationOnMount } from 'poly-admin-ui';
import { PageTabs } from '../../components/tabs/PageTabs.js';
import { SearchInput } from '../../containers/SearchInput.js';
import { ServiceTypesTable } from './ServiceTypeTable/ServiceTypesTable.js';
import { usePrefetchServiceTypes } from './apollo/serviceTypes.js';
import { useServiceTypesByStatusAndSearch } from './apollo/withServiceTypesBySearch.js';
import { AddNewServiceTypeBtn } from '../../containers/buttons/AddNewServiceTypeBtn.js';

const {
  colors: {
    statuses: { active, inactive },
  },
} = defaultTheme;

const serviceTypesStatusColors = {
  [ServiceTypeStatuses.active]: active,
  [ServiceTypeStatuses.inactive]: inactive,
};

function TitleComp({ status, text, ...restProps }) {
  const { result } = useServiceTypesByStatusAndSearch(status);

  return (
    <Status
      {...restProps}
      color={serviceTypesStatusColors[status]}
      text={`${text} (${result?.searchServiceTypes?.total || 0})`}
    />
  );
}

TitleComp.displayName = 'TitleComp';

TitleComp.propTypes = {
  status: string,
  text: string,
};

function TabsWithPrefetch(props) {
  useSetInitialPaginationOnMount();

  const { activeServiceTypes, inactiveServiceTypes } =
    usePrefetchServiceTypes();

  return (
    <PageTabs {...props} {...activeServiceTypes} {...inactiveServiceTypes} />
  );
}

export function ServiceTypesPage() {
  return (
    <>
      <PageHeaderContainer>
        <Heading.h2>Service Types</Heading.h2>
        <PageHeaderLinks topLinks={<AddNewServiceTypeBtn />} />
      </PageHeaderContainer>
      <TabsWithPrefetch
        toolBar={<SearchInput />}
        tabs={[
          [
            <TitleComp
              key={ServiceTypeStatuses.active}
              text="Active"
              status={ServiceTypeStatuses.active}
            />,
            'active-service-types',
            <ServiceTypesTable
              key={ServiceTypeStatuses.active}
              status={ServiceTypeStatuses.active}
            />,
          ],
          [
            <TitleComp
              key={ServiceTypeStatuses.inactive}
              text="Inactive"
              status={ServiceTypeStatuses.inactive}
            />,
            'inactive-service-types',
            <ServiceTypesTable
              key={ServiceTypeStatuses.inactive}
              status={ServiceTypeStatuses.inactive}
            />,
          ],
        ]}
        defaultValue="active-service-types"
      />
    </>
  );
}
