import { gql, useQuery } from '@apollo/client';
import { useEntitySubscriptionQuery } from 'poly-client-utils';
import { useRouterParams } from 'poly-client-routing';
import { commonAddressFields } from 'poly-admin-ui';

const propertyDetailsFields = gql`
  fragment propertyDetailsFields on Property {
    _id
    name
    status
    client {
      _id
      name
      configs {
        propertyFields {
          division
          storeNumber
          districtFacilityManager
        }
      }
    }
    address {
      ...commonAddressFields
    }
    aacContractMGR {
      _id
      fullName
    }
    branchManager {
      _id
      profile {
        fullName
        personalEmail
        personalPhone
      }
      fullName
      email
      emails {
        address
      }
    }
    addressTwo
    remarks
    invoiceTo
    fax
    phone
    occupancy
    ownership
    clientOuterId
    clientExternalId
    clientUnitName
    tier
    typeByClient
    square
    cardNumber
  }

  ${commonAddressFields}
`;

const PROPERTY_DETAILS = gql`
  query PROPERTY_DETAILS($id: ID!) {
    property(id: $id) {
      ...propertyDetailsFields
    }
  }

  ${propertyDetailsFields}
`;

const PROPERTY_DETAILS_SUB = gql`
  subscription PROPERTY_DETAILS_SUB($input: SingleDocSubInput!) {
    propertyChanged(input: $input) {
      id
      document {
        ...propertyDetailsFields
      }
    }
  }

  ${propertyDetailsFields}
`;

export const usePropertyDetails = (propertyId) => {
  const { data, loading } = useQuery(PROPERTY_DETAILS, {
    variables: { id: propertyId },
    skip: !propertyId,
  });

  return { data, loading };
};

export const usePropertyDetailsSubscription = () => {
  const { propertyId } = useRouterParams(['propertyId']);

  const { restProps } = useEntitySubscriptionQuery({
    gqlQuery: PROPERTY_DETAILS,
    fetchPolicy: 'network-only',
    queryEndpointName: 'property',
    gqlSubscription: PROPERTY_DETAILS_SUB,
    alias: 'usePropertyDetailsSubscription',
    propsOfComponent: { entityId: propertyId },
    subscriptionEndpointName: 'propertyChanged',
  });

  return { result: restProps };
};
