import React from 'react';
import styled from 'styled-components';

import { Text } from 'poly-book-admin';
import { CenterContent } from '../components/lib/centerContent.js';

const Wrapper = styled(CenterContent)`
  height: 80%;
  flex-direction: column;
  text-align: center;
`;

export function NotFoundPage() {
  return (
    <Wrapper>
      <Text size="40px">404</Text>
      <Text size="20px">Page not found</Text>
    </Wrapper>
  );
}

NotFoundPage.displayName = 'NotFoundPage';
