import { createAction, handleAction } from 'redux-actions';

export const setPagination = createAction('SET_PAGINATION');

export const paginationReducer = handleAction(
  setPagination,
  (_, { payload }) => payload,
  {
    allItemsCount: 0,
    itemsPerPage: 25,
    currentPage: 1,
  },
);
