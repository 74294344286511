import { useEffect } from 'react';
import { useSelectableTable } from 'poly-book-admin';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRows } from '../../state/modules/selected-rows.js';

export const useSelectableTableLogic = (rows, allowSelectInvoices) => {
  const dispatch = useDispatch();
  const selectedRows = useSelector((state) => state.selectedRows);

  const selectableProps = useSelectableTable({
    setSelectedRows,
    selectedRows,
    rows,
    dispatch,
  });

  useEffect(
    () => () => {
      dispatch(setSelectedRows([]));
    },
    [],
  );

  return allowSelectInvoices ? selectableProps : null;
};
