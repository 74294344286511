import React from 'react';
import {
  DefaultBodyWrapper,
  MainHeader,
  PageHeaderContainer,
} from 'poly-book-admin';
import { useProcessState } from 'poly-admin-ui';
import { bool } from 'prop-types';
import {
  ButtonsContainer,
  CancelFormBtn,
  SubmitBtn,
} from '../../../containers/buttons/ActionButtons.js';
import { routes } from '../../../routes/constants.js';
import { AddPropertyForm } from '../PropertyForm/AddPropertyForm.js';
import { addPropertyFormId } from '../../../utils/constants/property.js';

function AddPropertyFormHeaderComp({ loading }) {
  return (
    <PageHeaderContainer>
      <MainHeader>Create a New Property</MainHeader>
      <ButtonsContainer>
        <SubmitBtn disabled={loading} form={addPropertyFormId} loader={loading}>
          Create
        </SubmitBtn>
        <CancelFormBtn disabled={loading} redirectUrl={routes.PROPERTIES} />
      </ButtonsContainer>
    </PageHeaderContainer>
  );
}

AddPropertyFormHeaderComp.propTypes = {
  loading: bool.isRequired,
};

function AddPropertyFormHeader() {
  const { process } = useProcessState(addPropertyFormId);

  return <AddPropertyFormHeaderComp loading={process} />;
}

AddPropertyFormHeader.displayName = 'AddPropertyFormHeader';

export function AddPropertyPage() {
  return (
    <>
      <AddPropertyFormHeader />
      <DefaultBodyWrapper>
        <AddPropertyForm />
      </DefaultBodyWrapper>
    </>
  );
}
