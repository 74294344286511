import React from 'react';
import { Loader } from 'poly-book-admin';

import { ApprovalTableC } from './ApprovalTableC.js';
import { useApprovalInvoices } from '../useApprovalInvoices.js';

export function ApprovalTable() {
  const { loading, tableProps } = useApprovalInvoices();

  if (loading) return <Loader />;

  return <ApprovalTableC {...tableProps} />;
}

ApprovalTable.displayName = 'ApprovalTable';
