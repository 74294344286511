import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { Form } from 'react-final-form';

import { useModalContext, useNotificationState } from 'poly-admin-ui';

import { UserForm } from './UserForm.js';
import { prepareClientUserFormDataForMutation, validateUser } from './utils.js';

export const addUserFormId = 'addUserFormId';

const CREATE_USER_MUTATION = gql`
  mutation CREATE_USER_WITH_GROUP_MUTATION($input: CreateUserWithGroupInput!) {
    createUserWithGroup(user: $input) {
      _id
    }
  }
`;

export function AddUserForm() {
  const [createUser] = useMutation(CREATE_USER_MUTATION);

  const { showSuccessNotification } = useNotificationState();

  const { closeModal } = useModalContext();

  const handleSubmit = async (values) => {
    await createUser({
      variables: {
        input: prepareClientUserFormDataForMutation(values),
      },
    });

    showSuccessNotification('User created');

    closeModal(addUserFormId);
  };

  return (
    <Form
      initialValues={{}}
      render={UserForm}
      onSubmit={handleSubmit}
      formId={addUserFormId}
      validate={validateUser}
    />
  );
}
