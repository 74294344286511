import { useEntitySubscriptionQuery } from 'poly-client-utils';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'poly-client-routing';

const invoiceAccountFields = gql`
  fragment invoiceAccountFields on OCRInvoice {
    _id
    invoiceId
    invoiceFileLink
    supplierOuterContractId
    isNotRecurringInvoice
    dueDate
    invoiceNumber
    invoiceDate
    total
    receiveDate
    batch {
      _id
      batchId
    }
    workflow {
      ... on TiaaInvoiceWorkflow {
        status
        check {
          _id
          checkNumber
          issuanceDate
        }
      }
    }
    supplier {
      _id
      company {
        name
        address {
          formatted_address
        }
      }
    }
    property {
      _id
      name
      address {
        formatted_address
      }
    }
    manager {
      _id
      fullName
    }
  }
`;

const INVOICE_ACCOUNT_QUERY = gql`
  query INVOICE_ACCOUNT_QUERY($id: ID!) {
    ocrInvoice(id: $id) {
      ...invoiceAccountFields
    }
  }
  ${invoiceAccountFields}
`;

const INVOICE_ACCOUNT_SUBSCRIPTION = gql`
  subscription INVOICE_ACCOUNT_SUBSCRIPTION($input: SingleDocSubInput!) {
    ocrInvoiceChanged(input: $input) {
      id
      document {
        ...invoiceAccountFields
      }
    }
  }
  ${invoiceAccountFields}
`;

export const useInvoiceAccount = (isInvoiceRoute) => {
  const { invoiceId } = useParams();

  const { data, loading } = useQuery(INVOICE_ACCOUNT_QUERY, {
    variables: { id: invoiceId },
    skip: !isInvoiceRoute,
  });

  return {
    data,
    loading,
  };
};

export const useInvoiceAccountSubscription = () => {
  const { invoiceId } = useParams();

  const { restProps } = useEntitySubscriptionQuery({
    gqlQuery: INVOICE_ACCOUNT_QUERY,
    gqlSubscription: INVOICE_ACCOUNT_SUBSCRIPTION,
    queryEndpointName: 'ocrInvoice',
    subscriptionEndpointName: 'ocrInvoiceChanged',
    propsOfComponent: { entityId: invoiceId },
    fetchPolicy: 'network-only',
    alias: 'useInvoiceAccountSubscription',
  });

  return {
    result: restProps,
  };
};
