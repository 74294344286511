import * as R from 'ramda';
import styled from 'styled-components';
import {
  Button,
  getThemeColor,
  getThemeFont,
  LinkButton,
  Text,
} from 'poly-book-admin';

export const FlexContainer = styled.div`
  display: flex;
`;

export const SpaceBetween = styled(FlexContainer)`
  justify-content: space-between;
`;

export const FlexColumn = styled(FlexContainer)`
  flex-direction: column;
`;

export const FlexContainerS = styled(FlexContainer)`
  width: ${R.prop('width')}%;
  flex-direction: ${R.propOr('row', 'direction')};
  align-items: ${R.propOr('center', 'alignItems')};
`;

export const LabelS = styled(FlexContainerS)`
  align-items: flex-start;
  font-size: 14px;
  line-height: 15px;
  color: ${getThemeColor(['primaryLighter3'])};
  font-weight: ${getThemeFont(['medium'])};
  margin-right: 10px;
  padding-bottom: 5px;
`;

export const ValueText = styled(Text)`
  font-size: 15px;
  line-height: 15px;
  color: ${getThemeColor(['primary'])};
  align-items: center;
  justify-content: center;
`;

export const InvoiceInfoItemWrapper = styled(FlexColumn)`
  margin-right: 20px;
`;

export const LinkButtonS = styled(LinkButton)`
  margin-top: 10px;
  font-size: 14px;
  align-self: flex-start;
`;

export const LineContainer = styled(SpaceBetween)`
  margin-bottom: 10px;
`;

export const FooterContainer = styled(SpaceBetween)`
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 20px;
  border-top: 2px solid #efefef;
`;

export const TotalContainer = styled(SpaceBetween)`
  color: ${getThemeColor(['primary'])};
  padding: 5px 0 10px 0;
`;

export const VerifyButton = styled(Button)`
  margin-left: 10px;
`;
