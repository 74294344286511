import { ASC_SORT_ORDER, ServiceTypeStatuses } from 'poly-constants';
import { keywordSortQuery } from 'poly-client-utils';
import { useServiceTypesBySearch } from './withServiceTypesBySearch.js';

const defaultSort = keywordSortQuery(['name'])(ASC_SORT_ORDER);

const useSearchServiceTypesByStatus = (status) => {
  const { loading, result } = useServiceTypesBySearch({
    query: { term: { status } },
    sort: defaultSort,
  });

  return {
    loading,
    result,
  };
};

export const usePrefetchServiceTypes = () => {
  const activeServiceTypes = useSearchServiceTypesByStatus(
    ServiceTypeStatuses.active,
  );
  const inactiveServiceTypes = useSearchServiceTypesByStatus(
    ServiceTypeStatuses.inactive,
  );

  return {
    activeServiceTypes,
    inactiveServiceTypes,
  };
};
