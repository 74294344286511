import { gql } from '@apollo/client';
import {
  commonSortQuery,
  useEntitiesByReactiveReduxSearch,
  useSubscriptionByChanges,
} from 'poly-client-utils';
import { usePagination } from 'poly-admin-ui';
import { useRouterQuery } from 'poly-client-routing';
import { ASC_SORT_ORDER } from 'poly-constants';
import { generateQueueInvoicesMustQuery } from '../../../utils/invoices.js';
import { OCR_INVOICES_BY_SEARCH_SUB } from './useOcrInvoicesBySearch.js';
import { ITEMS_PER_SCROLL } from '../../../utils/constants/general.js';
import { SUPPLIER_SEARCH_CHANGED } from '../../supplier/apollo/useSuppliersBySearch.js';

const QUEUE_INVOICES = gql`
  query QUEUE_INVOICES($searchInput: CollectionSearchParams) {
    searchOCRInvoices(input: $searchInput) {
      total
      hits {
        _id
        invoiceId
        invoiceFileLink
        dueDate
        invoiceNumber
        invoiceDate
        total
        workflow {
          ... on TiaaInvoiceWorkflow {
            status
          }
        }
        supplier {
          _id
          company {
            name
          }
        }
        verifiedBy {
          _id
          fullName
        }
      }
    }
  }
`;

const queueInvoicesDefaultSort = commonSortQuery(['invoiceId'])(ASC_SORT_ORDER);

export const useQueueInvoices = (sort = queueInvoicesDefaultSort) => {
  const { pagination } = usePagination();

  const routerQuery = useRouterQuery([
    'OCRInvoiceStatus',
    'propertyId',
    'managerId',
  ]);

  const { loading, refetch, result } = useEntitiesByReactiveReduxSearch({
    gqlSearchQuery: QUEUE_INVOICES,
    gqlSearchChangedQuery: OCR_INVOICES_BY_SEARCH_SUB,
    pagination: { ...pagination, itemsPerPage: ITEMS_PER_SCROLL },
    query: { bool: { must: generateQueueInvoicesMustQuery(routerQuery) } },
    fetchPolicy: 'network-only',
    sort,
  });

  useSubscriptionByChanges({
    gqlQueryChanged: SUPPLIER_SEARCH_CHANGED,
    refetch,
    result,
  });

  return {
    loading,
    result,
  };
};
