import React, { useState } from 'react';
import { Input } from 'poly-book-admin';
import { shape, string, func, bool } from 'prop-types';
import { gql, useApolloClient } from '@apollo/client';
import { DuplicatedInvoiceNumberError } from 'poly-admin-ui';

const duplicateOcrInvoiceQuery = gql`
  query DUPLICATE_OCR_INVOICES_QUERY($input: CollectionSearchParams!) {
    searchOCRInvoices(input: $input) {
      hits {
        _id
        invoiceNumber
        invoiceDate
        total
        invoiceFileLink
        supplier {
          _id
          company {
            name
          }
        }
      }
      total
    }
  }
`;

const getSearchInvoiceNumberDuplicateQuery = ({
  _id,
  invoiceNumber,
  supplierId,
}) => ({
  bool: {
    must: [
      ...(_id ? [{ bool: { must_not: { term: { _id } } } }] : []),
      { term: { supplierId } },
      { term: { invoiceNumber } },
    ],
  },
});

const useCheckInvoiceNumberDuplicate = ({ _id, invoiceNumber, supplierId }) => {
  const apolloClient = useApolloClient();

  const checkInvoiceNumberDuplication = async () => {
    try {
      const { data } = await apolloClient.query({
        query: duplicateOcrInvoiceQuery,
        variables: {
          input: {
            size: 1,
            query: getSearchInvoiceNumberDuplicateQuery({
              _id,
              invoiceNumber,
              supplierId,
            }),
          },
        },
        fetchPolicy: 'network-only',
      });

      const duplicatedInvoices =
        data.searchOCRInvoices.total > 0 ? data.searchOCRInvoices.hits : [];

      return duplicatedInvoices;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return [];
    }
  };

  return { checkInvoiceNumberDuplication };
};

export function InvoiceNumberInput({
  value: invoiceNumber,
  formData: { _id, supplierId },
  meta: { touched },
  onBlur,
  ...props
}) {
  const [duplicatedInvoices, setDuplicatedInvoices] = useState([]);

  const { checkInvoiceNumberDuplication } = useCheckInvoiceNumberDuplicate({
    invoiceNumber,
    _id,
    supplierId,
  });

  // run checking after input blur
  const onBlurHandler = async () => {
    const invoices = await checkInvoiceNumberDuplication();
    setDuplicatedInvoices(invoices);
    onBlur();
  };

  // check duplication on render field
  if (!touched) {
    onBlurHandler();
  }

  return (
    <Input
      value={invoiceNumber}
      warning={
        duplicatedInvoices.length > 0 && (
          <DuplicatedInvoiceNumberError
            position="left"
            {...duplicatedInvoices[0]}
          />
        )
      }
      onBlur={onBlurHandler}
      {...props}
    />
  );
}

InvoiceNumberInput.propTypes = {
  value: string.isRequired,
  formData: shape({
    _id: string.isRequired,
    supplierId: string.isRequired,
  }),
  meta: shape({
    touched: bool.isRequired,
  }),
  onBlur: func.isRequired,
};
