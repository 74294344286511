import React from 'react';
import * as R from 'ramda';
import { yearPeriodOptions } from 'poly-utils';
import { Select } from 'poly-book-admin';

import { START_YEAR_PERIOD } from '../../utils/constants/general.js';

// yearOptions :: Date -> [Option]
const yearOptions = R.compose(
  R.map(R.converge(R.mergeLeft, [R.objOf('value'), R.objOf('label')])),
  yearPeriodOptions(START_YEAR_PERIOD),
);

export function YearSelect(props) {
  const selectProps = {
    ...props,
    width: '130px',
    name: 'YearSelect',
    dataTestId: 'year-select',
    placeholder: 'Select Year',
    options: yearOptions(new Date()),
  };

  return <Select {...selectProps} />;
}

YearSelect.displayName = 'YearSelect';
