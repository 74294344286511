import * as R from 'ramda';
import React from 'react';
import { Input } from 'poly-book-admin';
import { mergeFormConfigs, rejectFormFieldsByNamesList } from 'poly-admin-ui';
import { MAX_INT_32_VALUE } from 'poly-constants';

import { PropertyOccupancySelect } from '../../../components/selects/PropertyOccupancySelect.js';
import { PropertyOwnershipSelect } from '../../../components/selects/PropertyOwnershipSelect.js';
import { PropertyAffiliateSelect } from '../../../components/selects/PropertyAffiliateSelect.js';
import { PropertyTierSelect } from '../../../components/selects/PropertyTierSelect.js';
import { PropertyTypeSelect } from '../../../components/selects/PropertyTypeSelect.js';

const halfWidth = 'calc(50% - 10px)';

const propertyInfoSection = [
  {
    label: 'Property Info',
    order: 3,
    layout: {
      column: 2,
    },
    fields: [
      {
        label: 'Occupancy',
        order: 1,
        layout: { width: halfWidth, row: 1 },
        field: {
          name: 'occupancy',
          Component: PropertyOccupancySelect,
        },
      },
      {
        label: 'Ownership',
        order: 2,
        layout: { width: halfWidth, row: 1 },
        field: {
          name: 'ownership',
          Component: PropertyOwnershipSelect,
        },
      },
      {
        label: 'Budget Code',
        order: 3,
        layout: { width: halfWidth, row: 2 },
        field: {
          name: 'clientOuterId',
          Component: Input,
        },
        required: true,
        validators: [[R.identity, 'Budget Code is required']],
      },
      {
        label: 'PeopleSoft ID',
        order: 4,
        layout: { width: halfWidth, row: 2 },
        field: {
          name: 'clientExternalId',
          Component: Input,
        },
        required: true,
        validators: [[R.identity, 'PeopleSoft ID is required']],
      },
      {
        label: 'Tier',
        order: 5,
        layout: { width: halfWidth, row: 3 },
        field: {
          name: 'tier',
          Component: PropertyTierSelect,
        },
      },
      {
        label: 'Affiliate',
        order: 6,
        layout: { width: halfWidth, row: 3 },
        field: {
          name: 'clientUnitName',
          Component: PropertyAffiliateSelect,
        },
      },
      {
        label: 'Property Type',
        order: 7,
        layout: { width: halfWidth, row: 4 },
        field: {
          name: 'typeByClient',
          Component: PropertyTypeSelect,
        },
      },
      {
        label: 'Rentable Sq Ft',
        order: 8,
        layout: { width: halfWidth, row: 4 },
        field: {
          name: 'square',
          Component: (props) => (
            <Input
              {...props}
              type="numberMask"
              floatValue
              thousandSeparator
              maxValue={MAX_INT_32_VALUE}
            />
          ),
        },
      },
    ],
  },
];

const tiaaPropertyFormFieldsBlackList = ['aacContractMGR', 'branchManager'];

// transformPropertyFormSections :: [FormSection] -> [FormSection]
export const transformPropertyFormSections = R.compose(
  rejectFormFieldsByNamesList(tiaaPropertyFormFieldsBlackList),
  mergeFormConfigs(R.__, propertyInfoSection),
);
