export const uiBatchStatuses = {
  NEW: 'New',
  FUNDED: 'Funded',
  SYNC_OK: 'Sync OK',
  APPROVED: 'Approved',
  SYNC_ERROR: 'Sync Error',
  SENT_TO_AP: 'Sent To AP',
  SENT_TO_CITI: 'Sent To Citi',
  PENDING_SYNC: 'Pending Sync',
  AP_SYNC_ERROR: 'AP Sync Error',
  CITY_SYNC_ERROR: 'Citi Sync Error',
  PENDING_APPROVAL: 'Pending Approval',
};
