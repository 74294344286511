import React from 'react';
import { useDispatch } from 'react-redux';
import { TextButton } from 'poly-book-admin';
import { object, oneOfType, shape, string, array } from 'prop-types';
import { setOpenModal } from '../../../state/modules/modal.js';
import { ModalNames } from '../../../utils/constants/modals.js';

export function AddUpdateBtn({
  collection,
  entity,
  updateCacheParams,
  ...restProps
}) {
  const dispatch = useDispatch();

  const onCancel = () => dispatch(setOpenModal({ name: null }));

  const onClick = () =>
    dispatch(
      setOpenModal({
        name: ModalNames.ADD_UPDATE,
        payload: {
          collection,
          entity,
          updateCacheParams,
        },
      }),
    );

  return (
    <TextButton {...restProps} onCancel={onCancel} onClick={onClick}>
      Add New
    </TextButton>
  );
}

AddUpdateBtn.propTypes = {
  collection: string.isRequired,
  entity: shape({
    name: string,
    _id: string,
    humanReadableId: string,
  }).isRequired,
  updateCacheParams: shape({
    variables: oneOfType([string, object]),
    query: oneOfType([string, array, object]),
  }),
};
