import { gql, useMutation } from '@apollo/client';

const BLOCK_OCR_INVOICES_MUTATION = gql`
  mutation BLOCK_OCR_INVOICES_MUTATION($input: BlockOCRInvoicesInput!) {
    blockOCRInvoices(input: $input) {
      invoices {
        _id
      }
    }
  }
`;

export const useBlockInvoices = () => {
  const [mutate, { loading }] = useMutation(BLOCK_OCR_INVOICES_MUTATION);

  return {
    blockOCRInvoices: (invoiceIds) =>
      mutate({ variables: { input: { invoiceIds } } }),
    loading,
  };
};
