import * as R from 'ramda';
import React, { useState } from 'react';
import { TextButton } from 'poly-book-admin';
import { showBase64PDF } from 'poly-client-utils';
import { gql, useMutation } from '@apollo/client';
import { OCRInvoiceStatuses, PRINT_PDF_CAPTION } from 'poly-constants';
import { ALL_VALUES } from '../../../../utils/constants/general.js';
import { useSpendDetailReport } from '../withSpendDetailReport.js';

const CREATE_TIAA_CLIENT_SPEND_REPORT = gql`
  mutation CREATE_TIAA_CLIENT_SPEND_REPORT(
    $input: TiaaClientSpendReportInput!
  ) {
    createTiaaClientSpendReport(input: $input) {
      fileString
    }
  }
`;

const useCreateSpendReport = () => {
  const [mutate] = useMutation(CREATE_TIAA_CLIENT_SPEND_REPORT);

  return {
    createTiaaClientSpendReport: (input) => mutate({ variables: { input } }),
  };
};

// checkIfIsDisabled :: SearchSpendReportQueryResult -> Bool
export const checkIfIsDisabled = R.pathSatisfies(
  R.either(R.isNil, R.lte(R.__, 0)),
  ['searchOCRInvoices', 'total'],
);

export function GeneratePDFBtn() {
  const [isPDFGeneration, setPDFGeneration] = useState(null);

  const { createTiaaClientSpendReport } = useCreateSpendReport();

  const { result, filters } = useSpendDetailReport();

  const disabled = checkIfIsDisabled(result);

  const onClick = () => {
    if (!disabled) {
      setPDFGeneration(true);

      createTiaaClientSpendReport({
        startDate: filters.spendDetailsDateRange.startDate,
        endDate: filters.spendDetailsDateRange.endDate,
        propertyId:
          filters.property !== ALL_VALUES ? filters.property : undefined,
        status: OCRInvoiceStatuses.PAID,
      })
        .then(
          R.compose(
            showBase64PDF,
            R.path(['data', 'createTiaaClientSpendReport', 'fileString']),
          ),
        )
        .finally(() => setPDFGeneration(false));
    }
  };

  return (
    <TextButton loading={isPDFGeneration} disabled={disabled} onClick={onClick}>
      {PRINT_PDF_CAPTION}
    </TextButton>
  );
}

GeneratePDFBtn.displayName = 'GeneratePDFBtn';
