import React from 'react';
import styled from 'styled-components';
import { HeaderLinksWrapper, ToolBarBtnDivider, Loader } from 'poly-book-admin';
import { useRouterParams, useRouterQuery } from 'poly-client-routing';
import { filesCollections } from 'poly-constants';
import { NotFoundPage } from 'poly-admin-ui';
import {
  READ_CONTACT_USER_PERMISSION,
  READ_UPDATE_PERMISSION,
  READ_FILE_PERMISSION,
} from 'poly-security';

import {
  PropertyDetailsTab,
  PropertyPageHeader,
} from './PropertyDetailsTab.js';
import { PropertyPeopleTab } from './PropertyPeopleTab.js';
import { PropertyUpdatesTab } from './PropertyUpdatesTab.js';
import { FilesTab } from '../../../containers/tabs/FilesTab.js';
import { PageTabs } from '../../../components/tabs/PageTabs.js';
import { propertyTabs } from '../../../utils/constants/property.js';
import { EditPropertyForm } from '../PropertyForm/EditPropertyForm.js';
import { PropertyInvoicesPrintPDFBtn } from './PropertyInvoicesPrintPDFBtn.js';
import { PropertyInvoicesExportXLSBtn } from './PropertyInvoicesExportXLSBtn.js';
import { PropertyOCRInvoicesTab } from '../../invoice/EntityInvoicesTab/index.js';
import { PropertySuppliersTable } from './propertySuppliersTable/PropertySuppliersTable.js';
import { usePropertyDetails } from '../apollo/withPropertyDetails.js';
import { usePrefetchPropertyCard } from '../apollo/prefetches.js';

function PropertyFilesTab() {
  const { propertyId } = useRouterParams(['propertyId']);

  const { data, loading } = usePropertyDetails(propertyId);

  if (loading) return <Loader />;

  const entity = {
    _id: propertyId,
    humanReadableId: data?.property.name,
    name: 'property',
  };

  return <FilesTab collection={filesCollections.PROPERTIES} entity={entity} />;
}

function PropertyInvoicesTab() {
  const { propertyId } = useRouterParams(['propertyId']);

  return (
    <PropertyOCRInvoicesTab
      paginationVisible
      query={{
        match: { propertyId },
      }}
    />
  );
}

const tabs = [
  [
    'Info',
    propertyTabs.propertyInfo,
    <PropertyDetailsTab key={propertyTabs.propertyInfo} />,
  ],
  [
    'Invoices',
    propertyTabs.propertyInvoices,
    <PropertyInvoicesTab key={propertyTabs.propertyInvoices} />,
  ],
  [
    'Files',
    propertyTabs.propertyFiles,
    <PropertyFilesTab key={propertyTabs.propertyFiles} />,
    READ_FILE_PERMISSION,
  ],
  [
    'Updates',
    propertyTabs.propertyUpdates,
    <PropertyUpdatesTab key={propertyTabs.propertyUpdates} />,
    READ_UPDATE_PERMISSION,
  ],
  [
    'Contacts',
    propertyTabs.propertyPeople,
    <PropertyPeopleTab key={propertyTabs.propertyPeople} />,
    READ_CONTACT_USER_PERMISSION,
  ],
  [
    'Suppliers',
    propertyTabs.propertySuppliers,
    <PropertySuppliersTable key={propertyTabs.propertySuppliers} />,
  ],
];

const HeaderLinksWrapperS = styled(HeaderLinksWrapper)`
  > div,
  button {
    margin: 0;
  }

  > div {
    margin-left: 15px;
  }
`;

function PropertyTollBar() {
  const { tab } = useRouterQuery(['tab']);

  if (tab === propertyTabs.propertyInvoices) {
    return (
      <HeaderLinksWrapperS>
        <PropertyInvoicesPrintPDFBtn />
        <ToolBarBtnDivider />
        <PropertyInvoicesExportXLSBtn />
      </HeaderLinksWrapperS>
    );
  }

  return null;
}

function PropertyPageC() {
  const { isEdit } = useRouterQuery(['isEdit']);

  return (
    <>
      <PropertyPageHeader />
      {isEdit ? (
        <EditPropertyForm />
      ) : (
        <PageTabs
          tabs={tabs}
          toolBar={<PropertyTollBar />}
          defaultValue={propertyTabs.propertyInfo}
        />
      )}
    </>
  );
}

export function PropertyPage() {
  const { loading, propertyDetails } = usePrefetchPropertyCard();

  if (loading) return <Loader />;
  if (!propertyDetails?.property) return <NotFoundPage />;

  return <PropertyPageC />;
}
