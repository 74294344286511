import mime from 'mime';
import { gql, useQuery } from '@apollo/client';
import { useRouterParams } from 'poly-client-routing';
import { OCRInvoiceStatuses } from 'poly-constants';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ModalNames } from '../../../utils/constants/modals.js';
import { setOpenModal } from '../../../state/modules/modal.js';

const POLLING_INTERVAL = 5000;

const SYNC_INVOICE_WITH_XTRACTA = gql`
  query SYNC_INVOICE_WITH_XTRACTA($invoiceId: ID!) {
    syncInvoiceWithXtracta(id: $invoiceId) {
      _id
      invoiceId
      invoiceFileLink
      supplierOuterContractId
      client {
        _id
      }
      lines {
        lineNumber
        price
        quantity
        description
        measure
        subtotal
        serviceType {
          _id
        }
      }
      invoiceNumber
      invoiceDate
      total
      workflow {
        unvalidatedData {
          supplierAddress
          propertyAddress
          supplier {
            _id
            company {
              name
              address {
                formatted_address
              }
            }
          }
          property {
            _id
            name
            address {
              formatted_address
            }
          }
        }
        status
      }
      supplier {
        _id
        company {
          name
          address {
            formatted_address
          }
        }
      }
      property {
        _id
        name
        address {
          formatted_address
        }
      }
    }
  }
`;

const useSyncInvoice = (invoiceId) => {
  const { data, loading, startPolling, stopPolling } = useQuery(
    SYNC_INVOICE_WITH_XTRACTA,
    {
      variables: { invoiceId },
      skip: !invoiceId,
    },
  );

  return {
    syncInvoiceWithXtracta: data?.syncInvoiceWithXtracta,
    loading,
    startPolling,
    stopPolling,
  };
};

export const useInvoiceVerifyLogic = () => {
  const dispatch = useDispatch();

  const [isSynchronized, setSynchronized] = useState(false);

  const { invoiceId } = useRouterParams(['invoiceId']);

  const { syncInvoiceWithXtracta, loading, startPolling, stopPolling } =
    useSyncInvoice(invoiceId);

  useEffect(() => {
    if (
      syncInvoiceWithXtracta?.workflow.status ===
      OCRInvoiceStatuses.XTRACTA_EXPORT
    ) {
      dispatch(
        setOpenModal({
          name: ModalNames.OPEN_XTRACTA_MODAL,
          payload: 'Synchronization with Xtracta in progress',
        }),
      );
      startPolling(POLLING_INTERVAL);
    } else if (
      syncInvoiceWithXtracta?.workflow.status === OCRInvoiceStatuses.OCR_READY
    ) {
      stopPolling();
      dispatch(setOpenModal({ name: null }));
      setSynchronized(true);
    }
  }, [syncInvoiceWithXtracta]);

  return {
    loading,
    url: syncInvoiceWithXtracta?.invoiceFileLink,
    fileType: mime.getType(syncInvoiceWithXtracta?.invoiceFileLink),
    invoice: syncInvoiceWithXtracta,
    isSynchronized,
  };
};
