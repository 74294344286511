export const ModalNames = {
  APPROVE_INVOICES_MODAL: 'APPROVE_INVOICES_MODAL',
  OPEN_XTRACTA_MODAL: 'OPEN_XTRACTA_MODAL',
  CONFIRM_INVOICE_SAVE: 'CONFIRM_INVOICE_SAVE',
  CREATE_SUPPLIER: 'CREATE_SUPPLIER',
  CONFIRM_PRINT: 'CONFIRM_PRINT',
  CREATE_SERVICE_TYPE: 'CREATE_SERVICE_TYPE',
  EDIT_SERVICE_TYPE: 'EDIT_SERVICE_TYPE',
  CONFIRM_CANCEL_FORM_EDIT: 'CONFIRM_CANCEL_FORM_EDIT',
  ADD_FILES: 'ADD_FILES',
  ADD_UPDATE: 'ADD_UPDATE',
  ADD_PEOPLE: 'ADD_PEOPLE',
  ADD_PROPERTY_SUPPLIER: 'ADD_PROPERTY_SUPPLIER',
  EDIT_PEOPLE: 'EDIT_PEOPLE',
  GALLERY: 'GALLERY',
  EDIT_BATCH: 'EDIT_BATCH',
  ADD_TASK: 'ADD_TASK',
  EDIT_TASK: 'EDIT_TASK',
  COMPLETE_TASK: 'COMPLETE_TASK',
  POST_TASK_UPDATE: 'POST_TASK_UPDATE',
  CREATE_EDIT_RECURRING_INVOICE_MODAL: 'CREATE_EDIT_RECURRING_INVOICE_MODAL',
};
