import * as R from 'ramda';
import { useQuery, useSubscription } from '@apollo/client';

// defaultParamsFromProps :: Props -> QueryParams
// Props = Object
// QueryParams = Object
const defaultParamsFromProps = R.pipe(R.prop('entityId'), R.objOf('id'));

const useGqlEntityQuery = (
  gqlSearchQuery,
  queryParamsFromProps,
  propsOfComponent,
  alias,
  skipQuery = false,
  fetchPolicy = 'cache-first',
) => {
  const { data, ...rest } = useQuery(gqlSearchQuery, {
    alias,
    variables: queryParamsFromProps(propsOfComponent),
    fetchPolicy,
    skip: skipQuery,
  });

  const result = {
    ...data,
    ...propsOfComponent,
    ...rest,
  };

  return {
    result,
  };
};

export const useEntitySubscriptionQuery = ({
  gqlQuery,
  queryEndpointName,
  gqlSubscription,
  subscriptionEndpointName,
  queryParamsFromProps = defaultParamsFromProps,
  subscriptionParamsFromProps = defaultParamsFromProps,
  propsOfComponent,
  additionalSubParams,
  fetchPolicy,
  skipQuery = false,
  alias = 'useEntitySubscriptionQuery',
}) => {
  const { result } = useGqlEntityQuery(
    gqlQuery,
    queryParamsFromProps,
    propsOfComponent,
    alias,
    skipQuery,
    fetchPolicy,
  );

  useSubscription(gqlSubscription, {
    variables: {
      input: subscriptionParamsFromProps(result),
      ...(additionalSubParams || {}),
    },
    shouldResubscribe: true,
    onData: ({ subscriptionData }) => ({
      [queryEndpointName]: R.path(
        ['data', subscriptionEndpointName, 'document'],
        subscriptionData,
      ),
    }),
  });

  return {
    subscribeToMore: result.subscribeToMore,
    restProps: result,
  };
};
