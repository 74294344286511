import { useQuery } from '@apollo/client';
import { DEFAULT_USER_QUERY } from '../../../apollo/queries/user.js';
import { ALL_VALUES } from '../../../utils/constants/general.js';

export const useDefaultUser = (value) => {
  const { data, loading } = useQuery(DEFAULT_USER_QUERY, {
    variables: { id: value },
    skip: !value || value === ALL_VALUES,
    fetchPolicy: !value ? 'cache-only' : 'cache-first',
  });

  return {
    user: data?.user,
    loading,
  };
};
