import React from 'react';
import { Button } from 'poly-book-admin';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { APPROVE_OCR_INVOICE_PERMISSION } from 'poly-security';
import { useDispatch } from 'react-redux';
import { openModal } from 'poly-admin-ui';
import { ModalNames } from '../../../utils/constants/modals.js';
import { useSelectedInvoices } from '../../../utils/hooks/apollo.js';

export function ApproveInvoicesBtn() {
  const dispatch = useDispatch();

  const ifHasPermission = useHasUserAccessWithPermission(
    APPROVE_OCR_INVOICE_PERMISSION,
  );

  const { data, loading, selectedRows } = useSelectedInvoices();

  const onClick = () =>
    dispatch(
      openModal({
        name: ModalNames.APPROVE_INVOICES_MODAL,
      }),
    );

  return ifHasPermission ? (
    <Button
      {...data}
      loader={loading}
      disabled={!selectedRows.length}
      size="small"
      onClick={onClick}
    >
      Approve For Payment
    </Button>
  ) : null;
}

ApproveInvoicesBtn.displayName = 'ApproveInvoicesBtn';
