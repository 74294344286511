import React from 'react';
import { invoiceToTypes } from 'poly-constants';
import { Select } from 'poly-book-admin';

const invoiceToSelectOptions = [
  { value: invoiceToTypes.MAIN_ACCOUNT, label: 'Main Account' },
  { value: invoiceToTypes.BRANCH_ACCOUNT, label: 'Branch Account' },
];

export function InvoiceSelectField(props) {
  return (
    <Select
      options={invoiceToSelectOptions}
      name="PropertyStatusSelect"
      {...props}
    />
  );
}

InvoiceSelectField.displayName = 'PropertyInvoiceSelectField';
