import React from 'react';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import { openPrintWindowWithData } from 'poly-client-utils';
import { object, shape, string } from 'prop-types';
import {
  printTables,
  PrintLayout,
  printTableFileNames,
} from '../../buttons/TablePrintPDFBtn.js';
import { ITEMS_LIMIT } from '../../../utils/constants/general.js';
import { ConfirmPrintModalC } from '../../../components/modals/ConfirmPrintModalC.js';
import { setOpenModal } from '../../../state/modules/modal.js';

export function ConfirmPrintModal({ payload }) {
  const dispatch = useDispatch();

  const { data, metaData, printType } = payload;

  const text = (
    <>
      <p>Print PDF is limited to 100 line items. If you need more, hit</p>
      <p>Cancel and use the Export link to generate and XL file.</p>
    </>
  );

  const onCancel = () => dispatch(setOpenModal({ name: null }));

  const onConfirm = () => {
    dispatch(setOpenModal({ name: null }));

    openPrintWindowWithData({
      metaData,
      Layout: PrintLayout,
      Table: printTables[printType],
      data: R.take(ITEMS_LIMIT, data),
      fileName: printTableFileNames[printType],
    });
  };

  return (
    <ConfirmPrintModalC text={text} onCancel={onCancel} onConfirm={onConfirm} />
  );
}

ConfirmPrintModal.propTypes = {
  payload: shape({
    printType: string,
    // eslint-disable-next-line react/forbid-prop-types
    data: object,
    // eslint-disable-next-line react/forbid-prop-types
    metaData: object,
  }),
};
