import React from 'react';
import { useDispatch } from 'react-redux';
import { string, shape, number, func, bool } from 'prop-types';
import {
  formatDateOr,
  useHasUserAccessWithPermission,
} from 'poly-client-utils';
import { openModal, PageHeaderComponentByTabQuery } from 'poly-admin-ui';
import { formatCurrency } from 'poly-utils';
import { UPDATE_BATCH_PERMISSION } from 'poly-security';
import { EntityStatus, EntityDetail, Loader } from 'poly-book-admin';

import {
  EntityId,
  BackButton,
  HeaderFlexRow,
} from '../../../components/lib/pageHeader.js';
import { routes } from '../../../routes/constants.js';
import { ModalNames } from '../../../utils/constants/modals.js';
import { EditBtn } from '../../../containers/buttons/ActionButtons.js';
import { getBatchStatusColorAndText } from '../../../utils/statuses.js';
import { useBatchDetails } from '../apollo/useBatchSubscription.js';
import { uiAndRealAACBatchStatusesRel } from '../../../utils/constants/statuses.js';

function BatchHeaderC({
  batchId,
  invoiceCount,
  createdAt,
  fundedAt,
  approvedAt,
  status,
  total,
  onEditBatch,
  viewerHasPermission,
}) {
  return (
    <PageHeaderComponentByTabQuery key={batchId}>
      <HeaderFlexRow>
        <BackButton link={routes.BATCHES} />
        <EntityId>{batchId}</EntityId>
        <EntityStatus title="Batch" status={status} />
        <EntityDetail title="Count" value={invoiceCount} />
        <EntityDetail title="Batch Total" value={`$${total}`} />
        <EntityDetail title="Created" value={createdAt} />
        <EntityDetail title="Approved" value={approvedAt} />
        <EntityDetail title="Funded" value={fundedAt} />
      </HeaderFlexRow>
      {viewerHasPermission && <EditBtn onClick={onEditBatch} />}
    </PageHeaderComponentByTabQuery>
  );
}

BatchHeaderC.propTypes = {
  batchId: string.isRequired,
  invoiceCount: number.isRequired,
  createdAt: string.isRequired,
  fundedAt: string.isRequired,
  approvedAt: string.isRequired,
  status: shape({
    text: string.isRequired,
    color: string.isRequired,
  }).isRequired,
  total: string.isRequired,
  onEditBatch: func.isRequired,
  viewerHasPermission: bool.isRequired,
};

export function BatchHeader() {
  const dispatch = useDispatch();

  const ifHasUpdateBatchPermission = useHasUserAccessWithPermission(
    UPDATE_BATCH_PERMISSION,
  );

  const { data, loading } = useBatchDetails();

  if (loading) return <Loader />;

  const {
    batchId,
    createdAt,
    documentIds = [],
    workflow: { status, approvedAt, xeroFundedAt, total },
  } = data.batch;

  const onEditBatch = () =>
    dispatch(
      openModal({
        name: ModalNames.EDIT_BATCH,
      }),
    );

  return (
    <BatchHeaderC
      onEditBatch={onEditBatch}
      batchId={batchId}
      createdAt={formatDateOr('–')(createdAt)}
      invoiceCount={documentIds.length}
      fundedAt={formatDateOr('—')(xeroFundedAt)}
      approvedAt={formatDateOr('—')(approvedAt)}
      status={getBatchStatusColorAndText(uiAndRealAACBatchStatusesRel)(status)}
      total={formatCurrency(total)}
      viewerHasPermission={ifHasUpdateBatchPermission}
    />
  );
}
