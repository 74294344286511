import React from 'react';
import { useDispatch } from 'react-redux';
import {
  AddUpdateForm as CommonAddUpdateForm,
  useProcessState,
} from 'poly-admin-ui';
import { shape, string, array, object, oneOfType } from 'prop-types';
import { addUpdatesFormId } from './constants.js';
import { ModalFormWrapper } from '../../modal/ModalWrapper.js';
import { setOpenModal } from '../../../state/modules/modal.js';

function AddUpdateForm({ payload }) {
  const dispatch = useDispatch();

  const onCancel = () => dispatch(setOpenModal({ name: null }));

  return payload.entity._id ? (
    <CommonAddUpdateForm
      {...payload}
      successMessage="Update was created"
      formId={addUpdatesFormId}
      onCancel={onCancel}
      disableMentions
    />
  ) : null;
}

AddUpdateForm.propTypes = {
  payload: shape({
    collection: string,
    entity: shape({
      name: string,
      _id: string,
      humanReadableId: string,
    }),
    updateCacheParams: shape({
      variables: oneOfType([string, object]),
      query: oneOfType([string, array, object]),
    }),
  }),
};

export function AddUpdateModal(props) {
  const { process } = useProcessState(addUpdatesFormId);

  return (
    <ModalFormWrapper
      {...props}
      actionInProgress={!!process}
      title="Add Update"
      buttonLabel="Add Update"
      formId={addUpdatesFormId}
      Form={AddUpdateForm}
    />
  );
}
