import React from 'react';
import { Button, useNotificationContext } from 'poly-book-admin';
import { useOnSubmitSetStopSubmitting } from 'poly-admin-ui';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRows } from '../../../state/modules/selected-rows.js';
import { useBlockInvoices } from '../apollo/useBlockInvoices.js';

const blockInvoicesProcessId = 'blockInvoicesProcessId';

export function BlockInvoicesBtn() {
  const dispatch = useDispatch();

  const selectedRows = useSelector((state) => state.selectedRows);

  const { showSuccessNotification } = useNotificationContext();

  const { blockOCRInvoices, loading } = useBlockInvoices();

  const onSubmitHandler = async (selectedInvoices) => {
    await blockOCRInvoices(selectedInvoices);

    dispatch(setSelectedRows([]));
    showSuccessNotification(
      `You have blocked ${selectedInvoices.length} invoice(s)`,
    );
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    blockInvoicesProcessId,
    onSubmitHandler,
  );

  return (
    <Button
      id={blockInvoicesProcessId}
      onClick={() => onSubmit(selectedRows)}
      disabled={!selectedRows.length || loading}
      loader={loading}
      size="small"
    >
      Block
    </Button>
  );
}
