import React from 'react';
import styled from 'styled-components';
import { Button, DateRangePicker } from 'poly-book-admin';
import { func, instanceOf, shape, string } from 'prop-types';
import { FlexContainer } from 'poly-book-admin/src/lib/index.js';

import { GeneratePDFBtn } from './GeneratePDFBtn.js';
import { GenerateXLSBtn } from './GenerateXLSBtn.js';
import { PropertySelect } from '../../../property/PropertySelect/PropertySelect.js';
import { filtersNames } from '../spendDetailsUtils.js';

const { SELECTED_PROPERTY, SPEND_DETAILS_DATE_RANGE } = filtersNames;

const FiltersContainer = styled.div`
  display: flex;
  min-width: 70%;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  margin-top: 14px;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
`;

const DatePickerRangeS = styled(DateRangePicker)`
  margin: 0 30px 0 20px;
`;

const ButtonS = styled(Button)`
  margin-right: 25px;
`;

export function SpendDetailsToolbarC({
  onDateRangeChange,
  onPropertySelect,
  onApplyFilter,
  ...props
}) {
  return (
    <Container>
      <FiltersContainer>
        <FlexContainer>
          <PropertySelect
            withoutSkip
            width="250px"
            onChange={onPropertySelect}
            value={props[SELECTED_PROPERTY]}
          />
          <DatePickerRangeS
            value={props[SPEND_DETAILS_DATE_RANGE]}
            onChange={onDateRangeChange}
          />
        </FlexContainer>
      </FiltersContainer>
      <FlexContainer>
        <ButtonS onClick={onApplyFilter} size="small">
          Filter
        </ButtonS>
        <GenerateXLSBtn />
        <GeneratePDFBtn />
      </FlexContainer>
    </Container>
  );
}

SpendDetailsToolbarC.propTypes = {
  property: string,
  spendDetailsDateRange: shape({
    start: instanceOf(Date),
    end: instanceOf(Date),
  }).isRequired,
  onPropertySelect: func.isRequired,
  onDateRangeChange: func.isRequired,
  onApplyFilter: func.isRequired,
};
