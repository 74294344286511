import * as R from 'ramda';
import { SupplierPhoneTypes } from 'poly-constants';
import {
  insertParamsIntoURL,
  applySpecWithFields,
  assocByPath,
} from 'poly-utils';

import { routes } from '../../../routes/constants.js';

// convertEmails :: Object -> [Object]
const convertEmails = R.compose(
  R.filter(R.prop('email')),
  R.map(([type, email]) => ({ type, email })),
  R.toPairs,
  R.defaultTo({}),
  R.path(['company', 'emails']),
);

// withPhoneMeta :: String -> Object
const withPhoneMeta = R.when(
  R.identity,
  R.compose(
    R.of,
    R.assoc('phone', R.__, {
      type: SupplierPhoneTypes.GENERAL,
      isPrimary: true,
    }),
  ),
);

// convertPhone :: Object -> [Object]
const convertPhone = R.compose(
  withPhoneMeta,
  R.defaultTo(null),
  R.path(['company', 'phone']),
);

// convertServiceTypes :: Object -> [String]
const convertServiceTypes = R.compose(
  R.map(R.prop('value')),
  R.defaultTo([]),
  R.path(['company', 'servicesIds']),
);

const notFile = R.compose(R.not, R.is(window.File));

const omitNotFile = (path) =>
  R.when(
    R.compose(R.both(R.complement(R.isNil), notFile), R.path(path)),
    R.dissocPath(path),
  );

const checkW9File = omitNotFile(['tax', 'w9File']);
const checkWCFile = omitNotFile(['documents', 'wcFile']);
const checkGLFile = omitNotFile(['documents', 'glFile']);
const checkSCAFile = omitNotFile(['documents', 'scaFile']);
const checkSQQFile = omitNotFile(['documents', 'sqqFile']);

// when it is fetched from the server it is not File.
// So if we do not upload new file we not need to have this prop
const checkFiles = R.compose(
  checkW9File,
  checkWCFile,
  checkGLFile,
  checkSCAFile,
  checkSQQFile,
);

export const prepareSupplierAddressBeforeMutate = R.compose(
  R.dissocPath(['company', 'address', 'addressTwo']),
  applySpecWithFields({
    company: {
      addressTwo: R.pathOr('', ['company', 'address', 'addressTwo']),
    },
  }),
);

export const supplierNamesFromProps = ({ data, ownProps }) => ({
  items: R.pipe(
    R.pathOr([], ['searchSuppliers', 'hits']),
    R.reject(R.propEq('_id', ownProps.skipSupplierId)),
    R.map(
      R.applySpec({
        url: ({ _id }) =>
          insertParamsIntoURL({ supplierId: _id }, routes.SUPPLIER),
        name: R.path(['company', 'name']),
      }),
    ),
  )(data),
});

export const supplierNameQueryFromProps = ({ nameSearch }) => ({
  searchInput: {
    size: 3,
    query: {
      bool: {
        should: [
          {
            term: {
              'company.name.keyword': { value: nameSearch, boost: 3 },
            },
          },
          { term: { 'company.name': nameSearch } },
        ],
      },
    },
  },
});

/**
 * prepareClientUserFormDataForMutation :: Object -> Object
 */
export const prepareFormDataForMutation = R.compose(
  R.omit(['_id']),
  R.dissocPath(['company', 'phone']),
  R.dissocPath(['mwbe', 'isMBE']),
  checkFiles,
  assocByPath(['company', 'servicesIds'], convertServiceTypes),
  assocByPath(['company', 'emails'], convertEmails),
  assocByPath(['company', 'phones'], convertPhone),
);
