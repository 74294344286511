import React from 'react';
import downloadFile from 'downloadjs';
import { useOnSubmitSetStopSubmitting, useProcessState } from 'poly-admin-ui';
import { LinkButtonWithLoader, useNotificationContext } from 'poly-book-admin';
import { gql, useMutation } from '@apollo/client';
import { useRouterParams } from 'poly-client-routing';
import { useBatchDetails } from '../apollo/useBatchSubscription.js';

const GENERATE_BATCH_APPROVAL_FILE_MUTATION = gql`
  mutation GENERATE_BATCH_APPROVAL_FILE_MUTATION($batchId: ID!) {
    generateBatchApprovalFile(batchId: $batchId)
  }
`;

const useGenerateBatchApprovalFile = () => {
  const [mutate] = useMutation(GENERATE_BATCH_APPROVAL_FILE_MUTATION);

  return {
    generateBatchApprovalFile: (batchId) =>
      mutate({
        variables: {
          batchId,
        },
      }),
  };
};

const generateBatchApprovalFileProcessId = 'generateBatchApprovalFileProcessId';

export function GenerateBatchApprovalFileBtn() {
  const { showSuccessNotification } = useNotificationContext();

  const { process } = useProcessState(generateBatchApprovalFileProcessId);

  const { batchId } = useRouterParams(['batchId']);

  const { generateBatchApprovalFile } = useGenerateBatchApprovalFile();

  const { data } = useBatchDetails();

  const onSubmitHandler = async () => {
    const { data: approvalFile } = await generateBatchApprovalFile(batchId);

    downloadFile(approvalFile?.generateBatchApprovalFile);
    showSuccessNotification('Approval file has been attached to batch');
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    generateBatchApprovalFileProcessId,
    onSubmitHandler,
  );

  return (
    <LinkButtonWithLoader
      {...data}
      id={generateBatchApprovalFileProcessId}
      loader={process}
      onClick={onSubmit}
    >
      Export As XLS
    </LinkButtonWithLoader>
  );
}

GenerateBatchApprovalFileBtn.displayName = 'GenerateBatchApprovalFileBtn';
