import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormCreator } from 'poly-book-admin';
import { usePristineSubscribe } from 'poly-client-routing';
import {
  closeCurrentModal,
  useOnSubmitSetStopSubmitting,
  useProcessState,
} from 'poly-admin-ui';
import { editServiceTypeForm } from './constants.js';
import { createServiceTypeModalSections } from '../CreateServiceTypeModal/sections.js';
import {
  prepareFormDataForMutation,
  prepareInitialValuesForForm,
} from './utils.js';
import { useUpdateServiceType } from '../apollo/withUpdateServiceType.js';
import { ModalFormWrapper } from '../../../containers/modal/ModalWrapper.js';

function EditServiceTypeForm(props) {
  const { updateServiceType } = useUpdateServiceType();

  const { serviceType } = useSelector((state) => state.modal.payload);

  const onSubmitHandler = async (formData) => {
    await updateServiceType(
      serviceType?._id,
      prepareFormDataForMutation(formData),
    );
  };

  const { onSubmit } = useOnSubmitSetStopSubmitting(
    editServiceTypeForm,
    onSubmitHandler,
  );

  const subscriptionProps = usePristineSubscribe();

  return (
    <FormCreator
      {...props}
      {...subscriptionProps}
      onSubmit={onSubmit}
      id={editServiceTypeForm}
      initialValues={prepareInitialValuesForForm(serviceType)}
      sections={createServiceTypeModalSections}
      layout={{
        overflow: 'visible',
        card: false,
      }}
    />
  );
}

EditServiceTypeForm.displayName = 'EditServiceTypeForm';

export function EditServiceTypeModal(props) {
  const dispatch = useDispatch();

  const { process } = useProcessState(editServiceTypeForm);

  const onCancel = () => dispatch(closeCurrentModal());

  return (
    <ModalFormWrapper
      {...props}
      onCancel={onCancel}
      actionInProgress={!!process}
      title="Update Service Type"
      buttonLabel="Update"
      formId={editServiceTypeForm}
      Form={EditServiceTypeForm}
    />
  );
}
