import * as R from 'ramda';
import React from 'react';
import { assocBy } from 'poly-utils';
import {
  getSupplierCityStateAddress,
  getSupplierPhone,
  getServiceTypes,
  SuppliersStatus,
  useMapConfigToTableProps,
} from 'poly-admin-ui';
import { Loader } from 'poly-book-admin';
import { shape, string } from 'prop-types';
import { PropertySuppliersTableComp } from './PropertySuppliersTableComp.js';
import { SupplierLink } from '../../../../containers/links/Links.js';
import { usePropertySuppliers } from '../../apollo/usePropertySuppliers.js';

function SupplierLinkComp({ company, ...restProps }) {
  return <SupplierLink {...restProps} name={company?.name} />;
}

SupplierLinkComp.propTypes = {
  company: shape({
    name: string,
  }),
};

const propertySuppliersColumns = [
  ['', R.compose(SuppliersStatus, R.prop(['supplier']))],
  ['Supplier', R.compose(SupplierLinkComp, R.prop('supplier'))],
  [
    'Supplier Type',
    R.compose(
      getServiceTypes,
      R.assocPath(['company', 'services'], R.__, {}),
      R.prop('propertyServices'),
    ),
  ],
  ['City/State', R.compose(getSupplierCityStateAddress, R.prop(['supplier']))],
  ['Phone', R.compose(getSupplierPhone, R.prop('supplier'))],
];

// getPropertySuppliers :: SearchPropertiesQueryResult -> [Object]
const getPropertySuppliers = R.compose(
  R.map(assocBy('_id', R.path(['supplier', '_id']))),
  R.pathOr([], ['property', 'suppliers']),
);

export function PropertySuppliersTable() {
  const { result } = usePropertySuppliers();

  const tableProps = useMapConfigToTableProps(
    getPropertySuppliers,
    propertySuppliersColumns,
    result,
  );

  if (result.loading) return <Loader />;

  return <PropertySuppliersTableComp {...tableProps} />;
}

PropertySuppliersTable.displayName = 'UsersTable';
