import { accountInformationSection } from './accountInformationSection.js';
import { financialInformationSection } from './financialInfomationSection.js';
import { remarksSection } from './remarksSection.js';
import { peopleSection } from './peopleSection.js';
import { buildingInformationSection } from './buildinInformationSection.js';
import { alarmLockBoxInformationSection } from './alarmLockBoxInfoSection.js';

export const propertyFormSections = [
  ...accountInformationSection,
  ...buildingInformationSection,
  ...alarmLockBoxInformationSection,
  ...financialInformationSection,
  ...remarksSection,
  ...peopleSection,
];
