import React from 'react';
import { useDispatch } from 'react-redux';
import { useHasUserAccessWithPermission } from 'poly-client-utils';
import { CREATE_SERVICE_TYPE } from 'poly-security';
import { TextButton } from 'poly-book-admin';
import { setOpenModal } from '../../state/modules/modal.js';
import { ModalNames } from '../../utils/constants/modals.js';

export function AddNewServiceTypeBtn() {
  const dispatch = useDispatch();

  const ifHasPermission = useHasUserAccessWithPermission(CREATE_SERVICE_TYPE);

  const onClick = () =>
    dispatch(setOpenModal({ name: ModalNames.CREATE_SERVICE_TYPE }));

  return ifHasPermission ? (
    <TextButton onClick={onClick}>Add New</TextButton>
  ) : null;
}

AddNewServiceTypeBtn.displayName = 'AddNewServiceTypeBtn';
