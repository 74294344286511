import { gql, useMutation } from '@apollo/client';
import { useNotificationContext } from 'poly-book-admin';
import { useDispatch } from 'react-redux';
import { setOpenModal } from '../../../state/modules/modal.js';

const CREATE_SERVICE_TYPE_MUTATION = gql`
  mutation CREATE_SERVICE_TYPE_MUTATION($input: CreateServiceTypeInput!) {
    createServiceType(input: $input) {
      _id
    }
  }
`;

export const useCreateServiceType = () => {
  const dispatch = useDispatch();

  const { showSuccessNotification } = useNotificationContext();

  const [mutate] = useMutation(CREATE_SERVICE_TYPE_MUTATION, {
    alias: 'useCreateServiceType',
  });

  return {
    createServiceType: (input) =>
      mutate({ variables: { input } }).then(() => {
        dispatch(setOpenModal({ name: null }));
        showSuccessNotification('Service Type was successfully created');
      }),
  };
};
