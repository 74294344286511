import { gql } from '@apollo/client';
import { useEntitySubscriptionQuery } from 'poly-client-utils';
import * as R from 'ramda';
import { PROPERTY_SUPPLIER_RELATION_TYPES } from 'poly-constants';
import { useRouterParams } from 'poly-client-routing';

const { OCR_INVOICE } = PROPERTY_SUPPLIER_RELATION_TYPES;

const propertySupplierFields = gql`
  fragment propertySupplierFields on PropertySupplier {
    propertyServices {
      name
    }
    relationTypes
    supplier {
      _id
      status
      company {
        name
        phones {
          phone
          type
        }
        address {
          address_parts {
            locality
            administrative_area_level_1
          }
        }
      }
      type
    }
  }
`;

const PROPERTY_SUPPLIERS = gql`
  query PROPERTY_SUPPLIERS($id: ID!, $input: PropertySuppliersInput) {
    property(id: $id) {
      _id
      suppliers(input: $input) {
        ...propertySupplierFields
      }
    }
  }
  ${propertySupplierFields}
`;

const PROPERTY_SUPPLIERS_SUB = gql`
  subscription PROPERTY_SUB(
    $input: SingleDocSubInput!
    $suppliersInput: PropertySuppliersInput
  ) {
    propertyChanged(input: $input) {
      id
      document {
        _id
        suppliers(input: $suppliersInput) {
          ...propertySupplierFields
        }
      }
    }
  }

  ${propertySupplierFields}
`;

export const usePropertySuppliers = () => {
  const { propertyId } = useRouterParams(['propertyId']);

  const { restProps } = useEntitySubscriptionQuery({
    gqlQuery: PROPERTY_SUPPLIERS,
    gqlSubscription: PROPERTY_SUPPLIERS_SUB,
    queryEndpointName: 'property',
    subscriptionEndpointName: 'propertyChanged',
    queryParamsFromProps: R.applySpec({
      id: R.prop('entityId'),
      input: { relationType: R.always(OCR_INVOICE) },
    }),
    propsOfComponent: { entityId: propertyId },
    additionalSubParams: { suppliersInput: { relationType: OCR_INVOICE } },
  });

  return {
    result: restProps,
  };
};
