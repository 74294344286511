import { commonSortQuery } from 'poly-client-utils';
import {
  collectionNames,
  DESC_SORT_ORDER,
  peopleStatuses,
  SupplierStatuses,
} from 'poly-constants';
import {
  SUPPLIER_PEOPLE_QUERY,
  SUPPLIER_UPDATES_QUERY,
  entities,
  usePagination,
  useEntityUpdates,
  useEntityFiles,
  useContactUsersSearchByQuery,
} from 'poly-admin-ui';
import { useSupplierAccountSubscription } from './useSupplierAccount.js';
import { useOcrInvoicesBySearch } from '../../invoice/apollo/useOcrInvoicesBySearch.js';
import { useSuppliersBySearch } from './useSuppliersBySearch.js';

const useSuppliersByStatus = (status) => {
  const supplierProps = useSuppliersBySearch({ status });

  return {
    ...supplierProps,
  };
};

export function usePrefetchSuppliers() {
  const activeSuppliersProps = useSuppliersByStatus(SupplierStatuses.ACTIVE);
  const blockedSuppliersProps = useSuppliersByStatus(SupplierStatuses.BLOCKED);

  return {
    ...activeSuppliersProps,
    ...blockedSuppliersProps,
  };
}

const usePrefetchSupplierInvoices = (supplier) => {
  const { pagination } = usePagination();

  const { loading, result } = useOcrInvoicesBySearch({
    sort: commonSortQuery(['invoiceId'])(DESC_SORT_ORDER),
    pagination,
    query: { match: { supplierId: supplier?._id } },
    skipQuery: !supplier?._id,
  });

  return {
    loading,
    result,
  };
};

const useSupplierPeople = (entity) => {
  const activeSuppliersContactsQueryResult = useContactUsersSearchByQuery({
    gqlSearchQuery: SUPPLIER_PEOPLE_QUERY,
    status: peopleStatuses.ACTIVE,
    entity,
  });

  const inactiveSuppliersContactsQueryResult = useContactUsersSearchByQuery({
    gqlSearchQuery: SUPPLIER_PEOPLE_QUERY,
    status: peopleStatuses.INACTIVE,
    entity,
  });

  return {
    activeSuppliersContactsQueryResult,
    inactiveSuppliersContactsQueryResult,
  };
};

export const usePrefetchSupplierCard = () => {
  const { result } = useSupplierAccountSubscription();

  const entity = { _id: result?.supplier?._id, name: entities.SUPPLIER };

  useEntityFiles({
    collection: collectionNames.suppliers,
    entity,
  });

  useEntityUpdates({
    query: SUPPLIER_UPDATES_QUERY,
    entity,
  });

  const { result: prefetchedSupplierInvoices } = usePrefetchSupplierInvoices(
    result?.supplier,
  );

  const {
    activeSuppliersContactsQueryResult,
    inactiveSuppliersContactsQueryResult,
  } = useSupplierPeople(entity);

  return {
    loading: result?.loading,
    prefetchedSupplierInvoices,
    supplierAccount: result,
    activeSuppliersContactsQueryResult,
    inactiveSuppliersContactsQueryResult,
  };
};
