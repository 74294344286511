import { createAction, handleAction } from 'redux-actions';
import { filtersNames } from './spendDetailsUtils.js';

const { SELECTED_PROPERTY, SPEND_DETAILS_DATE_RANGE } = filtersNames;

export const setFilters = createAction('SET_FILTERS');

export const filtersReducer = handleAction(
  setFilters,
  ({ filters }, { payload }) => ({ ...filters, ...payload }),
  {
    [SELECTED_PROPERTY]: null,
    [SPEND_DETAILS_DATE_RANGE]: {
      startDate: null,
      endDate: null,
    },
  },
);
