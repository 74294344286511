import React from 'react';
import * as R from 'ramda';
import { DatePicker, Input, Checkbox } from 'poly-book-admin';
import { SupplierSelect } from 'poly-admin-ui';
import { OCRInvoiceStatuses } from 'poly-constants';
import { string } from 'prop-types';
import {
  EntityAddressComp,
  InvoiceFooter,
  LinesHeader,
  UpdateInvoiceLineField,
} from './components.js';
import {
  OCRInvoiceFinancialStatusDisabledSelect,
  OCRInvoiceStatusSelect,
} from '../../../../components/selects/OCRInvoiceStatusSelect.js';
import {
  usePropertyAddressLogic,
  useSupplierAddressLogic,
} from '../common/hooks.js';
import { UserSelect } from '../../../../components/selects/UserSelect/UserSelect.js';
import { OCRInvoicePropertySelect } from '../../../property/PropertySelect/PropertySelect.js';
import {
  invoiceNumberRules,
  lineFieldValidationRules,
  validateLineField,
} from '../../../../utils/forms/validators.js';

const inputWidth = 'calc(25% - 15px)';

function DatePickerDisabled(props) {
  return <DatePicker {...props} disabled />;
}

// getAdditionalSelectProps :: String -> Object
const getAdditionalSelectProps = (status) => ({
  disabled: status === OCRInvoiceStatuses.PAID,
  includeAllOption: false,
});

function OCRInvoicePropertySelectComp(props) {
  return (
    <OCRInvoicePropertySelect
      {...props}
      {...getAdditionalSelectProps(props.status)}
    />
  );
}

OCRInvoicePropertySelectComp.propTypes = {
  status: string,
};

function SupplierSelectComp(props) {
  return (
    <SupplierSelect {...props} {...getAdditionalSelectProps(props.status)} />
  );
}

SupplierSelectComp.propTypes = {
  status: string,
};

export function PropertyAddressComp(props) {
  const addressProps = usePropertyAddressLogic(props);

  return <EntityAddressComp {...addressProps} />;
}

export function SupplierAddressComp(props) {
  const addressProps = useSupplierAddressLogic(props);

  return <EntityAddressComp {...addressProps} />;
}

// isPastOrToday :: Date -> Bool
const isPastOrToday = R.lte(R.__, new Date());

const isDateGte = (gteDate) =>
  R.compose(R.gte(R.__, gteDate), R.constructN(1, Date));
const isDateLte = (gteDate) =>
  R.compose(R.lte(R.__, gteDate), R.constructN(1, Date));

const layoutPlaceholder = (row) => ({
  layout: { row, width: inputWidth },
  field: {
    name: 'empty field',
    Component: () => <div />,
  },
});

export const invoiceEditSections = [
  {
    id: 'invoiceEditSections',
    layout: {
      column: 1,
      margin: 0,
    },
    order: 1,
    fields: [
      {
        layout: { row: 1, width: inputWidth },
        label: 'Invoice #',
        field: {
          name: 'invoiceNumber',
          Component: Input,
        },
        validators: invoiceNumberRules,
        required: true,
      },
      {
        layout: { row: 1, width: inputWidth },
        label: 'Status',
        field: {
          name: 'status',
          Component: (props) => (
            <OCRInvoiceStatusSelect
              {...props}
              omitOptions={[
                OCRInvoiceStatuses.VOID,
                OCRInvoiceStatuses.XTRACTA_EXPORT,
                OCRInvoiceStatuses.OCR_ERROR,
                OCRInvoiceStatuses.SCANNING,
                OCRInvoiceStatuses.APPROVED,
              ]}
            />
          ),
        },
        required: true,
      },
      {
        layout: { row: 1, width: inputWidth },
        label: 'FinancialStatus',
        field: {
          name: 'status',
          Component: OCRInvoiceFinancialStatusDisabledSelect,
        },
        required: true,
      },
      layoutPlaceholder(1),
      {
        layout: { row: 2, width: inputWidth },
        label: 'Invoice Date',
        field: {
          name: 'invoiceDate',
          Component: DatePicker,
        },
        validators: [
          [isPastOrToday, "Invoice date can't be in the future"],
          [
            isDateGte(new Date('01/01/1900')),
            "Invoice date can't be earlier than 1900 year",
          ],
        ],
        required: true,
      },
      {
        layout: { row: 2, width: inputWidth },
        label: 'Receive Date',
        field: {
          name: 'receiveDate',
          Component: DatePickerDisabled,
        },
      },
      {
        layout: { row: 2, width: inputWidth },
        label: 'Due Date',
        field: {
          name: 'dueDate',
          Component: DatePicker,
        },
        validators: [
          [
            isDateGte(new Date('01/01/2017')),
            "Due date can't be earlier than 2017 year",
          ],
          [
            isDateLte(new Date('12/31/2099')),
            "Due date can't be later than 2099",
          ],
        ],
        required: true,
      },
      layoutPlaceholder(2),
      {
        layout: { row: 3, width: inputWidth },
        label: 'AAC Manager',
        field: {
          name: 'managerId',
          Component: UserSelect,
        },
        required: true,
      },
      {
        label: 'Property',
        layout: { row: 4, width: inputWidth },
        field: {
          withFormData: true,
          name: 'propertyId',
          Component: OCRInvoicePropertySelectComp,
        },
        required: true,
      },
      {
        id: 'propertyId',
        layout: { row: 4, padding: '25px 0 0 0', width: 'calc(75% - 5px)' },
        field: {
          name: 'propertyId',
          withFormData: true,
          Component: PropertyAddressComp,
        },
      },
      {
        layout: { row: 5, width: inputWidth },
        label: 'Supplier',
        field: {
          withFormData: true,
          name: 'supplierId',
          Component: SupplierSelectComp,
        },
        required: true,
      },
      {
        id: 'supplierId',
        layout: { row: 5, padding: '25px 0 0 0', width: 'calc(75% - 5px)' },
        field: {
          name: 'supplierId',
          withFormData: true,
          Component: SupplierAddressComp,
        },
      },
      {
        label: 'Account Number',
        layout: { row: 6, width: inputWidth },
        field: {
          name: 'supplierOuterContractId',
          Component: Input,
        },
      },
      {
        layout: { row: 6, padding: '25px 0 0 0', width: 'calc(75% - 5px)' },
        field: {
          name: 'isNotRecurringInvoice',
          Component: (props) => (
            <Checkbox {...props} label="This is not recurring invoice" />
          ),
        },
      },
      {
        id: 'lines',
        order: 1,
        label: <LinesHeader />,
        layout: { row: 7, flex: '1 5', overflow: 'visible' },
        field: {
          name: 'lines',
          isArrayField: true,
          Component: UpdateInvoiceLineField,
          isEqual: R.equals,
        },
        validateFunction: validateLineField,
        validators: lineFieldValidationRules,
      },
      {
        id: 'totalInfo',
        order: 1,
        layout: { row: 8, padding: 0 },
        field: {
          name: 'lines',
          withFormData: true,
          Component: InvoiceFooter,
        },
      },
    ],
  },
];
