import React from 'react';
import * as R from 'ramda';
import { array, oneOfType, string } from 'prop-types';
import {
  commonSortQuery,
  pathOrNothingUI,
  formatDateProp,
  useSortableTable,
  useHighlightMatchesBySearch,
} from 'poly-client-utils';
import { isNilOrEmpty } from 'poly-utils';
import {
  ASC_SORT_ORDER,
  OCRInvoiceStatuses,
  NOTHING_UI_STRING,
} from 'poly-constants';
import { Loader, useInfiniteTable } from 'poly-book-admin';
import { useMapConfigToTableProps } from 'poly-admin-ui';
import {
  InvoiceLink,
  InvoicePDFLink as InvoicePDFLinkC,
  InvoiceSupplierLink,
} from '../../../../containers/links/Links.js';
import { QueueTableC } from './QueueTableC.js';
import { formatCurrencyProp } from '../../../../utils/foramatters/index.js';
import { useQueueInvoices } from '../../apollo/useQueueInvoicesBySearch.js';
import { useFetchMoreOCRInvoices } from '../../apollo/useFetchMoreOcrInvoices.js';
import { PickupInvoiceBtn } from '../../../../containers/buttons/PickupInvoiceBtn.js';
import { useSelectableTableLogic } from '../../../../utils/hooks/table.js';

const NOT_INVOICE_NUMBER_MSG = 'UNRECOGNIZED';

function InvoicePDFLink({ invoiceNumber, ...restProps }) {
  return (
    <InvoicePDFLinkC
      {...restProps}
      invoiceNumber={
        isNilOrEmpty(invoiceNumber) ? NOT_INVOICE_NUMBER_MSG : invoiceNumber
      }
    />
  );
}

InvoicePDFLink.propTypes = {
  invoiceNumber: oneOfType([string, array]),
};

const InvoicePickupLink = R.ifElse(
  R.pathEq(['workflow', 'status'], OCRInvoiceStatuses.NEW),
  (invoice) => <PickupInvoiceBtn {...{ invoice }} />,
  pathOrNothingUI(['verifiedBy', 'fullName']),
);

const InvoiceSupplierLinkOrNothingUI = R.ifElse(
  R.path(['supplier', '_id']),
  InvoiceSupplierLink,
  R.always(NOTHING_UI_STRING),
);

const tableConfig = [
  ['Invoice ID', InvoiceLink, commonSortQuery(['invoiceId'])],
  [
    'Supplier',
    InvoiceSupplierLinkOrNothingUI,
    commonSortQuery(['supplier', 'company', 'name', 'keyword']),
  ],
  ['Invoice #', InvoicePDFLink],
  ['Inv Date', formatDateProp('invoiceDate'), commonSortQuery(['invoiceDate'])],
  ['Due Date', formatDateProp('dueDate'), commonSortQuery(['dueDate'])],
  ['Amount', formatCurrencyProp('total'), commonSortQuery(['total'])],
  ['Pickup', InvoicePickupLink],
];

// getInvoices :: SearchQueueInvoicesQueryResult -> [Invoice]
const getInvoices = R.pathOr([], ['searchOCRInvoices', 'hits']);

export function QueueTable() {
  const { sort, ...tableSortingProps } = useSortableTable({
    tableConfig,
    column: 1,
    order: ASC_SORT_ORDER,
  });

  const { loading, result } = useQueueInvoices(sort);

  const { loadMore, isFetchingMore } = useFetchMoreOCRInvoices({
    data: result,
    fetchMore: result.fetchMore,
  });

  const { onScroll } = useInfiniteTable(loadMore);

  const { rows, ...tableProps } = useMapConfigToTableProps(
    getInvoices,
    tableConfig,
    result,
  );

  const { highlightedRows } = useHighlightMatchesBySearch(
    R.identity,
    [['supplier', 'company', 'name'], ['invoiceNumber'], ['invoiceId']],
    rows,
  );

  const selectableProps = useSelectableTableLogic(highlightedRows, true);

  if (loading) return <Loader />;

  return (
    <QueueTableC
      {...tableSortingProps}
      {...tableProps}
      {...selectableProps}
      rows={highlightedRows}
      isFetchingMore={isFetchingMore}
      onScroll={onScroll}
    />
  );
}

QueueTable.displayName = 'QueueTable';
