import React from 'react';
import styled from 'styled-components';
import { shape, string, bool, func } from 'prop-types';
import { FooterContainer, Button, getThemeFont } from 'poly-book-admin';
import { CenterContent } from '../../../../components/lib/centerContent.js';

const TotalTitle = styled.span`
  font-family: Lato;
  font-weight: ${getThemeFont(['semibold'])};
  font-size: 12px;
  margin-right: 30px;
  letter-spacing: 1px;
`;

const TotalValue = styled.span`
  font-family: Lato;
  font-weight: ${getThemeFont(['semibold'])};
  font-size: 14px;
  color: #3e4c5b;
`;

export function CreateBatchInvoicesFooterC({ btnProps, total }) {
  return (
    <FooterContainer>
      <Button {...btnProps}>Create Batch</Button>
      <CenterContent>
        <TotalTitle>Total</TotalTitle>
        <TotalValue>{total}</TotalValue>
      </CenterContent>
    </FooterContainer>
  );
}

CreateBatchInvoicesFooterC.displayName = 'CreateBatchInvoicesFooter';
CreateBatchInvoicesFooterC.propTypes = {
  btnProps: shape({
    size: string,
    loader: bool,
    onClick: func,
    disabled: bool,
  }).isRequired,
  total: string.isRequired,
};
