import React from 'react';
import { bool, number, object, oneOfType, shape, string } from 'prop-types';
import { CardsWrapper, SectionsWrapper, SideSection } from 'poly-book-admin';
import { useHasUserAccessToSupplierPrivateFields } from 'poly-client-utils';
import { supplierTypes } from 'poly-constants';
import {
  SupplierOfficeHoursSection,
  supplierAccountPropsTypes,
  SupplierRemitInfoSection,
  RemarksSection,
} from 'poly-admin-ui';

import { GeneralInfoSection } from './sections/GeneralInfoSection.js';
import { RatesSection } from './sections/RatesSection.js';
import { FinancialSection } from './sections/FinancialSection.js';
import { BankACHSection } from './sections/BankACHSection.js';
import { DocumentsSection } from './sections/DocumentsSection.js';

export function SupplierAccountTabComp({
  type,
  generalInfo,
  remarks,
  financialInfo,
  bankACH,
  documentsInfo,
  ratesInfo,
  mwbe,
  remitInfo,
}) {
  const { hasUserTaxPrivateFieldsAccess, hasUserBankPrivateFieldsAccess } =
    useHasUserAccessToSupplierPrivateFields();

  return (
    <CardsWrapper>
      <SectionsWrapper>
        <SideSection>
          <GeneralInfoSection {...{ type, mwbe, ...generalInfo }} />
          <SupplierOfficeHoursSection {...generalInfo} />
          {type === supplierTypes.SUBCONTRACTOR && (
            <RatesSection {...ratesInfo} />
          )}
        </SideSection>
        <SideSection>
          <RemarksSection {...{ remarks }} />
          <SupplierRemitInfoSection {...remitInfo} />
          <FinancialSection
            {...financialInfo}
            hasUserTaxPrivateFieldsAccess={hasUserTaxPrivateFieldsAccess}
          />
          <BankACHSection
            {...bankACH}
            hasUserBankPrivateFieldsAccess={hasUserBankPrivateFieldsAccess}
          />
          {type === supplierTypes.SUBCONTRACTOR && (
            <DocumentsSection {...documentsInfo} />
          )}
        </SideSection>
      </SectionsWrapper>
    </CardsWrapper>
  );
}

SupplierAccountTabComp.propTypes = {
  type: string.isRequired,
  generalInfo: shape(supplierAccountPropsTypes.generalInfoPropTypes).isRequired,
  ...supplierAccountPropsTypes.remarksPropTypes,
  financialInfo: shape(supplierAccountPropsTypes.financialPropTypes).isRequired,
  bankACH: shape(supplierAccountPropsTypes.bankACHPropTypes).isRequired,
  ratesInfo: shape({
    hourly: number,
    overTime: number,
    weekend: number,
    trip: number,
  }).isRequired,
  mwbe: shape({
    minority: string,
    isWBE: bool,
  }).isRequired,
  remitInfo: shape({
    name: string,
    address: oneOfType([object, string]).isRequired,
  }),
};
