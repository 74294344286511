import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AddPeopleForm as CommonAddPeopleForm,
  useProcessState,
} from 'poly-admin-ui';
import { useNotificationContext } from 'poly-book-admin';
import { ModalFormWrapper } from '../../../containers/modal/ModalWrapper.js';
import { setOpenModal } from '../../../state/modules/modal.js';
import { addPeopleFormId } from '../constants.js';

function AddPeopleForm(props) {
  const dispatch = useDispatch();

  const notificationContext = useNotificationContext();

  const { payload } = useSelector((state) => state.modal);

  const onCancel = () => dispatch(setOpenModal({ name: null }));

  return (
    <CommonAddPeopleForm
      {...props}
      {...payload}
      {...notificationContext}
      onCancel={onCancel}
      successMessage="Contact was successfully created"
      formId={addPeopleFormId}
    />
  );
}

export function AddPeopleModal(props) {
  const { process } = useProcessState(addPeopleFormId);

  return (
    <ModalFormWrapper
      {...props}
      actionInProgress={!!process}
      title="Add Contact"
      buttonLabel="Create"
      formId={addPeopleFormId}
      Form={AddPeopleForm}
    />
  );
}
