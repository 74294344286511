import * as R from 'ramda';
import { useRouterParams } from 'poly-client-routing';
import { useEntitySubscriptionQuery } from 'poly-client-utils';
import { gql, useQuery } from '@apollo/client';
import { commonAddressFields } from 'poly-admin-ui';

const supplierAccountFields = gql`
  fragment supplierAccountFields on Supplier {
    _id
    type
    status
    remarks
    isPrivate
    cardNumber
    service_24_7
    officeHours {
      day
      isActive
      startTime
      endTime
    }
    misc {
      source
    }
    mwbe {
      minority
      isWBE
    }
    finance {
      acceptsCreditCard
      terms
    }
    tax {
      is1099
      id
      name
      classification
      address {
        ...commonAddressFields
      }
      addressTwo
      socialSecurityNumber
      w9File {
        url
        fileName
      }
      exempt
    }
    rates {
      hourly
      overTime
      trip
      weekend
    }
    bank {
      routingNumber
      accountNumber
      remittanceEmail
      payByACH
    }

    documents {
      wcExp
      glExp
      wcFile {
        fileName
        fileType
        url
      }

      glFile {
        fileName
        fileType
        url
      }

      scaFile {
        fileName
        fileType
        url
      }

      sqqFile {
        fileName
        fileType
        url
      }
    }

    company {
      name
      dba
      fax
      addressTwo
      website
      address {
        ...commonAddressFields
      }
      phones {
        phone
        type
        isPrimary
        legacyFormat
      }
      emails {
        email
        type
      }
      services {
        _id
        name
      }
    }
    masterSupplier {
      _id
      company {
        name
      }
    }
    remit {
      name
      address {
        ...commonAddressFields
      }
      addressTwo
    }
  }
  ${commonAddressFields}
`;

const SUPPLIER_ACCOUNT = gql`
  query SUPPLIER_ACCOUNT($supplierId: ID!) {
    supplier(id: $supplierId) {
      ...supplierAccountFields
    }
  }
  ${supplierAccountFields}
`;
const SUPPLIER_ACCOUNT_SUB = gql`
  subscription SUPPLIER_ACCOUNT_SUB($input: SingleDocSubInput!) {
    supplierChanged(input: $input) {
      id
      document {
        ...supplierAccountFields
      }
    }
  }
  ${supplierAccountFields}
`;

export const useSupplierAccount = () => {
  const { supplierId } = useRouterParams(['supplierId']);

  const { data, loading } = useQuery(SUPPLIER_ACCOUNT, {
    variables: { supplierId },
  });

  return {
    supplier: R.propOr({}, 'supplier', data),
    loading,
    data,
  };
};

export const useSupplierAccountSubscription = () => {
  const { supplierId } = useRouterParams(['supplierId']);

  const { restProps } = useEntitySubscriptionQuery({
    gqlQuery: SUPPLIER_ACCOUNT,
    queryEndpointName: 'supplier',
    gqlSubscription: SUPPLIER_ACCOUNT_SUB,
    subscriptionEndpointName: 'supplierChanged',
    queryParamsFromProps: R.compose(
      R.objOf('supplierId'),
      R.path(['supplierId']),
    ),
    subscriptionParamsFromProps: R.compose(
      R.objOf('id'),
      R.path(['supplierId']),
    ),
    propsOfComponent: { supplierId },
    alias: 'useSupplierAccountSubscription',
  });

  return { result: restProps };
};
