import styled from 'styled-components';
import { getThemeColor } from 'poly-book-admin';

export const Report1099PagePlaceholder = styled.div.attrs(() => ({
  children: 'Please select your Filters',
  'data-testid': 'report-1099-placeholder',
}))`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 20px;
  color: ${getThemeColor(['primaryLighter1'])};
`;
