import React from 'react';
import * as R from 'ramda';
import { string } from 'prop-types';
import { PageHeaderContainer } from 'poly-book-admin';
import { useRouterQuery } from 'poly-client-routing';

const fileTabHeaderStyles = `
  box-shadow: none;
`;

const fileTabContentStyles = `
  margin: 0 70px 0 55px;
  max-width: 1424px;
  min-width: 955px;
  padding-right: 40px;
  width: 100%;
`;

export function PageHeaderComponentByTabQuery({
  contentStyles,
  headerStyles,
  ...props
}) {
  const { tab = '' } = useRouterQuery(['tab']);
  const isFileTab = R.includes('-files', tab);

  const mergedHeaderStyles = R.concat(
    isFileTab ? fileTabHeaderStyles : '',
    headerStyles,
  );
  const mergedContentStyles = R.concat(
    isFileTab ? fileTabContentStyles : '',
    contentStyles,
  );

  return (
    <PageHeaderContainer
      headerStyles={mergedHeaderStyles}
      contentStyles={mergedContentStyles}
      {...props}
    />
  );
}

PageHeaderComponentByTabQuery.propTypes = {
  contentStyles: string,
  headerStyles: string,
};

PageHeaderComponentByTabQuery.defaultProps = {
  contentStyles: '',
  headerStyles: '',
};
