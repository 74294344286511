import React from 'react';
import styled from 'styled-components';
import { string, bool, func } from 'prop-types';
import {
  UserAvatar,
  PopUp,
  Text,
  PopUpMenuItem,
  DefaultPopUpContentWrapper,
} from 'poly-book-admin';

const PopUpS = styled(PopUp)`
  margin-right: 20px;
`;

const PopUpContentWrapperS = styled(DefaultPopUpContentWrapper)`
  top: 37px;
`;

const UserName = styled(Text)`
  padding-left: 10px;
`;

const UserMenuItem = styled(PopUpMenuItem)`
  cursor: default;
  display: flex;

  &:hover {
    background-color: #fff;
  }
`;

export function UserPopUpC({
  onLogout,
  initials,
  close,
  toggleOpen,
  isOpen,
  fullName,
}) {
  return (
    <PopUpS
      onBlur={close}
      isOpen={isOpen}
      trigger={
        <UserAvatar key={fullName} onClick={toggleOpen}>
          {initials}
        </UserAvatar>
      }
    >
      <PopUpContentWrapperS>
        <UserMenuItem>
          <UserAvatar>{initials}</UserAvatar>
          <UserName size="15px">{fullName}</UserName>
        </UserMenuItem>
        <PopUpMenuItem onClick={onLogout}>
          <Text size="14px">Log Out</Text>
        </PopUpMenuItem>
      </PopUpContentWrapperS>
    </PopUpS>
  );
}

UserPopUpC.propTypes = {
  initials: string.isRequired,
  fullName: string.isRequired,
  onLogout: func.isRequired,
  close: func.isRequired,
  toggleOpen: func.isRequired,
  isOpen: bool.isRequired,
};
