import * as R from 'ramda';
import { gql } from '@apollo/client';
import { hasUserAccessWithAi, createAccessItem } from 'poly-security';

export const CURRENT_USER_ACCESS_ITEMS = gql`
  query CURRENT_USER_ACCESS_ITEMS {
    me {
      _id
      accessItems {
        permission
        types {
          clientApp {
            equals
            flippedContains
          }
          createdBy {
            equals
            flippedContains
          }
          clientId {
            equals
            flippedContains
          }
          propertyId {
            equals
            flippedContains
          }
          technicianId {
            equals
            flippedContains
          }
          projectType {
            equals
            flippedContains
          }
        }
      }
    }
  }
`;

// _cleanUpUsersAccessItems :: AccessItemTypes -> User -> User
export const _cleanUpUsersAccessItems = (typesByProps) =>
  R.compose(
    R.over(
      R.lensProp('accessItems'),
      R.map(
        R.compose(
          R.over(
            R.lensProp('types'),
            R.ifElse(
              R.pipe(typesByProps, R.isEmpty),
              R.always({}),
              R.compose(R.reject(R.isNil), R.omit(['__typename'])),
            ),
          ),
          R.omit(['__typename']),
        ),
      ),
    ),
  );

// _shouldRenderNothingByPermission :: Props -> AccessItemPermission -> Boolean
//   Props = Object
export const _shouldRenderNothingByPermission = R.curry((props, permission) =>
  R.compose(
    R.not,
    R.ifElse(
      R.has('me'),
      R.converge(hasUserAccessWithAi, [
        R.compose(createAccessItem(permission), props.typesByProps),
        R.compose(_cleanUpUsersAccessItems(props.typesByProps), R.prop('me')),
      ]),
      R.F,
    ),
  )(props),
);
