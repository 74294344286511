import * as R from 'ramda';
import { toDate, forceTitleCase } from 'poly-utils';
import { NOTHING_UI_STRING } from 'poly-constants';

import { ITEMS_PER_SCROLL } from './constants/general.js';

// toISO :: Date -> String
const toISO = (date) => date.toISOString();

// whenNotEmpty :: (Any -> Any) -> Any
const whenNotEmpty = R.when(R.identity);

// serializeDateRange :: Object -> Object
export const serializeDateRange = R.evolve({
  start: whenNotEmpty(toISO),
  end: whenNotEmpty(toISO),
});

// deserializeDateRange :: Object -> Object
export const deserializeDateRange = R.pipe(
  R.evolve({ start: whenNotEmpty(toDate), end: whenNotEmpty(toDate) }),
  R.dissoc('__typename'),
);

// notAllLoaded :: Number -> Boolean
export const notAllLoaded = (itemsCount) => itemsCount % ITEMS_PER_SCROLL === 0;

/**
 * propToTitleCase :: String -> Object -> String
 */
export const propToTitleCase = (prop) =>
  R.compose(forceTitleCase, String, R.defaultTo(''), R.prop(prop));

/**
 * getPropOrNothingUI :: String -> String
 */
export const getPropOrNothingUI = (propName) =>
  R.pipe(R.prop(propName), R.defaultTo(NOTHING_UI_STRING));
