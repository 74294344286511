import React, { useState } from 'react';
import { func, string, array, oneOfType, object } from 'prop-types';
import { TextButton } from 'poly-book-admin';
import { EXPORT_XLS_CAPTION } from 'poly-constants';

export function TableExportXLSBtn({ data, xlsGenerator, style, btnName }) {
  const [isLoading, setIsLoading] = useState(false);

  const onClick = () => {
    setIsLoading(true);
    Promise.resolve(data)
      .then(xlsGenerator)
      .finally(() => setIsLoading(false));
  };

  return (
    <TextButton loading={isLoading} onClick={onClick} style={style}>
      {btnName || EXPORT_XLS_CAPTION}
    </TextButton>
  );
}

TableExportXLSBtn.displayName = 'TableExportXLSBtn';

TableExportXLSBtn.propTypes = {
  btnName: string,
  // eslint-disable-next-line react/forbid-prop-types
  data: array.isRequired,
  xlsGenerator: func.isRequired,
  style: oneOfType([string, object]),
};
