import * as R from 'ramda';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useUpdateQueryParams, useLocation } from 'poly-client-routing';
import {
  Heading,
  WhiteCard,
  FooterContainer,
  DefaultBodyWrapper,
  PageHeaderContainer,
} from 'poly-book-admin';

import { DefaultFiltersQuery } from './constants.js';
import { QueueTable } from './QueueTable/QueueTable.js';
import { BlockInvoicesBtn } from './BlockInvoicesBtn.js';
import { OCRInvoiceStatusSelectWithRouter } from '../../../components/selects/OCRInvoiceStatusSelect.js';
import { ManagerSelectWithRouter } from '../../../components/selects/UserSelect/UserSelect.js';
import { PropertySelectWithRouter } from '../../property/PropertySelect/PropertySelect.js';
import { SearchInput } from '../../../containers/SearchInput.js';
import { FlexContainer } from '../InvoicePage/common/styles.js';

const WhiteCardS = styled(WhiteCard)`
  height: calc(100% - 90px);
`;

const FiltersContainerS = styled(FlexContainer)`
  margin-left: 25px;
  & > div {
    margin-right: 15px;
  }
`;

function PageHeader() {
  const updateQueryParams = useUpdateQueryParams();
  const location = useLocation();
  useEffect(() => {
    if (R.isEmpty(location.search)) {
      updateQueryParams(DefaultFiltersQuery);
    }
  }, []);

  return (
    <PageHeaderContainer>
      <FlexContainer>
        <Heading.h2>Invoice Queue</Heading.h2>
        <FiltersContainerS>
          <OCRInvoiceStatusSelectWithRouter />
          <PropertySelectWithRouter />
          <ManagerSelectWithRouter />
        </FiltersContainerS>
      </FlexContainer>
      <SearchInput />
    </PageHeaderContainer>
  );
}

export function QueuePage() {
  return (
    <>
      <PageHeader />
      <DefaultBodyWrapper>
        <WhiteCardS>
          <QueueTable />
        </WhiteCardS>
        <FooterContainer>
          <BlockInvoicesBtn />
        </FooterContainer>
      </DefaultBodyWrapper>
    </>
  );
}
