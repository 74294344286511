import React from 'react';
import {
  PageTabs as PageTabsImp,
  useCheckTabsPermissions,
} from 'poly-admin-ui';
import { arrayOf, array, oneOfType } from 'prop-types';

export function PageTabs({ tabs: tabsFromProps, ...restProps }) {
  const { tabs } = useCheckTabsPermissions({ tabs: tabsFromProps });

  return <PageTabsImp {...restProps} tabs={tabs} />;
}

PageTabs.propTypes = {
  tabs: arrayOf(oneOfType([array])),
};
