import { gql, useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { useNotificationContext } from 'poly-book-admin';
import { setOpenModal } from '../../../state/modules/modal.js';

const UPDATE_SERVICE_TYPE_MUTATION = gql`
  mutation UPDATE_SERVICE_TYPE_MUTATION(
    $id: ID!
    $input: UpdateServiceTypeInput!
  ) {
    updateServiceType(id: $id, input: $input) {
      _id
    }
  }
`;

export const useUpdateServiceType = () => {
  const dispatch = useDispatch();
  const { showSuccessNotification } = useNotificationContext();

  const [mutate] = useMutation(UPDATE_SERVICE_TYPE_MUTATION, {
    alias: 'useUpdateServiceType',
  });

  return {
    updateServiceType: (id, input) =>
      mutate({ variables: { id, input } }).then(() => {
        dispatch(setOpenModal({ name: null }));
        showSuccessNotification('Service Type was successfully updated');
      }),
  };
};
