import React from 'react';
import * as R from 'ramda';
import { gql } from '@apollo/client';
import { Loader } from 'poly-book-admin';
import { useRouterParams } from 'poly-client-routing';
import { collectionNames } from 'poly-constants';
import {
  useMasterSupplierDetailsQuery,
  commonUpdateFields,
  entities,
} from 'poly-admin-ui';

import { UpdatesTab } from '../../containers/tabs/UpdatesTab.js';

export const MASTER_SUPPLIER_UPDATES = gql`
  query MASTER_SUPPLIER_UPDATES(
    $id: ID!
    $documentUpdatesInput: DocumentUpdatesInput!
  ) {
    masterSupplier(id: $id) {
      _id
      documentUpdates(input: $documentUpdatesInput) {
        hits {
          ...commonUpdateFields
        }
        total
      }
    }
  }

  ${commonUpdateFields}
`;

export function MasterSupplierUpdatesTab() {
  const { supplierId } = useRouterParams(['supplierId']);
  const { data, loading } = useMasterSupplierDetailsQuery(supplierId);

  const tabProps = {
    collection: collectionNames.masterSuppliers,
    entity: {
      name: entities.MASTER_SUPPLIER,
      _id: R.path(['masterSupplier', '_id'], data),
      humanReadableId: R.path(['masterSupplier', 'company', 'name'], data),
    },
  };

  return loading ? (
    <Loader />
  ) : (
    <UpdatesTab {...tabProps} updatesQuery={MASTER_SUPPLIER_UPDATES} />
  );
}
