import * as R from 'ramda';

const serviceTypesGLCodes = [
  {
    glCode: '82160048',
    psCategoryIds: ['43000000', '76110000', '81112201', '81112201'],
  },
  {
    glCode: '82160150',
    psCategoryIds: ['46000000'],
  },
  {
    glCode: '82160165',
    psCategoryIds: ['80161508', '50202310'],
  },
];

export const getGlCodeByCategoryId = R.pipe(
  (categoryId) =>
    R.find(
      R.propSatisfies(R.includes(categoryId), 'psCategoryIds'),
      serviceTypesGLCodes,
    ),
  R.prop('glCode'),
);
