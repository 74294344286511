import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { number, object, oneOfType, shape, string } from 'prop-types';
import { setOpenModal } from '../../../state/modules/modal.js';
import { ConfirmInvoiceSaveModalC } from '../../../components/modals/ConfirmInvoiceSaveModalC.js';
import { useVerifyInvoice } from '../apollo/useVerifyInvoice.js';
import { useUpdateOCRInvoice } from '../apollo/useUpdateOcrInvoice.js';
import { InvoiceWorkflow } from '../../../utils/constants/invoice.js';

export function ConfirmInvoiceSaveModal({ payload }) {
  const { invoiceUpdate, invoiceNumber, invoiceDuplicatesCount, saveWorkflow } =
    payload;

  const dispatch = useDispatch();

  const [isMutating, setIsMutating] = useState(false);

  const { verifyInvoice } = useVerifyInvoice();
  const { updateOCRInvoice } = useUpdateOCRInvoice();

  const selectedInvoiceHandler =
    saveWorkflow === InvoiceWorkflow.VERIFY ? verifyInvoice : updateOCRInvoice;

  const onConfirm = async () => {
    setIsMutating(true);

    await selectedInvoiceHandler(invoiceUpdate);
    dispatch(setOpenModal({ name: null }));
  };

  const onCancel = () => dispatch(setOpenModal({ name: null }));

  return (
    <ConfirmInvoiceSaveModalC
      invoiceDuplicatesCount={invoiceDuplicatesCount}
      isMutating={isMutating}
      invoiceNumber={invoiceNumber}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
}

ConfirmInvoiceSaveModal.propTypes = {
  payload: shape({
    invoiceNumber: string,
    saveWorkflow: string,
    invoiceDuplicatesCount: number,
    invoiceUpdate: oneOfType([string, object]).isRequired,
  }),
};
